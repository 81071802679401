<template>
  
  <div class="CartaDocumento" v-if="filter(estado)">
  <!--
     v-if="show"
    {{idDoc}}
    {{docParam}}
    -->

    <div class="fileCard__title" style="padding:3px;cursor:pointer;" @click="showFullOptions=!showFullOptions;">
      
      <div style="display:inline-block;width:50%;vertical-align:top;">
        <i class="bi bi-file-earmark" style="font-size: 150%;" v-if="estado=='No Disponible'"></i>
        <i class="bi bi-file-earmark-check" style="font-size: 150%;" v-if="estado=='Correcto'"></i>
        <i class="bi bi-file-earmark-excel" style="font-size: 150%;" v-if="estado=='Con Observacion'"></i>
        <i class="bi bi-file-earmark-arrow-up" style="font-size: 150%;" v-if="estado=='En Verificacion'"></i>

        <label style="font-size: 120%;"></label>{{docParam.c1}}
        <i class="bi bi-chevron-down" style="float:right;" v-if="!showFullOptions"></i>
        <i class="bi bi-chevron-up" style="float:right;" v-else></i>
      </div>
      <div style="display:inline-block;width:50%;vertical-align:top;text-align:right;">      
        <label style="font-size: 80%;">
          {{estado=='No Disponible'?'Archivo pendiente':estado}}
        </label>
        <i class="bi bi-square" style="width:0px;font-size: 150%;" v-if="estado=='No Disponible'"></i>
        <i class="bi bi-exclamation-square-fill" style="width:0px;font-size: 150%;color:var(--comment);" v-if="estado=='Con Observacion'"></i>        
        <i class="bi bi-check-square-fill" style="background-color:white;width:0px;height:0px;font-size: 150%;color:var(--blueOriginal);" v-if="estado=='En Verificacion'"></i>
        <i class="bi bi-check-square-fill" style="background-color:white;width:0px;height:0px;font-size: 150%;color:var(--correct);" v-if="estado=='Correcto'"></i>
      </div>
      
    </div>
    <div v-if="showFullOptions" style="padding:12px;padding-bottom: 18px;">

      <div class="col90 center">
        
        <div>
          {{docParam.c2}}
        </div>
      </div>

      <div style="width:100%;min-height:15px;">
        <span></span>
      </div>

      
      <div class="col90 center" v-if="observacion!=''" style="background:#ea4335;border-radius: 5px;">
        <div style="color:white;">
          Observación:
        </div>
        <div style="color:yellow;">
          {{observacion}}
        </div>
      </div>
      
      
      <div style="width:100%;min-height:15px;">
        <span></span>
      </div>

      

      <div class="fullWidth center">
        <label class="btn btnBlue tCenter" v-bind:for="docParam.c1+'ref'" style="display:block;margin:0 auto;width:90%;cursor:pointer;" v-if="cargando==0">
          <i class="bi bi-upload"></i>Cargar Archivo                 
        </label>

        <input                             
          class="form-control btn btn-primary"
          type="file"                   
          v-bind:name="docParam.c1+'ref'"
          v-bind:id="docParam.c1+'ref'"                  
          v-bind:ref="docParam.c1+'ref'" 
          v-on:change="handleFileDoc()" 
          v-bind:accept="docParam.c3"
          v-bind:value="fileName"
        />
      </div>



    </div>
    <!--
    <div>
      Tamaño maximo del archivo:{{doc.c4}} MB
      Opciones del archivo:{{doc.c3}}
    </div>
    -->
    
    
    
    
  </div>

</template>

<script>
import axios from "axios";

import { mapStores } from 'pinia';
import { useUserStore } from '../../store/user';

export default {
  name: 'CartaDocumento',
  props:{
    modo:String,
    docParam:Object

  },  
  data:function(){

    return{      
      show:false,
      modalMessage:'',
      showModal:false,
      listaDocumentos:[],
      idDoc:'',
      cargando:0,
      resultadoCarga:'',
      estado:'',
      fileName:'',
      nombreArchivo:'',
      observacion:'',
      docObject:{},
      showFullOptions:false,
      modalMsg:''
      


    };

  },
  watch:{

    

  },
  mounted:function(){

    this.docObject=this.doc;     
    this.estado=this.docParam.c6;
    this.observacion=this.docParam.c7
    this.idDoc=this.docParam.c0;

    this.filter(this.estado);
  
  },
  methods:{

    handleFile(){

      this.docObject.c6='En Verificacion';
      this.$emit('uploadedEv');
      console.log("handleFile()");
    },

    

    handleFileDoc(){
        
        //let doc=this.listaDocumentos[index];
        //let doc=this.doc;
        let doc = this.docParam;
        this.cargando=1;
        console.log("Handle File Doc:");
        console.log(doc);
        
        
        this.$forceUpdate();

        console.log("Nombre Doc: "+doc.c1+'ref');
        console.log(this.$refs);
        console.log("IputRef:");
        console.log(this.$refs[doc.c1+'ref']);
        console.log("InputRefValue:");
        console.log(this.$refs[doc.c1+'ref'].value);
        
        console.log("Archivo: ");
        console.log(this.$refs[doc.c1+'ref']);
        console.log(this.$refs[doc.c1+'ref'].files);
        console.log(this.$refs[doc.c1+'ref'].files[0]);
        
        let file = this.$refs[doc.c1+'ref'].files[0];


        console.log("File:");
        console.log(file);

        console.log("FileSize:");
        console.log(file.size);

        let tamArchSeleccionado = (file.size/1048576);
        console.log("FileSizeMB:");
        console.log( tamArchSeleccionado );
        
        console.log("FileName:");
        console.log( file.name );
        let fileName=file.name;
        let ext=(fileName.substring(fileName.lastIndexOf("."))).toLowerCase();
        console.log(ext);

        let tamMaxPermitido = parseFloat(''+doc.c4);
        console.log("Tam en MB"+tamArchSeleccionado+"<"+tamMaxPermitido);

        if(doc.c3==ext){

          if(tamArchSeleccionado<tamMaxPermitido){
          

            let date = new Date();
            let timeStamp =
              "" +
              date.getFullYear() +
              "-" +
              (date.getMonth() + 1) +
              "-" +
              date.getDate() +
              " " +
              date.getHours() +
              ":" +
              date.getMinutes() +
              ":" +
              date.getSeconds();

            console.log(timeStamp);            

            let formData = new FormData();
            formData.append("fileToUpload", file);            
            formData.append("archivoSizeMb", doc.c4);
            formData.append("extensionDeseada", doc.c3);
            formData.append("folio", this.userStore.idp);
            formData.append("aIngreso",this.userStore.anioIngreso);
            formData.append("nombre",doc.c5);
            formData.append("idCD",doc.c10);
            console.log(formData);
            
            this.uploadDocFile(formData);
          }else{

            this.$emit('showModalEv',{msg:''+"El archivo no se pudo cargar. El limite de tamaño de archivo permitido para este tipo de documento es de "+tamMaxPermitido+" MByte(s), Por favor reduzca el tamaño del archivo e intente nuevamente"});

            this.cargando=0;
            this.$refs[doc.c1+'ref'].value="";
          }
        }else{

          
          

          this.$emit('showModalEv',{msg:''+"Error: "+"Error el tipo de Archivo seleccionado: "+ext+" no concuerda con el tipo especificado: "+doc.c3});

          this.cargando=0;
          this.$refs[doc.c1+'ref'].value="";
        }
        
        

    },
    async uploadDocFile(formData) {
      
      /*
      let request_data = {
        opcion: 11,
        unidad: "" + this.unidad
      };
      */

      //const uriServer = "http://localhost/clienteaspirantes/upload.php";
      //const uriServer = "https://www.sistemaregistroupn.com/acceso/uploadCE.php";
      const uriServer = "https://webdevworks.xyz/liceoFSTR/uploadASP.php";

      axios
        .post(uriServer, formData, {
          headers: {
            "Content-Type": "multipart/form-data"
          }
        })
        .then(response => {
          console.log(response.data);
          console.log(response.data.response_data.status);
          //
          //this.listaDocumentos[index].cargando = response.data.response_data.status;
          
          this.cargando=0;
          let strExito=response.data.response_data.msg;
          console.log("response.data.response_data.msg:");
          console.log(response.data.response_data.msg);


          if (response.data.response_data.status == 1) {
            //this.fichaEscolar.status=-1;
            //alert(strExito);
            this.resultadoCarga = response.data.response_data.status;
            this.modalMsg=strExito;
            this.nombreArchivo=response.data.response_data.fileName;
            //this.updateDocData(strExito);
            this.sendUpdateEstadoDocumentosAspirante(strExito);
          }else{            
            
            this.$emit('showModalEv',{msg:''+"Error: "+strExito});
            this.resultadoCarga = response.data.response_data.status;
            this.$refs[this.docParam.c1+'ref'].value="";
          }

          console.log("SUCCESS");
        })
        .catch(error => {
          console.log(error);
        });
      
    },

    sendUpdateEstadoDocumentosAspirante(){
      console.log("sendUpdateEstadoDocumentosAspirante()");

      let archExtension=this.nombreArchivo.substring(this.nombreArchivo.lastIndexOf('.'));
      let data={
        idp:''+this.userStore.idp,        
        iddoc:''+this.idDoc,
        idCatDoc:''+this.docParam.c10,
        nombre:''+this.nombreArchivo,        
        estado:'En Verificacion',
        tipoArch:''+archExtension,
        observ:'Archivo Cargado en Espera de Verificación'
      };
      this.$root.sendPOSTRequest(
        "https://webdevworks.xyz/scLC/api/v1/actualizarEstadoDocumentoAspirante/",
        data,
        this.onSuccessSendUpdateEstadoDocumentosAspirante,
        this.onErrorSendUpdateEstadoDocumentosAspirante
        );
    },
    onSuccessSendUpdateEstadoDocumentosAspirante(data){
      console.log("onSuccessSendUpdateEstadoDocumentosAspirante");
      console.log("Data success:");
      console.log(data);            
      
      this.estado=data.estado;
      this.observacion=data.observ;
      this.idDoc=data.id;
      
      this.$emit('updateDocEv',{c0:''+this.idDoc,c1:''+this.docParam.c1,c6:''+this.estado,c7:''+this.observacion});
      this.$emit('showModalEv',{msg:''+this.modalMsg});
      this.userStore.documentosCargados=1;
      this.filter(this.estado);
    },
    onErrorSendUpdateEstadoDocumentosAspirante(data){
      console.log("onErrorGetListaDocumentosAspirante");
      console.log(data);
    },

    filter(status){
      //'No Disponible','Cargado en Espera de Revision','Con Observacion','Correcto','Cancelado','Pendiente de carga'
      if(this.modo=='pendientes'){
        
        return (status=='No Disponible'||status=='Con Observacion');    
        
      }
      if(this.modo=='cargados'){
        return (status=='En Verificacion'||status=='Correcto');    
      }

    },

  },
  computed:{  
      ...mapStores(useUserStore)
  }
  
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

  .CartaDocumento{
    margin-bottom:5px;
    border-radius:5px;
    overflow:hidden;
  }

  .CartaDocumento:hover{
    margin-bottom:5px;
    border-radius:5px;
    overflow:hidden;
    
  }

  .fileCard__title{
    background:none;
  }
  .fileCard__title:hover{
    background:var(--blue);
  }

</style>
