<template> 
  <div v-if="!showNuevo">
    
    <div :class="getInputClass()" style="background:white;overflow:hidden;" @click="showHideList()">
     
      <input class="col95 row" type="text" v-model="data" readonly style="line-height:25px;border:none;outline:none;" :placeholder="placeholder">
      <div class="col5 row">
        <i class="bi bi-chevron-down" 
        style="font-size:0.8rem;"></i>
        <i class="bi bi-check-circle-fill fontCorrect" 
        style="max-height:1px;font-size:1.3rem;
        float:right;position:relative;top:-22px;right:15px;margin-bottom:0px;" v-if="state==1"></i>    
      </div>
      
    </div>  
    <div v-if="showOpts">
      <div class="fullWidth">
        <div>
          <input id="busqueda" class="fullWidth center" type="text" v-model="busqueda" :placeholder="'Filtrar '+optionName" v-if="optionsArray.length>0">
        </div>
        <div>
          <small v-if="optionsArray.length>0">
            {{'Seleccione su '+optionName+':'}}
          </small>
          <small style="border-radius:5px;background:blue;color:white;cursor:pointer;float:right;" @click="showHideNuevo();" v-if="allowNew">Mi {{optionName}} no esta en la lista</small>
        </div>
      </div>
      
      <div class="col90 center" style="max-height:25vh;overflow-y:auto;"  v-if="optionsArray.length>0">
        <button class="btnWhiteSmall col90 block" v-for="option in filtroOptions" :bind="option.c0" :key="option" style="border-bottom:solid 1px white;" 
        @click="data=valFieldName?
          option[''+this.valFieldName]:
          option.c1;showOpts=false;busqueda='';"
        >
          {{displayFieldname?
          option[''+this.displayFieldname]:
          option.c1}}
        </button>
      </div>
    </div>  
  </div>
  <div v-else>      
    <input :id="'nuevo'+propName" :class="getInputClass()" type="text" v-model="data" :placeholder="'Introduzca su '+optionName">
    <i class="bi bi-x-circle-fill fontError" style="max-height:10px;font-size:1.3rem;float:right;position:relative;top:-27px;right:15px;margin-bottom:0px;" v-if="state==-1"></i>
    <i class="bi bi-check-circle-fill fontCorrect" style="max-height:1px;font-size:1.3rem;float:right;position:relative;top:-27px;right:15px;margin-bottom:0px;" v-if="state==1"></i>      
    <small style="color:blue;cursor:pointer;text-align:center;display:block;margin:0 auto;" @click="showNuevo=false;">Regresar a lista de opciones</small>
  </div> 
</template>
<script>

export default{
 name:'InputSelectList',
 data:function(){
  return{
    data:'',
    state:0,
    busqueda:'',
    showOpts:false,
    showNuevo:false,
    mountedData:false
  };
 },
 updated:function(){
  if(this.showOpts&&!this.showNuevo&&this.optionsArray.length>0){    
    document.getElementById("busqueda").focus();
  }
  if(this.showNuevo){   
    document.getElementById("nuevo"+this.propName).focus();   
  }
 },
 mounted:function(){
   
  //this.data='Seleccione su '+this.optionName;

  if(this.$parent.formData[''+this.propName].data!=''){
    this.data=this.$parent.formData[''+this.propName].data;
    
    this.state=1;
    
    this.mountedData=true;
    //console.log("InputSelectList Mounted ("+this.optionName+"):"+this.data);
  }
  //console.log("InputSelectList Mounted: "+this.optionName);
  //console.log(this.optionName+"-data:"+this.data);

 },
 props:{  
  ref:String,
  allowNew:Boolean,
  placeholder:String,
  maxLength:Number,
  minLength:Number,
  capital:Boolean,
  mode:String,
  extraPropName:String,
  propName:String,
  optionName:String,
  optionsArray:Array,
  displayFieldname:String,
  valFieldName:String
 },
 
 watch:{
  optionsArray:function(){    
    
    if(this.$parent.formData[''+this.propName].data==''&&!this.mountedData){
      this.data='';
      this.state=0;
    }
  },
  busqueda:function(){
    
    this.busqueda=this.busqueda.toUpperCase();
  },
  data:function(){

    
    if(this.showNuevo){
      //console.log("nuevo");
      if(this.capital){        
        this.data=this.data.toUpperCase();
        //console.log("capital");
      }
      if(this.data.length>this.maxLength){
        this.data=this.data.substring(0,this.maxLength);
      }
      this.state=this.$root.verifData(this.data, this.mode, this.minLength, this.maxLength);
      if(this.propName!=''){
          //this.$parent[''+this.propName]=this.data;          
          this.$parent.formData[''+this.propName]={data:''+this.data,state:this.state};
          //console.log("actualizando valor de "+this.propName);
      }

    }else{
      if(this.data!='Seleccione su '+this.optionName&&this.data!=''){
        this.state=1;
        if(this.propName!=''){
          //this.$parent[''+this.propName]=this.data;
          this.$parent.formData[''+this.propName]={data:''+this.data,state:this.state};
          if(this.extraPropName!=''){
            //console.log("extraPropName:"+this.extraPropName);
            //console.log("data:"+this.data);
            this.$parent[''+this.extraPropName]=this.data;
          }
          
        }      
      }
    }


  }
 },
 
 methods:{
  showHideNuevo(){
    this.showNuevo=!this.showNuevo;    
    this.data='';
    this.state=0;  
  },

  showHideList(){
    this.showOpts=!this.showOpts;
    

  },  
  getData(){
    return this.data;
  },
  getState(){
    return this.state;
  },
  getBState(){
    return (this.state==1);
  },
  getInputClass(){    

    let classStr={};

    if(!this.showNuevo){
      classStr={      
        '0':'input',
        '1':'inputCorrect'
      };
    }else{
      classStr={
        '-1':"inputError",
        '0':'input',
        '1':'inputCorrect'
      };
    }
    return classStr[''+this.state];
  },

  
},
computed:{
  filtroOptions(){
        if(this.busqueda==''){
          return this.optionsArray;
        }
        let arr=[];
        let l=this.optionsArray.length;
        let busquedaStr=this.busqueda;
        //console.log('busquedaStr:'+busquedaStr);
        for(let i=0;i<l;i++){
          
          if(this.displayFieldname){            
            
            //console.log((''+this.optionsArray[i][''+this.displayFieldname]).match(busquedaStr));
            if((''+this.optionsArray[i][''+this.displayFieldname]).includes(busquedaStr)){
              arr[arr.length]=this.optionsArray[i];
            
            }
          }else{
            
            //console.log((''+this.optionsArray[i].c1).startsWith(busquedaStr));
            if((''+this.optionsArray[i].c1).includes(busquedaStr)){
              arr[arr.length]=this.optionsArray[i];            
            }
            
          }

          
        }
        return arr;
  },
}
}
</script>
<style scoped>

</style>
