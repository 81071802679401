<template>
  <div> 
  
    <div :class="selectedData<=1?'inputContainer':'inputContainerCorrect'" @click="showCalendar=!showCalendar;">
      <div style="margin-top:4px;min-width:72%;display:inline-block;vertical-align:top;text-align:left;float:left;">
        <!--
        <label style="vertical-align:middle;margin-left:2px;">
          {{getDay()}} / {{getMonth()}} / {{year}}
        </label>
        -->
        <input type="text" readonly :value="getDay()+' / '+getMonth()+' / '+year" style="vertical-align:middle;margin-left:2px;border:none;">
        
      </div>
      <div style="margin-top:4px;min-width:20%;display:inline-block;vertical-align:top;float:right;">
        <button class="btnError" 
        style="vertical-align:middle;cursor:pointer;line-height:15px;float:right;"  
        v-if="showCalendar">
            Cerrar <i class="bi bi-x-lg"></i>
        </button>
        <i class="bi bi-check-circle-fill fontCorrect" 
        style="max-height:1px;font-size:1.3rem;float:right;position:relative;top:0px;right:15px;margin-bottom:0px;" 
        v-if="selectedData>1"></i>    
      </div>
    </div>
      <div v-if="showCalendar" class="calendar">
      <div style="background:var(--blue);">
        <div style="width:50%;display:inline-block;">
          <strong style="color:white;">
            Mes:
          </strong>
          <select v-model="month">
            <option v-for="(mes,mesI) in getMonthArr()" :key="mes" :value="(mesI+1)">{{mes}}</option>
          </select>
        </div>
        <div style="width:40%;float:right;display:inline-block;text-align:right;">
          <strong style="color:white;">
            Año:
          </strong>
          <select style="margin-right:2px;" v-model="year">
            <option 
            v-for="anio in getYearArr()" 
            :key="anio" 
            style="color:black;" 
            :value="anio">
            {{anio}}
            </option>
          </select>
        </div>
      </div>
      <div style="text-align:left;">
        <button class="btn" v-for="dia in getDayArr()" 
        :key="dia" :style="day==dia?'cursor:pointer;width:14.2%;background:var(--orangeDark);color:white;':'cursor:pointer;width:14.2%;'" @click="day=dia;daySelected=true;">
          {{dia}}
        </button>
      </div>
    </div>
    
  </div>
</template>
<script>

export default{
 name:'InputDate',
 data:function(){
  return{
    data:'',
    state:0,
    showCalendar:false,
    day:1,
    month:1,
    year:1,
    initialYear:0,
    selectedData:0,


  };
 },
 props:{
  propName:String,
  placeholder:String,
  date:String
 },
 watch:{
  day:function(){
    
    this.setData(''+this.year+'-'+(this.month<10?'0'+this.month:''+this.month)+'-'+(this.day<10?'0'+this.day:this.day));
    this.selectedData++;
    console.log("day");
  },
  month:function(){
    
    this.setData(''+this.year+'-'+(this.month<10?'0'+this.month:''+this.month)+'-'+(this.day<10?'0'+this.day:this.day));
    this.selectedData++;
    console.log("month");
  },
  year:function(){    
    
    this.setData(''+this.year+'-'+(this.month<10?'0'+this.month:''+this.month)+'-'+(this.day<10?'0'+this.day:this.day));
    this.selectedData++;
    console.log("year");
  },
  data:function(){
    if(this.selectedData>1){

      this.state=this.$root.verifData(this.data, this.mode, this.minLength, this.maxLength);
      if(this.propName!=''){
          //this.$parent[''+this.propName]=this.data;        
          this.$parent.formData[''+this.propName]={data:''+this.data,state:this.state};
          //console.log("actualizando valor de "+this.propName);
      }

    }

  }
 },
 mounted:function(){
  //console.log("State:"+this.state);
  
  let d = new Date();
  this.initialYear = d.getFullYear();
  this.year = d.getFullYear()-13;
  this.setData(''+this.year+'-'+(this.month<10?'0'+this.month:''+this.month)+'-'+(this.day<10?'0'+this.day:this.day));
  //console.log("State:"+this.state);
  
  if(this.$parent.formData[''+this.propName].state==1&&(''+this.$parent.formData[''+this.propName].data!='')){

    let dataStr=''+this.$parent.formData[''+this.propName].data;
    let y=parseInt(''+dataStr[0]+dataStr[1]+dataStr[2]+dataStr[3]);
    dataStr=dataStr.substring(5);
    console.log('year: '+y);
    console.log("substr dataStr: "+dataStr);
    let m=parseInt(''+dataStr[0]+dataStr[1]);
    dataStr=dataStr.substring(3);
    console.log('month: '+m);
    console.log("substr dataStr: "+dataStr);
    let d=parseInt(''+dataStr[0]+dataStr[1]);
    console.log('day: '+d);

    console.log("inputDate:");
    console.log(this.data);
    
    this.year=y;
    this.month=m;
    this.day=d;
    this.data=this.$parent.formData[''+this.propName].data;
    this.state=1;
    
  }
  

 },
 methods:{

  getDay(){
    return (this.day<10)?('0'+this.day):(''+this.day);
  },
  getMonth(){
    return (this.month<10)?'0'+this.month:''+this.month;
  },
  getDayArr(){
    let days={
      1:31,
      2:28,
      3:31,
      4:30,
      5:31,
      6:30,
      7:31,
      8:31,
      9:30,
      10:31,
      11:30,
      12:31
    };

    let arr=[];

    if(this.year%4==0&&this.month==2){
      days[this.month]=29;
    }

    for(let i=1;i<=days[this.month];i++){
      arr[arr.length]=''+i;
    }

    return arr;
  },

  getMonthArr(){
    return [
      'Enero',
      'Febrero',
      'Marzo',
      'Abril',
      'Mayo',
      'Junio',
      'Julio',
      'Agosto',
      'Septiembre',
      'Octubre',
      'Noviembre',
      'Diciembre'      
    ]
  },

  getYearArr(){
    let arr=[];

    for(let i=this.initialYear-65;i<=this.initialYear-13;i++){
      arr[arr.length]=''+i;
    }
    return arr;
  },

  setData(str){
    this.data=str;
    if(this.selectedData>1){
      this.state=this.$root.verifData(this.data, this.mode, this.minLength, this.maxLength);
    
      if(this.propName!=''){
          //this.$parent[''+this.propName]=this.data;        
          this.$parent.formData[''+this.propName]={data:''+this.data,state:this.state};
          //console.log("actualizando valor de "+this.propName);
      }
    }
  },

  getData(){
    return this.data;
  },
  getBState(){
    return (this.state==1);
  },
  
  getInputClass(){    

    let classStr={};

    classStr={
        '-1':"inputError",
        '0':'input',
        '1':'inputCorrect'
    };

    return classStr[''+this.state];
  },

  


 }
}
</script>
<style scoped>

  .inputContainer{
    width:100%;
    display: inline-block;
    text-align:center;
    border-radius:5px;
    background:white;
    border:rgb(220,220,220) solid 1px;
    min-height:28px;
  }

  .inputContainer:hover{
    width:100%;
    display: inline-block;
    text-align:center;
    border-radius:5px;
    background:white;
    border: var(--orange) solid 1px;
    min-height:28px;
  }

  .inputContainerCorrect{
    width:100%;
    display: inline-block;
    text-align:center;
    border-radius:5px;
    background:white;
    border-bottom:rgb(19, 132, 150) solid 5px;
    min-height:28px;
  }

  .inputContainerCorrect:hover{
    width:100%;
    display: inline-block;
    text-align:center;
    border-radius:5px;
    background:white;
    border-bottom:rgb(19, 132, 150) solid 5px;
    outline: var(--orange) solid 1px;
    min-height:28px;
  }

  
  .btn{
    background:white;
    border-radius:5px;    
    border:solid 1px darkgray;
  }
  .btn:hover{
    background:var(--blue);
    color:white;
    border-radius:5px;    
    border:solid 1px darkgray;
  }
  .calendar{
    
    display: block;
    width:100%;
    background:gray;
    border-radius:5px;    
    position:relative;
    top:30;
    left:0;
    z-index:1000;
    
  }
</style>
