<template>
  <div class="fullWidth fullHeight blueDark">    
    
    <div v-bind:class="getWidthClass()">
      <navbar style="top:0;position: -webkit-sticky;position:sticky;"></navbar>
      <div :class="getViewContainerClass()" style="border-radius:5px;padding-top:10px;">
        <router-view :class="getLoginClass()"></router-view>
      </div>
    </div>
        
  </div>
</template>

<script>

import { mapStores } from 'pinia';
import { useUserStore } from './store/user';
//import Sidebar from './components/Sidebar/Sidebar.vue';
import Navbar from './components/Navbar/Navbar.vue';

import axios from "axios";

export default {

  
  name: 'App',
  components: {
    
    Navbar
  },
  
  computed:{
    
    ...mapStores(useUserStore)

  },
  
  methods:{

    toHome(){
      window.location.href="https://bachilleratochedraui.com/aspirantes";
    },

    importData(data){
      this.$root.changeRoute("/home");
      this.userStore.login=1;
      this.userStore.userID=data.c1
      this.userStore.correo=data.c20;
      this.userStore.idp=data.c21;
      this.userStore.status=data.c2;
      this.userStore.nombre=data.c3;
      this.userStore.ap1=data.c4;
      this.userStore.ap2=data.c5;
      this.userStore.userName=data.c6,      
      this.userStore.correoVerificado=data.c7;
      this.userStore.datosRegistrados=data.c8;
      this.userStore.datosValidados=data.c9;
      this.userStore.documentosCargados=data.c10;      
      this.userStore.documentosValidados=data.c11;
      this.userStore.fichaRegistroRegistrada=data.c12;
      this.userStore.fichaRegistroCargada=data.c13;
      this.userStore.fichaRegistroValidada=data.c14;
      this.userStore.descargaComprobanteInscripcion=data.c15;
      this.userStore.sistema=data.c16;
      this.userStore.carrera=data.c17;
      this.userStore.numeroControl=data.c18;
      this.userStore.plantel=data.c19;
      this.userStore.horario=data.c22;
      this.userStore.anioIngreso=data.c23;
      

      /*
      this.userStore.datosStatus=data.c8;
      this.userStore.documentosStatus=data.c9;
      this.userStore.fichaRegistro=data.c10;
      this.userStore.comprobanteInscripcionDescargado=data.c11;
      this.userStore.registroCompleto=data.c12;            
      this.userStore.sistema=data.c13;
      this.userStore.carrera=data.c14;
      this.userStore.numeroControl=data.c15;
      this.userStore.plantel=data.c16;   
      */
    },


    getEncodedArray(str){  
      let charCodeArray=[];
      let l=str.length;
      for(let i=0;i<l;i++){
      
        charCodeArray[i]=(str.charCodeAt(i)<<3);
      
      }
      return charCodeArray;
    },

    getEncodedStr(str){  
      let encodedArray=this.getEncodedArray(str);
      let encodedStr="";

      
      let l=encodedArray.length;
      let nChar=0;
      for(let i=0;i<l;i++){
        
        nChar=(""+encodedArray[i]).length;
        encodedStr+=""+nChar+encodedArray[i];
      
      }
      return btoa(encodedStr);
    },


    getDecodedArray(encodedArray){
    
      let decodedArray=[];
      let l=encodedArray.length;
      for(let i=0;i<l;i++){
      
        decodedArray[i]=(encodedArray[i]>>7);
      
      }
      return decodedArray;
    },

    decodeString(str){
      let decodedStringb64=atob(str);
    
      let charCodeArray=[];
      let l=decodedStringb64.length;
      
      let nChar=0;
      let charCodeStr="";
      
      for(let i=0;i<l;i++){    
        if(nChar==0){        	
            nChar=parseInt(decodedStringb64[i]);
              if(charCodeStr!==""){
                charCodeArray[charCodeArray.length]=parseInt(charCodeStr);
              }
              charCodeStr="";                    
          }else{
            charCodeStr+=decodedStringb64[i];
            nChar--;        
          }    
      }
    
      return charCodeArray;
    },

    encodeData(str){

      let encodedData=this.getEncodedStr(str);

      console.log(atob(encodedData));


      return encodedData;
      
    },


    getData(formData){

      let dataObj={};

      let formDataKeys=Object.keys(formData);
      
      let l=formDataKeys.length;
      let dataText='';
      let key='';
      for(let i=0;i<l;i++){

        
        key=''+formDataKeys[i];
        dataText=formData[key];
        dataText=formData[key].data.replace(/'/g, "");
        dataText=formData[key].data.replace(/\n/g, "");
        dataText=formData[key].data.replace(/\t/g, "");
        //dataObj[key]=""+btoa(dataText);
        dataObj[key]=""+dataText;
        

      }      

      /*
      let jsonStr=JSON.stringify(dataObj);
      let encodedObj=btoa(jsonStr);
      console.log(encodedObj);
      console.log(atob(encodedObj));
      */

      return dataObj;
    },

    getPeriodoTiempoCarga(){
      return [
      "Una Vez",
      "Trimestre",
      "Cuatrimestre",
      "Semestre"
      ];
    },

    getViewContainerClass(){

      if(this.userStore.registroView){
        return 'fullHeight fromWhiteToBlueDark transShort';
      }else{

        if(this.userStore.login==1){
          return 'fullWidth fullHeight fullWhite lightModePageBackground';
        }else{
          return 'fullHeight fullWhite fromBlueDarkToWhite transShort';
        }

      }

      

      

    },

    getLoginClass(){

      if(this.userStore.login==1){
        return 'pageContainer';
      }else{
        return '';
      }

    },
    getWidthClass(){

      let cssClass="row fullWidth fullHeight";
/*
      if(this.userStore.login==1){
        cssClass="row view fullHeight";
      }
      */
      return cssClass;

    },

    changeRoute(pathName){

      this.$router.push({ path: pathName });
    },

    getTimeStamp(){
      let date = new Date();
      let timeStamp = 
        "" +date.getFullYear()+
        "-" +((date.getMonth() + 1)<10?'0'+(date.getMonth() + 1):(date.getMonth() + 1))+
        "-" +((date.getDate())<10?'0'+(date.getDate()):(date.getDate()))+
        " " +((date.getHours())<10?'0'+(date.getHours()):(date.getHours()))+
        ":" +((date.getMinutes())<10?'0'+(date.getMinutes()):(date.getMinutes()))+
        ":" +((date.getSeconds())<10?'0'+(date.getSeconds()):(date.getSeconds()))
      ;
      console.log(timeStamp);
      return timeStamp;
    },

    sanitizeData(data){
      //console.log('Received Data:');
      //console.log(data);
      //console.log('sanitizeData:');
      let attArr=Object.keys(data);

      let l=attArr.length;
      let preparedData=data;

      for(let i=0;i<l;i++){
        //data[''+attArr[i]]=''+data[''+attArr[i]].
        //console.log("typeof:");
        //console.log(typeof data[''+attArr[i]]);
        preparedData[''+attArr[i]]=this.trimStartEnd(data[''+attArr[i]]);
      }
      //console.log(attArr);
      //console.log('Sanitized Data:');
      //console.log(data);
      return preparedData;
    },
    trimStartEnd(val){      
      if(typeof val=='string'){
        //let i=0;        
        let trimStr=val;              
        if(trimStr.indexOf('\n')>-1||trimStr.indexOf('\r\n')>-1){
          //console.log("replacing new lines");
          trimStr=trimStr.replace(/\n/g, "<n>");
        }
        if(trimStr.indexOf('\t')>-1){        
          //console.log("replacing tabs");
          trimStr=trimStr.replace(/\t/g, "<t>");
        }

        while(trimStr[0]==' '||trimStr[0]=='\t'||trimStr[0]=='\n'){
          //console.log("Deleting starting spaces");
          trimStr=trimStr.substring(1);          
          //i++;
          //console.log(i+')->'+trimStr);
        }
        let l=trimStr.length-1;
        //i=0;
        //console.log("Char at end:");
        //console.log(trimStr[l]+'|');
        while(trimStr[l]==' '||trimStr[l]=='\t'||trimStr[l]=='\n'){
          //console.log("Deleting ending spaces");
          trimStr=trimStr.substring(0,l);          
          l=trimStr.length-1;
          //i++;
          //console.log(i+')'+trimStr+'<-');
        }
        return trimStr;
        
      }
      return val;
    },

    async sendPOSTRequest(dir,request_data,onSuccess,onError){
      console.log("sendPOSTRequest")
      console.log(dir);
      request_data.fecha=''+this.getTimeStamp();
      console.log("Data:");
      console.log(request_data);
      //let encData=encodeData(request_data);
      //console.log(encData);
      //let address = "https://webdevworks.xyz/scDT/server.php";
      request_data=this.sanitizeData(request_data);

      let address = dir;
      console.log("address:");
      console.log(address);
      axios
        .post(
          address,
          {request:request_data},
          {
            headers: {
              "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8"
            }
          }
        ).then(response => {
          
          console.log("response:")
          console.log(response)
          console.log("Response.data:");
          console.log(response.data);
          console.log("Response.data.data:");
          console.log(response.data.data);
          onSuccess(response.data);          
          
        }).catch(error => {
          console.log(error);
          onError(error);
        });
    },


    async sendPOSTRequestParam(dir,request_data,onSuccess,onError,param){
      console.log("sendPOSTRequestParam")
      console.log(dir);
      request_data.fecha=''+this.getTimeStamp();
      console.log("Data:");
      console.log(request_data);
      //let encData=encodeData(request_data);
      //console.log(encData);
      //let address = "https://webdevworks.xyz/scDT/server.php";
      request_data=this.sanitizeData(request_data);
      let address = dir;
      console.log("address:");
      console.log(address);
      axios
        .post(
          address,
          {request:request_data},
          {
            headers: {
              "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8"
            }
          }
        ).then(response => {
          
          console.log("response:")
          console.log(response)
          console.log("Response.data:");
          console.log(response.data);
          console.log("Response.data.data:");
          console.log(response.data.data);
          onSuccess(response.data,param);          
          
        }).catch(error => {
          console.log(error);
          onError(error);
        });
    },

    async sendGETRequest(dir,request_data,onSuccess,onError){
      console.log("sendGETRequest");
      console.log(dir);

      let date = new Date();
      let timeStamp =
        "" +date.getFullYear() +
        "-" +((date.getMonth() + 1)<10?'0'+(date.getMonth() + 1):(date.getMonth() + 1)) +
        "-" +((date.getDate())<10?'0'+(date.getDate()):(date.getDate()))+
        " " +((date.getHours())<10?'0'+(date.getHours()):(date.getHours()))+        
        ":" +((date.getMinutes())<10?'0'+(date.getMinutes()):(date.getMinutes()))+        
        ":" +((date.getSeconds())<10?'0'+(date.getSeconds()):(date.getSeconds()))
        ;
      console.log(timeStamp);            
      request_data.fecha=''+timeStamp;
      //let address = "https://webdevworks.xyz/scDT/server.php";
      request_data=this.sanitizeData(request_data);
      let address = dir;
      axios
        .get(
          address,
          {
            params:request_data,                        
            headers: {
              "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8"
            }
          }
          
        ).then(response => {
          
          console.log("response:")
          console.log(response)
          console.log("Response.data:");
          console.log(response.data);
          console.log("Response.data.data:");
          console.log(response.data.data);
          onSuccess(response.data);          
          
        }).catch(error => {
          console.log(error);
          onError(error);
        });

    },

    async sendPATCHRequest(dir,request_data,onSuccess,onError){
      console.log("sendPATCHRequest")
      console.log(dir);
      let date = new Date();
      let timeStamp =
        "" +date.getFullYear() +
        "-" +((date.getMonth() + 1)<10?'0'+(date.getMonth() + 1):(date.getMonth() + 1)) +
        "-" +((date.getDate())<10?'0'+(date.getDate()):(date.getDate()))+
        " " +((date.getHours())<10?'0'+(date.getHours()):(date.getHours()))+        
        ":" +((date.getMinutes())<10?'0'+(date.getMinutes()):(date.getMinutes()))+        
        ":" +((date.getSeconds())<10?'0'+(date.getSeconds()):(date.getSeconds()))
        ;
      console.log(timeStamp);            
      request_data.fecha=''+timeStamp;
      //let address = "https://webdevworks.xyz/scDT/server.php";
      request_data=this.sanitizeData(request_data);
      let address = dir;
      axios
        .patch(
          address,
          {request:request_data},
          {
            headers: {
              "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8"
            }
          }
        ).then(response => {
          
          console.log("response:")
          console.log(response)
          console.log("Response.data:");
          console.log(response.data);
          console.log("Response.data.data:");
          console.log(response.data.data);
          onSuccess(response.data);          
          
        }).catch(error => {
          console.log(error);
          onError(error);
        });
    },

    async sendPUTRequest(dir,request_data,onSuccess,onError){
      console.log("sendPUTRequest")
      console.log(dir);
      let date = new Date();
      let timeStamp =
        "" +date.getFullYear() +
        "-" +((date.getMonth() + 1)<10?'0'+(date.getMonth() + 1):(date.getMonth() + 1)) +
        "-" +((date.getDate())<10?'0'+(date.getDate()):(date.getDate()))+
        " " +((date.getHours())<10?'0'+(date.getHours()):(date.getHours()))+        
        ":" +((date.getMinutes())<10?'0'+(date.getMinutes()):(date.getMinutes()))+        
        ":" +((date.getSeconds())<10?'0'+(date.getSeconds()):(date.getSeconds()))
        ;
      console.log(timeStamp);            
      request_data.fecha=''+timeStamp;
      request_data=this.sanitizeData(request_data);
      //let address = "https://webdevworks.xyz/scDT/server.php";
      let address = dir;
      axios
        .put(
          address,
          {request:request_data},
          {
            headers: {
              "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8"
            }
          }
        ).then(response => {
          
          console.log("response:")
          console.log(response)
          console.log("Response.data:");
          console.log(response.data);
          console.log("Response.data.data:");
          console.log(response.data.data);
          onSuccess(response.data);          
          
        }).catch(error => {
          console.log(error);
          onError(error);
        });
    },

    async sendDELETERequest(dir,request_data,onSuccess,onError){
      console.log("sendDELETEequest")
      console.log(dir);
      let date = new Date();
      let timeStamp =
        "" +date.getFullYear() +
        "-" +((date.getMonth() + 1)<10?'0'+(date.getMonth() + 1):(date.getMonth() + 1)) +
        "-" +((date.getDate())<10?'0'+(date.getDate()):(date.getDate()))+
        " " +((date.getHours())<10?'0'+(date.getHours()):(date.getHours()))+        
        ":" +((date.getMinutes())<10?'0'+(date.getMinutes()):(date.getMinutes()))+        
        ":" +((date.getSeconds())<10?'0'+(date.getSeconds()):(date.getSeconds()))
        ;
      console.log(timeStamp);            
      request_data.fecha=''+timeStamp;
      request_data=this.sanitizeData(request_data);
      //let address = "https://webdevworks.xyz/scDT/server.php";
      let address = dir;
      axios
        .delete(
          address,        
          {
            headers: {
              "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8"
            },
            data:{request_data}
          }
        ).then(response => {
          
          console.log("response:")
          console.log(response)
          console.log("Response.data:");
          console.log(response.data);
          console.log("Response.data.data:");
          console.log(response.data.data);
          onSuccess(response.data);          
          
        }).catch(error => {
          console.log(error);
          onError(error);
        });
    },
    verifData(inputVal, verifType, minCharLength, maxCharLength) {
        this.verifCount++;
        let cData = -1;
        let limiteChar = maxCharLength;
        let l = inputVal.length;
  
        let b = false;
        if (verifType == "AN") {
          //console.log("AlphaNum");
          b = this.verifAlphanumeric(inputVal);
  
        } else {
          if (verifType == "A") {
            //console.log("Alpha");
            b = this.verifAlpha(inputVal);
            
          } else {
            if (verifType == "CURP") {
              //console.log("curp");
              b = !this.verifCURP(inputVal);
              limiteChar = 18;
            } else {
  
              if (verifType == "email") {
  
                //console.log("email");
                b = !this.verifEmail(inputVal);
  
              } else {
  
                if (verifType == "N") {
                  //console.log("num");
                  b = this.verifNumeric(inputVal);
  
                } else {
                  if (verifType == "NCG") {
                    //console.log("num guion");
                    b = this.verifNumericConGuion(inputVal);
                  }
                  else {
                    if (verifType == "NF") {
                      //console.log("num flotante");
                      b = this.verifNumericFloat(inputVal);
                    }
                  }
  
                }
  
              }
  
            }
          }
        }
  
        let mCb = false;
        if (minCharLength) {
          if (l < minCharLength) {
  
            mCb = true;
  
          } else {
            mCb = false;
          }
        }
  
  
        if ((l > limiteChar) || mCb || b) {
          cData = -1;
  
        } else {
          //console.log(((l > limiteChar) || mCb || b));
          cData = 1;
        }
  
  
  
        if (inputVal.length == 0) {
          cData = 0;
          
        }
        
        return cData;
  
      },
      verifCURP(curp) {
        //console.log("tipo de dato curp: "+typeof curp);
        var re = /^([A-Z][AEIOUX][A-Z]{2}\d{2}(?:0[1-9]|1[0-2])(?:0[1-9]|[12]\d|3[01])[HM](?:AS|B[CS]|C[CLMSH]|D[FG]|G[TR]|HG|JC|M[CNS]|N[ETL]|OC|PL|Q[TR]|S[PLR]|T[CSL]|VZ|YN|ZS)[B-DF-HJ-NP-TV-Z]{3}[A-Z\d])(\d)$/,
          validado = curp.match(re);
        //console.log(validado);
        if (!validado) {
          //Coincide con el formato general?
          return false;
        } else {
          return true;
        }
  
  
      },
  
      verifEmail(email) {
        var mailformat = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
        if (email.match(mailformat)) {
          //alert("Valid email address!");
          return true;
        }
        else {
          //alert("Invalid email address!");
          return false;
        }
      },
  
      verifAlphanumeric(cad) {
        //console.log("cad: " + cad);
        //let re = /^[a-zA-Z0-9\_\-\,]/;
        //let validado = cad.test(re);
  
        let patt = new RegExp(
          /[\\?|\\¿|\\!|\\¡|\\=|\\"|\\'|\\+|\\*|\\/|\\#|\\;|\\:|\\||\\<|\\>|\\{|\\}|\\(|\\)|\\$|\\%|\\&]/,
          "g"
        );
        let validado = patt.test(cad);
        //console.log("Pattern: " + patt);
        //console.log("No Alfanumerico: " + validado);
        return validado;
      },
      verifNumericConGuion(cad) {
        //console.log("cad: " + cad);
        //let re = /^[a-zA-Z0-9\_\-\,]/;
        //let validado = cad.test(re);
  
        let patt = new RegExp(
          /[a-zA-Z|\\.|\\,|\\?|\\¿|\\!|\\¡|\\=|\\"|\\'|\\+|\\*|\\/|\\#|\\;|\\:|\\||\\<|\\>|\\{|\\}|\\(|\\)|\\$|\\%|\\&]/,
          "g"
        );
        //let validado = patt.test(cad);
        let validado = false;
        let cadena = "" + cad;
  
  
        if (cadena.match(patt)) {
          validado = true;
        }
  
        //console.log("Pattern: " + patt);
        //console.log("No Alfanumerico: " + validado);
        return validado;
      },
  
      verifNumeric(cad) {
        //console.log("cad: " + cad);
        //let re = /^[a-zA-Z0-9\_\-\,]/;
        //let validado = cad.test(re);
  
        let patt = new RegExp(
          /[a-zA-Z|\\-|\\.|\\,|\\?|\\¿|\\!|\\¡|\\=|\\"|\\'|\\+|\\*|\\/|\\#|\\;|\\:|\\||\\<|\\>|\\{|\\}|\\(|\\)|\\$|\\%|\\&]/,
          "g"
        );
        //let validado = patt.test(cad);
        let validado = false;
        let cadena = "" + cad;
  
  
        if (cadena.match(patt)) {
          validado = true;
        }
  
        //console.log("Pattern: " + patt);
        //console.log("No Alfanumerico: " + validado);
        return validado;
      },
  
      verifNumericFloat(cad) {
        //console.log("cad: " + cad);
        //let re = /^[a-zA-Z0-9\_\-\,]/;
        //let validado = cad.test(re);
  
        let patt = new RegExp(
          /[a-zA-Z|\\-|\\,|\\?|\\¿|\\!|\\¡|\\=|\\"|\\'|\\+|\\*|\\/|\\#|\\;|\\:|\\||\\<|\\>|\\{|\\}|\\(|\\)|\\$|\\%|\\&]/,
          "g"
        );
        //let validado = patt.test(cad);
        let validado = false;
        let cadena = "" + cad;
  
  
        if (cadena.match(patt)) {
          validado = true;
        }
  
        //console.log("Pattern: " + patt);
        //console.log("No Alfanumerico: " + validado);
        return validado;
      },
      verifAlpha(cad) {
        //console.log("cad: " + cad);
        //let re = /^[a-zA-Z0-9\_\-\,]/;
        //let validado = cad.test(re);
  
        let patt = new RegExp(
          /[0-9|\\?|\\¿|\\!|\\¡|\\=|\\"|\\'|\\+|\\*|\\/|\\#|\\;|\\:|\\||\\<|\\>|\\{|\\}|\\(|\\)|\\$|\\%|\\&]/,
          "g"
        );
        let validado = patt.test(cad);
        //console.log("Pattern: " + patt);
        //console.log("No Alfanumerico: " + validado);
        return validado;
      },
      verifFormat(inputCad, maxLength, capitalize) {
        let cad = "" + inputCad;
  
        if (capitalize) {
          cad = cad.toUpperCase();
        }
  
        if (cad.length > maxLength) {
  
          cad = cad.substring(0, maxLength);
  
        }
  
  
        return cad;
  
      },

  }

}
</script>

<style>
  /*
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}*/
</style>
