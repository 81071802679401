<template>
  <div class="fullWidth">

    <div class="title block tCenter" style="width:100%;line-height:40px;margin-top:10px;">
      <!--
      <div class="col10 left" style="vertical-align: middle;overflow:hidden;cursor:pointer;margin-left:20px;" @click="$root.changeRoute('/');">
        <strong>
          <i class="bi bi-arrow-left left"></i>
        </strong>
        <small>
          Volver
        </small>
         
      </div>
      -->
      <div class="col50 block tCenter" style="margin:0 auto;">
        <h2 v-on:dblclick="showGetOferta=true;">
          Preinscripción
        </h2>
      </div>
    </div>

    <div class="formRow col90 center glass tCenter">
    
      <div class="formCol col48 colSm95">

        <div class="formRow colSm95" >
            
            <label class="formLabel fontWhite" >        
              Plantel:
            </label>
            <span style="min-width:5%;width:5%;"></span>
            
            <input-select ref="plantelRef" :optionName="'Plantel'"  :whiteBackground="false" 
            :optionsArray="listaPlanteles" :propName="'plantel'"
            ></input-select>
                                
        </div>
      </div>
      <div class="formCol col48 colSm95">
        <div class="formRow colSm95">
            
          <label class="formLabel fontWhite">        
            Modalidad:{{modalidad}}
          </label>
          <span style="min-width:5%;width:5%;"></span>            
          <input-select ref="modalidadRef" :optionName="'Modalidad'" :whiteBackground="false" 
          :optionsArray="filtroModalidadesPorPlantel" :propName="'modalidad'" :extraPropName="'modalidad'">
          </input-select>

        </div>
      </div>


      <div class="formCol col48 colSm95" v-if="formData.modalidad.data.indexOf('Carreras')!=-1&&formData.plantel.state==1">
        <div class="formRow colSm95" >
            
          <label class="formLabel fontWhite" >        
            Carrera:
          </label>
          <span style="min-width:5%;width:5%;"></span>
          <input-select ref="carreraRef" :propName="'carrera'"  :whiteBackground="false"  
          :optionName="'Carrera'" :optionsArray="listaCarreras"></input-select>
                                
        </div>
      </div>



      <div class="formCol col48 colSm95">
        <div class="formRow colSm95">
            
          <label class="formLabel fontWhite">        
            Horario:
          </label>
          <span style="min-width:5%;width:5%;"></span>            
          
          <input-select ref="horarioRef" :optionName="'Horario'" :whiteBackground="false" 
          :optionsArray="filtroHorarioPorModalidadEnPlantel" :propName="'horario'" :extraPropName="'horario'" 
          :displayFieldname="'c0'" :displayExtraField="'c2'" :valFieldName="'c1'"></input-select>
          
          
                            
        </div>
      </div>

        
      

      <div class="formCol col48 colSm95">
        <div class="formRow colSm95">
            
          <label class="formLabel fontWhite" >        
            Correo Electrónico:
          </label>
          <span style="min-width:5%;width:5%;"></span>          
          
          <input-text ref="correoRef" :propName="'correo'"  :whiteBackground="false" :mode="'email'" 
          :placeholder="'Correo Electrónico'" :capital="false" :maxLength="120" :minLength="5"></input-text>
                        
        </div>
      </div>
      <div class="formCol col48 colSm95" v-if="formData.correo.state==1">
        <div class="formRow colSm95">
            
          <label class="formLabel fontWhite" >        
            Teléfono de Contacto:
          </label>
          <span style="min-width:5%;width:5%;"></span>          
          <input-text ref="telefonoRef" :propName="'telefono'" :whiteBackground="false" :mode="'N'" 
          :placeholder="'Teléfono de Contacto'" :capital="true" :maxLength="10" :minLength="10"></input-text>
                        
        </div>
      </div>

      <div class="formCol col48 colSm95" v-if="formData.correo.state==1">
        <div class="formRow colSm95">
              
          <label class="formLabel fontWhite">        
            CURP:
          </label>
          <span style="min-width:5%;width:5%;"></span>
          <input-text ref="curpRef" :placeholder="'CURP'" :propName="'curp'"  :whiteBackground="false"  :mode="'curp'" 
          :capital="true" :maxLength="18" :minLength="18"></input-text>
                      
        </div>
      </div>

      <div class="formCol col48 colSm95" v-if="formData.curp.state==1">
        <div class="formRow colSm95">
              
          <label class="formLabel fontWhite" >        
            Nombre de usuario:
          </label>
          <span style="min-width:5%;width:5%;"></span>
          <input-text ref="userNameRef" :placeholder="'Nombre de Usuario'" :propName="'userName'"  :whiteBackground="false"  :mode="'AN'" 
          :capital="false" :maxLength="35" :minLength="5"></input-text>
                              
        </div>
      </div>

      
      
      <div class="formCol col48 colSm95" v-if="formData.curp.state==1">

        <div class="formRow colSm95">
            
          <label class="formLabel fontWhite" >        
              Nombre(s):
            </label>
            <span style="min-width:5%;width:5%;"></span>            
            <input-text ref="nombreRef" :propName="'nombre'"  :whiteBackground="false" :mode="'A'"   
            :capital="true" :maxLength="120" :minLength="2" :placeholder="'Nombre(s)'"></input-text>
                        
        </div>
      </div>
      <div class="formCol col48 colSm95" v-if="formData.nombre.state==1">
        <div class="formRow colSm95">
              
          <label class="formLabel fontWhite" >        
            Primer Apellido:
          </label>
          <span style="min-width:5%;width:5%;"></span>
          <input-text ref="ap1Ref" :capital="true" :propName="'ap1'" :whiteBackground="false" :mode="'A'" 
          :maxLength="120" :minLength="2" :placeholder="'Primer Apellido'"></input-text>
                      
        </div>
      </div>                    
          
      <div class="formCol col48 colSm95" v-if="formData.nombre.state==1">
        <div class="formRow colSm95">
              
          <label class="formLabel fontWhite" >        
            Segundo Apellido:
          </label>
          <span style="min-width:5%;width:5%;"></span>        
          <input-text ref="ap2Ref" :propName="'ap2'"  :whiteBackground="false"  :mode="'A'" 
          :capital="true" :maxLength="120" :minLength="2" :placeholder="'Segundo Apellido'"></input-text>
                      
        </div>          
      </div>


      <div class="formCol col48 colSm95">
        <div class="formRow colSm95">
            
          <label class="formLabel fontWhite">        
            Estado Secundaria:{{estadoSecundaria}}
          </label>
          <span style="min-width:5%;width:5%;"></span>            
          <input-select ref="estadoRef" :optionName="'Estado'" :whiteBackground="false" 
          :optionsArray="listaEstados" :propName="'estado'" :extraPropName="'estadoSecundaria'" 
          ></input-select>
                            
        </div>
      </div>


      <div class="formCol col48 colSm95">
        <div class="formRow colSm95">
            
          <label class="formLabel fontWhite">        
            Municipio Secundaria:{{municipioSecundaria}}
          </label>
          <span style="min-width:5%;width:5%;"></span>            
          <input-select ref="municipioRef" :optionName="'Municipio'" :whiteBackground="false" 
          :optionsArray="listaMunicipios" :propName="'municipio'" :extraPropName="'municipioSecundaria'"
          :displayFieldname="'c0'" :valFieldName="'c0'"></input-select>
          
                            
        </div>
      </div>


      <div class="formCol col48 colSm95">
        <div class="formRow colSm95">
            
          <label class="formLabel fontWhite">        
            Escuela Secundaria:{{formData.secundaria.data}}
          </label>
          <span style="min-width:5%;width:5%;"></span>            
          <input-select ref="secundariaRef" :optionName="'Escuela Secundaria'" :whiteBackground="false" 
          :optionsArray="listaSecundarias" :propName="'secundaria'" v-if="!showNuevaSecundaria"
          :displayFieldname="'c0'" :valFieldName="'c0'"
          ></input-select>
          
          <input-text ref="secundariaRef" :propName="'secundaria'"  :whiteBackground="false"  :mode="'AN'" 
          :capital="true" :maxLength="120" :minLength="2" :placeholder="'Introduce el nombre de tu escuela secundaria'" v-else></input-text>
          <small v-on:click="showNuevaSecundaria=!showNuevaSecundaria;" v-if="!showNuevaSecundaria" style="color:blue;cursor:pointer;">Mi Escuela Secundaria no esta en la lista</small>
          <small v-on:click="showNuevaSecundaria=!showNuevaSecundaria;" v-else style="color:blue;cursor:pointer;">Ver lista de Escuelas Secundarias</small>
                            
        </div>
      </div>

      <div class="formCol col48 colSm95">
        <div class="formRow colSm95">
              
          <label class="formLabel fontWhite" >        
            Promedio Secundaria:{{formData.promedio.data}}
          </label>
          <span style="min-width:5%;width:5%;"></span>        
          <input-text ref="promedioRef" :propName="'promedio'"  :whiteBackground="false"  :mode="'N'" min="6.0" max="10.0" 
          :capital="false" :maxLength="4" :minLength="1" :placeholder="'Promedio'"></input-text>
                      
        </div>          
      </div>      

      <div class="formCol col48 colSm95">
        <div class="formRow colSm95">
              
          <span style="min-height: 32px;"></span>    
        </div>
      </div>

        
      
      
      <div class="formRow colSm95" v-if="procesando==0">
        
        <button class="btn primary btnWide" v-on:click="registrar()"
        v-if="isRegistroCompleted()"
        >Registrar</button>
        <button class="btn default btnWide" v-on:click="mostrarMensajeRegistrar()" v-else>Registrar</button>        
        <!--
        <button v-on:click="getValues()">Get values</button>       
        -->
      </div>
      <div class="formRow colSm95" v-if="procesando==1">
        <div>
          <h3>
            Procesando Registro...
          </h3>
        </div>                  
        <div>
          <h4>
            Espere por favor
          </h4>
        </div>        
      </div>
    </div>
    <div class="fullWidth tCenter">
      <small @dblclick="showCurrentVersion=true;">
        {{verMsg}}
      </small>
      <small v-if="showCurrentVersion">
      : {{currentVersion}}
      </small>
    </div>

    <modal title="Registro" v-if="showModal" v-on:closeModalEv="closeModal()" style="display:block;left:10vw;top:10vh;height:30vh;">              
      <div style="text-align:center;overflow-y:auto;">
        <h3 style="color:black;">

          {{modalMessage}}
          
        </h3>
        <div v-if="registroExitoso" style="width:100%;display:block;margin-top:10px;">
          <div style="min-height:370px;">
            <label style="color:red;">
              IMPORTANTE: Descarga y guarda tu comprobante de preinscripción, posteriormente serán necesarios para continuar con el proceso de inscripción.
            </label>
            <div class="col80" style="margin:0 auto;color:black;">
              <strong>
                Tu nombre de usuario es:
              </strong>
              <p>
                <strong style="color:blue;">
                  {{this.formData.userName.data}}
                </strong>
              </p>
            </div>
            <div class="col80" style="margin:0 auto;color:black;">
              <strong>
                Tu contraseña es:
              </strong>
              <p>
                <strong style="color:blue;">
                  {{psw}}
                </strong>
              </p>
            </div>
            <div>
              <button class="btn primary" @click="openDescargarComprobante()">
                Descargar Comprobante de Preinscripción
              </button>

              <button class="btn secondary" @click="openDescargarConvocatoria()">
                Descargar Convocatoria
              </button>

              <!--
              <button class="btn primary" @click="closeModal()" v-if="showAceptar">
                Aceptar
              </button>
              <button class="btn default" @click="showMensajeDatosAcceso()" v-else>
                Aceptar
              </button>              
              -->

            </div>
            <div>
              <span  style="min-height:30px;"></span>
            </div>
          </div>
          </div>
      </div>
      
    </modal>

    <button @click="getOferta()" v-if="showGetOferta">
      get Oferta
    </button>


  </div>
</template>

<script>
import { mapStores } from 'pinia';
import { useUserStore } from '../../store/user';
import InputText from '../Inputs/InputText.vue';

import InputSelect from '../Inputs/InputSelect.vue';
import Modal from '../Modal/Modal.vue';

export default {
  name: 'RegistroAspirante',
  components:{
    InputText,    
    InputSelect,
    Modal
  },
  data:function(){

    return{
      showNuevaSecundaria:false,
      showGetOferta:false,
      estadoSecundaria:'',
      municipioSecundaria:'',
      showAceptar:false,
      registroExitoso:false,
      showCurrentVersion:false,
      currentVersion:'',
      verMsg:'Versión Actual',
      modalMessage:'',
      showModal:false,
      registrando:false,
      psw:'',
      horarioNulo:false,
      formData:{
        'modalidad':{data:"",state:0},
        'plantel':{data:"",state:0},
        'carrera':{data:"",state:0,optional:true},
        'correo':{data:"",state:0},
        'telefono':{data:"",state:0},
        'nombre':{data:"",state:0},
        'ap1':{data:"",state:0},
        'ap2':{data:"",state:0},
        'curp':{data:"",state:0},        
        'userName':{data:"",state:0},        
        'horario':{data:"",state:0},        
        'estado':{data:"",state:0},        
        'municipio':{data:"",state:0},        
        'secundaria':{data:"",state:0},        
        'promedio':{data:"",state:0}
      },
      mountedForm:false,
      registroCompleto:false,
      modalidad:'',
      plantel:'',
      horario:'',                  
      procesando:0,
      showGoToIniciarSesion:false,
      listaSecundarias:[],
      listaMunicipios:[],
      listaEstados:[],
      listaModalidades:[
        {c0:"ID",c1:"Bachillerato Escolarizado",
        c2:[
          {c0:"07:00 a 14:00 hrs.",c1:"Matutino",c2:"Matutino"},
          {c0:"14:00 a 21:00 hrs.",c1:"Vespertino",c2:"Vespertino"}
        ]},
        {c0:"ID",c1:"Bachillerato - Mixto",
        c2:[
          {c0:"07:30 a 14:40 hrs.",c1:"Matutino",c2:"Matutino"}          
        ]},      
      ],

      
      listaPlanteles:[
        {c0:"",c1:"XALAPA",c2:[
          {
           c0:"ID",
           c1:"Bachillerato Escolarizado",
           c2:[
            {c0:"07:00 a 14:00 hrs.",c1:"Matutino",c2:"(Matutino)"},
            {c0:"14:00 a 21:00 hrs.",c1:"Vespertino",c2:"(Vespertino)"}
          ]
          },
          {c0:"ID",c1:"Bachillerato Mixto",
           c2:[
            {c0:"07:30 a 14:40 hrs.",c1:"Mixto",c2:"(Mixto)"}
           ]
          },
          {c0:"ID",c1:"Carreras Técnicas",
           c2:[
            {c0:"07:30 a 14:40 hrs.",c1:"Mixto",c2:"(Mixto)"}
           ]
          }
        ]
        },
        {c0:"",c1:"VERACRUZ",c2:[          
          {c0:"ID",c1:"Bachillerato Mixto",
           c2:[
            {c0:"07:30 a 14:40 hrs.",c1:"Mixto",c2:"(Mixto)"}
           ]
          }
        ]},
        {c0:"",c1:"VILLAHERMOSA",c2:[
          {c0:"ID",c1:"Bachillerato Escolarizado",
           c2:[
            {c0:"07:00 a 14:00 hrs.",c1:"Matutino",c2:"(Matutino)"}
          ]
          },          
        ]
        }
      ],
      listaHorarios:[],
      listaCarreras:[
        {c0:"",c1:"Auxiliar Técnico en Alta Repostería"                        },
        {c0:"",c1:"Auxiliar Técnico en Gastronomía"                            },
        {c0:"",c1:"Auxiliar Técnico en Soporte y Mantenimiento de Computadoras"},
        {c0:"",c1:"Auxiliar Técnico en Contabilidad"                           },
        {c0:"",c1:"Auxiliar Técnico en Cultora de Belleza"                     },
        {c0:"",c1:"Auxiliar Técnico en Informática"                            }
      ],
      listaHorariosCarreras2:[],
      listaBL:[],
      listaHorariosCarreras:[
        {c0:"",c1:"XALAPA",c2:[
          {c0:"ID",c1:"Auxiliar Técnico en Alta Repostería",
           c2:[
            {c0:"08:00 a 14:00 hrs.",c1:"Alta Repostería Viernes",c2:"(Viernes)"},
            {c0:"08:00 a 14:00 hrs.",c1:"Alta Repostería Dominical",c2:"(Dominical)"}
          ]
          },
          {c0:"ID",c1:"Auxiliar Técnico en Gastronomía",
           c2:[
            {c0:"07:30 a 14:00 hrs.",c1:"Gastronomía Sabatino",c2:"(Sabatino)"}
           ]
          },
          {c0:"ID",c1:"Auxiliar Técnico en Soporte y Mantenimiento de Computadoras",
           c2:[
            {c0:"07:30 a 14:00 hrs.",c1:"Soporte y Mantenimiento de Computadoras Sabatino",c2:"(Sabatino)"}
           ]
          },
          {c0:"ID",c1:"Auxiliar Técnico en Contabilidad",
           c2:[
            {c0:"14:00 a 20:00 hrs.",c1:"Contabilidad Sabatino",c2:"(Sabatino)"},
            {c0:"08:00 a 14:00 hrs.",c1:"Contabilidad Dominical",c2:"(Dominical)"}
           ]
          },
          {c0:"ID",c1:"Auxiliar Técnico en Cultora de Belleza",
           c2:[
            {c0:"07:30 a 14:00 hrs.",c1:"Cultora de Belleza Sabatino",c2:"(Sabatino)"},
            {c0:"08:00 a 14:00 hrs.",c1:"Cultora de Belleza Dominical",c2:"(Dominical)"}
           ]
          },
          {c0:"ID",c1:"Auxiliar Técnico en Informática",
           c2:[
            {c0:"14:00 a 20:00 hrs.",c1:"Informática Sabatino",c2:"(Sabatino)"}
           ]
          },

        ]
        },
      ]

    };

  },
  mounted:function(){
    this.procesando=0;
    this.mountedForm=true;
    this.userStore.registroView=true;
    this.getCurrentVersion();
    this.getListaCatalogoEstado();
    this.getOferta();
    
    //console.log("Mounted:");
    //console.log(this.$root.$refs);

  },  
  watch:{

    horario:function(){

      let arr=this.filtroHorarioPorModalidadEnPlantel;
      console.log("arreglo horario: ");
      console.log(arr);
      let l=arr.length;
      for(let i=0;i<l;i++){

        if(arr[i].c1==this.horario){

          if(arr[i].c4==0){

            this.showModal=true;
            this.modalMessage="El horario seleccionado ha alcanzado el número máximo de aspirantes, para mayores informes comunícate al plantel. Teléfono: "+arr[i].c5;
            this.horarioNulo=true;

          }else{
            this.horarioNulo=false;
          }

        }
        

      }

    },
    municipioSecundaria:function(){

      this.getListaCatalogoSecundarias();

    },

    estadoSecundaria:function(){

      this.getListaMunicipios();

    },
    modalidad:function(){
            
      
      if(this.modalidad.indexOf("Carreras")!==-1){
        this.formData.carrera.state=0;
        this.formData.carrera.data='';
        this.formData.carrera.optional=false;
      }else{
        this.formData.carrera.optional=true;
      }

    },

  },
  methods:{        


    openDescargarConvocatoria(){
      
      const d = new Date();
      let year = d.getFullYear();
      
      

      if(this.formData.modalidad.data!='Carreras Técnicas'){
        let file="convocatoria.pdf";
        let downloadName="Convocatoria Bachillerato "+year+".pdf";
        window.open("https://webdevworks.xyz/liceoAssets/download.php?file=" +file+"&downloadName="+downloadName);          

      }else{
        let file="convocatoriaCT.pdf";
        let downloadName="Convocatoria Carreras Técnicas "+year+".pdf";
        window.open("https://webdevworks.xyz/liceoAssets/download.php?file=" +file+"&downloadName="+downloadName);          

      }
        
      
    },

    

    openDescargarComprobante(){
      window.open('https://webdevworks.xyz/liceoAssets/descargaComprobante.php?id='+this.formData.userName.data);            
    },

    closeModal(){
      if(this.registroExitoso){
        if(this.showAceptar){
          this.showModal=false;
          
          if(this.registroExitoso){        
            window.location.href='https://www.liceodeartesyoficios.org/';
          }
        }
      }else{
        this.showModal=false;
      }

    },


    getOferta(){
      console.log("getOferta()");
      let data={
      };
      this.$root.sendPOSTRequest(
        "https://webdevworks.xyz/scLC/api/v1/ofertaAspirantesBCT/",
        data,
        this.onSuccessGetOferta,
        this.onErrorGetOferta
        );
    },
    onSuccessGetOferta(data){
      console.log("onSuccessGetOferta data:");
      console.log(data);
      this.setOferta(data);
    },
    onErrorGetOferta(data){
      console.log("data:");
      console.log(data);      
    },
    setOferta(data){

      console.log("setOferta data:");
      console.log(data);      
      /*
      console.log("setOferta listaBAC:");
      console.log(data.listaBAC);      
      */
      this.listaPlanteles=data.listaBAC;
      this.listaHorariosCarreras=data.listaCT;
      this.listaBL=data.listaBL;
      this.listaCarreras.listaCarreras;
      /*
      console.log("listaHorariosCarreras:");
      console.log(this.listaHorariosCarreras);
      this.listaHorariosCarreras2=data.data;
      */
    },
    sendVisita(){
      console.log("sendVisita()");
      let data={        
      };
      this.$root.sendPOSTRequest(
        "https://webdevworks.xyz/scLC/api/v1/registrarVisita/",
        data,
        this.onSuccessSendVisita,
        this.onErrorSendVisita
        );
    },
    onSuccessSendVisita(data){
      console.log("Data:");
      console.log(data.data.data);            
      this.setListaEstados(data.data.data);
    },
    onErrorSendVisita(data){
      console.log(data);
    },



    getListaCatalogoEstado(){
      console.log("getListaCatalogoEstado()");
      let data={        
      };
      this.$root.sendPOSTRequest(
        "https://webdevworks.xyz/scLC/api/v1/definicionEstados/",
        data,
        this.onSuccessGetListaCatalogoEstado,
        this.onErrorGetListaCatalogoEstado
        );
    },
    onSuccessGetListaCatalogoEstado(data){
      console.log("Data:");
      console.log(data.data.data);            
      this.setListaEstados(data.data.data);
    },
    onErrorGetListaCatalogoEstado(data){
      console.log(data);
    },
    setListaEstados(data){
      this.listaEstados=data;
    },

    getListaMunicipios(){
      console.log("getListaMunicipios()");
      let data={
        estado:''+this.estadoSecundaria        
      };
      this.$root.sendPOSTRequest(
        "https://webdevworks.xyz/scLC/api/v1/definicionMunicipios/",
        data,
        this.onSuccessGetListaMunicipios,
        this.onErrorGetListaMunicipios
        );
    },
    onSuccessGetListaMunicipios(data){
      console.log("Data:");
      console.log(data.data.data);            
      this.setListaMunicipios(data.data.data);
    },
    onErrorGetListaMunicipios(data){
      console.log(data);
    },
    setListaMunicipios(data){
      this.listaMunicipios=data;
    },

    getListaCatalogoSecundarias(){
      console.log("getListaCatalogoSecundarias()");
      let data={   
        mun:''+this.municipioSecundaria,
        est:''+this.estadoSecundaria
      };
      this.$root.sendPOSTRequest(
        "https://webdevworks.xyz/scLC/api/v1/definicionSecundarias/",
        data,
        this.onSuccessGetListaCatalogoSecundarias,
        this.onErrorGetListaCatalogoSecundarias
        );
    },
    onSuccessGetListaCatalogoSecundarias(data){
      console.log("Data:");
      console.log(data.data.data);            
      this.setListaCatalogoSecundarias(data.data.data);
    },
    onErrorGetListaCatalogoSecundarias(data){
      console.log(data);
    },
    setListaCatalogoSecundarias(data){
      this.listaSecundarias=data;
    },


    isRegistroCompleted(){
      let formDataKeys=Object.keys(this.formData);
      
      let l=formDataKeys.length;
      for(let i=0;i<l;i++) {
        if(!this.formData[''+formDataKeys[i]].optional){
          
          if(this.formData[''+formDataKeys[i]].state!==1){
            return false;
          }
        }                
      }            
      return true;
    },

    getValues(){
      console.log("app");
      console.log(this.$root);
      /*
      this.$data['modalidad']="prueba";
      console.log("Data");
      console.log(this.$data['modalidad']);
      */
      console.log("Correo Ref val:");
      console.log(this.$refs.correoRef.getData());

    },
    /*
    testRegistro(){

      this.email="prueba@gmail.com";
      this.nombre="prueba";
      this.ap1="prueba";
      this.ap2="prueba";
      this.curp="prueba";
      this.telefono="prueba";

    },*/

    toIniciarSesion(){
      this.$root.changeTo(
        {
         name:'firstStart',
         params:{
          email:''+this.email,
          passw:''+this.password
         }
        });
    },

    mostrarMensajeRegistrar(){
      alert("Debes de llenar correctamente todos los campos antes de poder continuar con su registro");
    },
    
    registrar(){
      if(this.procesando==0){
        if(this.horarioNulo==false){
          this.sendRegistrar();
        }else{
          this.showModal=true;
          this.modalMessage="El horario seleccionado no permite el registro de nuevos aspirantes.";
        }
      }
    },
    showAceptarBtn() {
      this.showAceptar=true;
    },
    showMensajeDatosAcceso(){
      alert("Importante: guarda tus datos de acceso, posteriormente serán necesarios para continuar con el proceso de inscripción");
    },
    sendRegistrar(){

      let password=this.getPassword();
      let request_data=this.$root.getData(this.formData);
      this.procesando=1;        
      let programa=this.modalidad;
      if(this.formData.modalidad.data.indexOf('Carreras')!=-1){

        programa=this.formData.carrera.data;

      }



      let data={
        horario:''+this.horario,
        tipoInscripcion:'ASP',
        programa:''+programa, 
        plantel:''+this.formData['plantel'].data,
        curp:''+this.formData['curp'].data,
        correo:''+this.formData['correo'].data,
        password:''+password,
        telefono:''+this.formData['telefono'].data,    
        usName:''+this.formData['userName'].data,    
        nombre:''+this.formData['nombre'].data,    
        ap1:''+this.formData['ap1'].data,    
        ap2:''+this.formData['ap2'].data,
        estado:''+this.formData['estado'].data,
        municipio:''+this.formData['municipio'].data,
        secundaria:''+this.formData['secundaria'].data,
        promedio:''+this.formData['promedio'].data        
      };
      //request_data['password']=this.password;
      console.log("request_data:");
      console.log(request_data);

      console.log("data:");
      console.log(data);

      //this.$root.sendRequest("http://192.168.1.74:8086/api/login",request_data,this.onSuccessLogin,this.onErrorLogin);
      this.$root.sendPOSTRequest("https://webdevworks.xyz/scLC/api/v1/nuevoRegistroAspirante/",
      data,
      this.onSuccessRegistrar,
      this.onErrorRegistrar);

    },
    onSuccessRegistrar(data){

      console.log(data);

      console.log(data.exists);
      console.log(typeof data.exists);
      console.log(data.insert);
      console.log(typeof data.insert);

      if(data.exists=='0'&&data.insert=='ok'){
        
        this.showModal=true;
        this.modalMessage="Tu registro fue exitoso, un asesor educativo te contactará para continuar con el proceso de inscripción.";
        this.registroExitoso=true;
        this.psw=atob(data.psw);
        
        const myTimeout = setTimeout(this.showAceptarBtn, 10000);
        
        console.log(myTimeout);
        //this.modalMessage+='\n\nTu contraseña es:'+this.psw;
        


      }else{
        this.showModal=true;

        if(data.registroPermitido=='0'){

          this.modalMessage="Error: "+data.insert;
          this.procesando=0;

        }else{
          if(data.exists=='1'){
            this.modalMessage="Error: "+data.insert;
            this.procesando=0;
          }else{
            this.modalMessage="Error: Ocurrio un error al registrar al aspirante, por favor intente nuevamente.";
            this.procesando=0;
          }                
        }
      }

    },
    onErrorRegistrar(error){
      console.log(error);
      alert("Ocurrio un error: "+error);

    },



    getCurrentVersion(){

      
      let request_data=this.$root.getData(this.formData);        
      let data={};
      //request_data['password']=this.password;
      console.log("request_data:");
      console.log(request_data);

      console.log("data:");
      console.log(data);

      //this.$root.sendRequest("http://192.168.1.74:8086/api/login",request_data,this.onSuccessLogin,this.onErrorLogin);
      this.$root.sendPOSTRequest("https://webdevworks.xyz/scLC/api/v1/verificarVersionPortalAlumnos/",
      data,
      this.onSuccessVersion,
      this.onErrorVersion);

    },
    onSuccessVersion(data){

      console.log(data);

      
      this.setVersionState(data.version);

    },
    onErrorVersion(error){
      console.log(error);
      //alert("Ocurrio un error: "+error);

    },

    setVersionState(currentVersion){
      console.log('currentVersion:');
      console.log(currentVersion);
      let version=currentVersion;

        //localStorage.clear();
        if(localStorage.estado){
            let estadoLoaded=JSON.parse(localStorage.estado);
            //this.version=estadoLoaded.version;
            //this.versionActual=version;
            //alert("Version actual: "+estadoLoaded.version);
            console.log("Estado:");
            console.log(localStorage.estado);
            console.log("estadoLoaded.version="+estadoLoaded.version);
            console.log("currentVersion="+currentVersion);

            if(estadoLoaded.version!=version){
              let estado={
                version:""+version,
                actualizado:true
                
              };
              
              localStorage.estado=JSON.stringify(estado);
              //this.userData.version=currentVersion;
              //alert("Estado Actualizado");
              this.verMsg="Versión Actualizada";
              console.log("Versión Actualizada");
              
              console.log(localStorage.estado);
              location.reload(true);
            }else{
              this.sendVisita();
            }
            this.currentVersion=currentVersion;
            

        }else{
          let estado={
            version:""+currentVersion,
            actualizado:true
          };
          //this.userData.version=currentVersion;
          localStorage.estado=JSON.stringify(estado);
          //alert("Estado Guardado");
          this.verMsg="Versión guardada";
          console.log(localStorage.estado);
          this.currentVersion=currentVersion;
        }

    },



    async setExitoso(){
      this.procesando=2;
      
      
      await setTimeout(function(){
        this.setShowIniciarSesionBtn();
        }.bind(this), 2000);

    },
    setShowIniciarSesionBtn(){
      this.showGoToIniciarSesion=true;
    },
    

    setError(){
      this.procesando=-1;
    },

    getPassword(){

      let chars=[
        'a','b','c','d','e','f','g','h','i','j',
        'k','l','m','n','o','p','q','r','s','t',
        'u','v','w','x','y','z',
        '0','1','2','3','4','5','6','7','8','9',
        'A','B','C','D','E','F','G','H','I','J',
        'K','L','M','N','O','P','Q','R','S','T',
        'U','V','W','X','Y','Z',        
        ];

      let passw="";
      let nChars = 8;
      let l = chars.length-1;
      let r = Math.floor(Math.random(l)*l);

      console.log("l:"+l);

      for(let i=0;i<nChars;i++){
        
        r = Math.floor(Math.random(l)*l);
        console.log(r);
        passw+=chars[r];

      }


      console.log("Password: "+passw);
      return passw;

    }


  },
  computed:{    
    ...mapStores(useUserStore),
    filtroModalidadesPorPlantel(){
      let arr=[];
      let plantel=this.formData.plantel.data;
      let lp=this.listaPlanteles.length;

      

      for(let i=0;i<lp;i++){

        if(this.formData.plantel.data=='EN LÍNEA'){
        
          if(this.listaBL[i].c1==plantel){
              arr=this.listaBL[i].c2;
          }

        }else{

            if(this.listaPlanteles[i].c1==plantel){
              arr=this.listaPlanteles[i].c2;
            }

        }
      }

      //this.listaModalidades=arr;
      return arr;
    },
    filtroHorarioPorModalidadEnPlantel(){

      let arrModalidad=[];      
      let arrHorario=[];      
      
      let plantel=this.formData.plantel.data;
      

      let listaHorario=[];
      
      let modalidad='';
      if(this.modalidad=='Carreras Técnicas'){
        listaHorario = this.listaHorariosCarreras;
        modalidad = this.formData.carrera.data;
      }
      else{

        if(this.formData.plantel.data=='EN LÍNEA'){

          listaHorario = this.listaBL;
          modalidad = this.formData.modalidad.data;

        }else{

            listaHorario = this.listaPlanteles;
            modalidad = this.formData.modalidad.data;

        }
      }

      let lp=listaHorario.length;
      let l=this.listaModalidades.length;
      console.log("lista usada para filtro:");
      console.log(listaHorario);

      for(let i=0;i<lp;i++){
        if(listaHorario[i].c1==plantel){
          arrModalidad=listaHorario[i].c2;
        }
      }
      
      l=arrModalidad.length;
      
      

      for(let j=0;j<l;j++){
        console.log(arrModalidad[j].c1+'='+modalidad);
        if(arrModalidad[j].c1==modalidad){
          arrHorario=arrModalidad[j].c2;
        }
      }
      
      //this.listaHorarios=arr;
      return arrHorario;

    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
