<template>
  <div class="registro" style="min-height:60vh;">

    <!--
    <div class="subtitleBlueDark fullWidth">
      <label v-on:dblclick="testRegistro()">
      Registro de Datos del Aspirante
      </label>
    </div>
    -->
    <div class="form__panel fullWidth" >
      <!--
      <div class="col95 center">
        <div class="col25 colSm50 row rowm btnBlue tCenter">
          <small>
            Firmar y Descargar Ficha de Registro
          </small>
        </div>
        <div class="col25 colSm50 row rowm btnBlue tCenter">
          <small>
            Descargar Ficha Registro
          </small>
        </div>

        <div class="col25 colSm50 row rowm btnBlue tCenter">
          <small>
            Cargar Ficha de Registro
          </small>
        </div>

      </div>
      -->

      <div class="col95">
        <div class="blockRow subtitleBlueDark separator">
          Firmar Ficha de Registro
    <!--
          {{userAgent}} - {{pdfMode}}
    -->          
        </div>

        <div class="fullWidth colSm95 tCenter" style="background:var(--default);color:white;border-radius:5px;margin:5px;" v-if="docSrc==''">
          <label v-if="modo=='p'">
            <p>
              1)Introduzca la firma del padre o tutor dentro del área blanca y presione 
              <b>
                "Guardar Firma"
              </b>
            </p>
            
          </label>
          <label v-if="modo=='a'">
            <p>
              1)Introduzca la firma del aspirante dentro del area blanca y presione "Guardar Firma"
            </p>            
            
          </label>
        </div>
                
        <div class="col30 colSm95 row" :style="'overflow:hidden;border-radius:5px;background:'+(modo=='p'?'var(--blue);':'var(--orange);')" v-if="docSrc==''">
            <div class="block fullWidth">
              <button class="col49 colSm45 row rowm btnBlue tCenter" @click="modo='p'" style="margin:1px;">
                <i class="bi bi-pencil-square"></i>
                <small>
                  Firma<br>Padre o tutor
                </small>
              </button>
              <button  class="col49 colSm45 row rowm btnOrange tCenter" @click="modo='a'" style="margin:1px;">
                <i class="bi bi-pencil-square"></i>
                <small>
                  Firma<br>Aspirante
                </small>
              </button>
              <div>
                <label>
                  <strong v-if="modo=='p'" style="color:white;">
                    Firma del Padre:
                  </strong>
                  <strong v-if="modo=='a'" style="color:white;">
                    Firma del Aspirante:
                  </strong>
                </label>
              </div>

            </div>

            <canvas 
              id="canvas" 
              @touchstart="touchStart"
              @touchmove="touchMove"
              style="background:white;border:solid white 4px;overflow:hidden;border:solid 2px darkgray;"             
              @mouseup="mouseUpEv" 
              @mousedown="mouseDownEv" 
              @mousemove="mouseMovingEv"
              @mouseleave="mouseUpEv"
            >
            </canvas>
            
            

            <div class="tCenter;">
              <button  class="col49 colSm50 row rowm btnBlue tCenter" @click="clearPad()">
                <i class="bi bi-sticky-fill"></i>
                <small>
                  Limpiar Area
                </small>
              </button>   

              <button  class="col49 colSm50 row rowm btnBlue tCenter" @click="guardarFirmaPadre()" v-if="modo=='p'">
                <i class="bi bi-bookmark-plus-fill"></i>
                <small>
                  Guardar Firma
                </small>
              </button>

              <button  class="col49 colSm50 row rowm btnBlue tCenter" @click="guardarFirmaAlumno()" v-if="modo=='a'">
                <i class="bi bi-bookmark-plus-fill"></i>
                <small>
                  Guardar Firma
                </small>
              </button>

              <!--
              <button class="hideMobile col30 colSm50 row rowm btnBlue tCenter" @click="sendBase64()">
                <i class="bi bi-search"></i>
                <small>
                  Previsualizar
                </small>
              </button>
              -->
              
              <!--
                <a download="fichaRegistroAspirante.pdf" :href="'https://docs.google.com/gview?embedded=true&url='+docSrc">Previsualizar Mobile</a>
                -->
              <!--
              <a class="hideDesktop btnBlue col30 colSm50 row rowm tCenter" 
              style="text-decoration: none;" 
              href="https://docs.google.com/gview?embedded=true&url=https://www.sistemaregistroupn.com/ecfstr/store/2023/d087d81c66.pdf">
                <i class="bi bi-search"></i>
                Previsualizar Mobile
              </a>
              -->

              

            </div>
          
        </div>  

        

        <div class="fullWidth colSm90 row" style="height:500px;max-height:480px;" v-if="docSrc!=''">          
              <!--                
                {{docSrc}}
                <iframe src="https://docs.google.com/gview?embedded=true&url=https://www.sistemaregistroupn.com/ecfstr/store/2023/d087d81c66.pdf" style="display:inline;float:left;width:100%;min-height:350px;">
                </iframe>
              -->
            <div class="fullWidth orange" style="border-top-left-radius: 5px;border-top-right-radius: 5px;">
              <strong>
                Sí la firma es correcta, presiona "Finalizar Firma" para terminar este paso.
              </strong>
            </div>
            <div class="fullWidth orange">

              <button  class="col49 colSm45 row rowm btnBlue tCenter" @click="sendBase64()" v-if="docSrc!=''">
                <i class="bi bi-pencil-square"></i>
                <small>
                  Finalizar <br>Firma
                </small>
              </button>

              <button  class="col49 colSm45 row rowm btnDefault tCenter" @click="reiniciarFirma()" v-if="base64!=''">
                <i class="bi bi-arrow-clockwise"></i>
                <small>
                  Reiniciar <br>Firma
                </small>
              </button>

            </div>

            <iframe class="col95" :src="docSrc" style="display:inline;float:left;width:100%;min-height:350px;"  v-if="pdfMode=='Android-Firefox'||pdfMode=='PC-Windows'">
            </iframe>
            
            <iframe :src="'https://docs.google.com/gview?embedded=true&url='+docSrc" style="display:inline;float:left;width:100%;min-height:350px;" v-if="pdfMode=='Android-Chrome'">
            </iframe>

        </div>      

        <div v-if="userStore.datosRegistrados==0" style="margin-top:10px;">          
          <div class="fullWidth row rowm center colSm50 btnBlue tCenter" @click="showPadreTutor=false;showSeguridadSocial=true;">
            Continuar Registro <br>de Información de Seguridad Social y Salud
          </div>
        </div>

      </div>         
<!--
      <div>
          {{formData}}
      </div>
-->      
      <div class="col90" style="min-height:40px;">
        <span style="min-height:20px;"></span>
      </div>

    </div>


  </div>
</template>

<script>



//import Modal from '../Modal/Modal.vue';
import { mapStores } from 'pinia';
import { useUserStore } from '../../store/user';
import axios from "axios";


export default {
  name: 'FirmarDescargarFichaRegistroAspirante',

  components:{},
  data: function(){
    return{
      base64:'',
      base64Padre:'',
      canvas:{},
      context:{},
      drawEnabled:false,
      initialX:0,
      initialY:0,
      docSrc:'',
      canvastop:{},
      lastx:0,
      lasty:0,
      modo:'p',
      userAgent:'',
      pdfMode:'Android-Chrome'
      
    };

  },

  watch:{
    
    estadoNac:function(){
      this.getListaMunicipios(this.estadoNac,'nac');
    },
    estadoRes:function(){
      this.getListaMunicipios(this.estadoRes,'res');
    },

  },
  mounted:function(){

    console.log("Mounted firma");
    this.canvas=document.getElementById('canvas');
    this.context=this.canvas.getContext('2d');
    this.context.fillStyle = "white";
    //this.canvasStop = this.canvas.offsetTop;
    this.canvasStop = this.canvas.offsetTop + this.canvas.height-64;
    this.userAgent = navigator.userAgent;
    //this.context.fillRect(0, 0, 360, 360);
    //console.log("Context:");
    //console.log(this.context);
  },
  methods:{    


    sendCloseOpen(){

      this.$emit("closeOpenEv");
      console.log("sendCloseOpen()");

    },

    reiniciarFirma(){
      console.log("reiniciarFirma()");
      this.clearPad();
      this.base64='';
      this.base64Padre='';
      this.docSrc='';
      this.sendCloseOpen();

      /*
      setTimeout(myGreeting, 200);

      function myGreeting() {
        this.sendCloseOpen();
      }*/
      

      /*
      this.canvas=document.getElementById('canvas');
      this.context=this.canvas.getContext('2d');
      this.context.fillStyle = "white";
      
      this.canvasStop = this.canvas.offsetTop + this.canvas.height-64;
      */
    },

    touchStart(event){
      event.preventDefault();                 
      //event.stopPropagation();
      //console.log("touchStart");
      this.lastx = event.touches[0].clientX - 27;
      this.lasty = event.touches[0].clientY - this.canvasStop;

      this.dot(this.lastx,this.lasty);

    },

    touchMove(event){                   
    event.preventDefault(); 
    //console.log("touchMove");                

    let newx = event.touches[0].clientX - 27;
    let newy = event.touches[0].clientY - this.canvasStop;

    this.line(this.lastx,this.lasty, newx,newy);
    
    this.lastx = newx;
    this.lasty = newy;
    },

    line(fromx,fromy, tox,toy) {
      //console.log('Line');
      this.context.strokeStyle='navy';
      this.context.beginPath();
      this.context.moveTo(fromx, fromy);
      this.context.lineWidth=3;
      this.context.lineTo(tox, toy);
      this.context.stroke();
      this.context.closePath();
    },

    dot(x,y) {
      //console.log('Dot');
      this.context.beginPath();
      this.context.fillStyle = "#000000";
      this.context.strokeStyle='navy';
      this.context.arc(x,y,1.3,0,Math.PI*2,true);
      this.context.fill();
      this.context.stroke();
      this.context.closePath();
    },

    guardarFirmaAlumno(){
      this.base64=this.toBase64();
      this.base64=''+this.base64.replace('data:image/png;base64,','');
      this.clearPad();
      this.sendBase64();
      
    },
    guardarFirmaPadre(){
      this.base64Padre=this.toBase64();
      this.base64Padre=''+this.base64Padre.replace('data:image/png;base64,','');
      this.clearPad();
      this.modo='a';
    },

    sendBase64(){
      this.docSrc='';
      //this.toBase64();
      
      
      //this.base64Padre=''+this.base64Padre.replace('data:image/png;base64,','');
      console.log("sendBase64()");
      //console.log("base64 enviada:");
      //console.log(this.base64);
      let file = new Blob([''+this.base64], { type: 'text/plain' });
      let file2 = new Blob([''+this.base64Padre], { type: 'text/plain' });
      let formData = new FormData();
      formData.append("fileToUpload", file);
      formData.append("fileToUpload2", file2);
      formData.append("idp", this.userStore.idp);
      
      //console.log("file:");
      //console.log(file);      
      //console.log("IDP:");
      //console.log(this.userStore.idp);


      
      const uriServer = "https://webdevworks.xyz/liceoAssets/fichaRegistroFirmada.php";

      axios
        .post(uriServer, formData, {
          headers: {
            "Content-Type": "multipart/form-data"
          }
        })
        .then(response =>{
          //console.log("Response:");
          //console.log(response);
          //console.log(response.data);
          //console.log(response.data.response_data.b64.base64);
          //console.log(response.data.response_data.path);
          //console.log(response.data.response_data.status);          
          //this.docSrc = 'data:application/pdf;base64,'+response.data;
          //this.docSrc = 'data:application/pdf;base64,'+response.data;
          //response.data.response_data.b64.base64
          if(this.userAgent.indexOf('Windows')!=-1){
            this.pdfMode='PC-Windows';
            this.docSrc=''+response.data.response_data.b64.base64;
            //this.docSrc = 'data:application/pdf;base64,'+response.data;
          }else{
            if(this.userAgent.indexOf('Android')!=-1){

              if(this.userAgent.includes('Firefox')){
                this.pdfMode='Android-Firefox';
                this.docSrc=''+response.data.response_data.b64.base64;
              }else{
                this.docSrc=''+response.data.response_data.path;
              }
              //this.docSrc = 'data:application/pdf;base64,'+response.data;
            }
          }

          console.log("SUCCESS");
        })
        .catch(error => {
          console.log(error);
        });

    /*
      let data={        
      };
      this.$root.sendGETRequest(
        "https://webdevworks.xyz/scLC/api/v1/imagenBase64.php?base64="+this.base64,
        data,
        this.onSuccessSendRegistrar,
        this.onErrorSendRegistrar
        );
    */
    },    

    

    toBase64(){

      return this.canvas.toDataURL('image/png');

    },
    
    clearPad(){
      this.context.clearRect(0, 0, this.canvas.width, this.canvas.height);
    },
    mouseDownEv(e){
      //console.log(e);
      this.drawEnabled=true;      
      /*
      console.log("mouseDownEv");      
      console.log("X:"+this.initialX);
      console.log("Y:"+this.initialY);
      */      
    },
    mouseUpEv(){
      this.drawEnabled=false;
    },
    mouseMovingEv(e){
      if(this.drawEnabled){
        //this.draw(this.initialX,this.initialY);
        this.draw(e.offsetX,e.offsetY);
      }
    },
    draw(x,y){
      //console.log("draw");
      //let canvas=document.getElementById('canvas');
      //let context=canvas.getContext('2d');
      this.initialX=x;
      this.initialY=y;

      this.context.beginPath();
      this.context.moveTo(this.initialX,this.initialY);
      this.context.strokeStyle='navy';
      this.context.lineWidth=3;
      this.context.lineCap='round';
      this.context.lineJoin='miter';
      this.context.lineTo(x,y);
      this.context.stroke();
    },    
    handleFileDoc(){
                
        let doc = this.docParam;
        this.cargando=1;
        console.log("Handle File Doc:");
        console.log(doc);        
        
        this.$forceUpdate();

        console.log("Nombre Doc: "+doc.c1+'ref');
        console.log(this.$refs);
        console.log("IputRef:");
        console.log(this.$refs[doc.c1+'ref']);
        console.log("InputRefValue:");
        console.log(this.$refs[doc.c1+'ref'].value);
        
        console.log("Archivo: ");
        console.log(this.$refs[doc.c1+'ref']);
        console.log(this.$refs[doc.c1+'ref'].files);
        console.log(this.$refs[doc.c1+'ref'].files[0]);
        
        let file = this.$refs[doc.c1+'ref'].files[0];

        console.log("File:");
        console.log(file);

        console.log("FileSize:");
        console.log(file.size);

        let tamArchSeleccionado = (file.size/1048576);
        console.log("FileSizeMB:");
        console.log( tamArchSeleccionado );
        
        console.log("FileName:");
        console.log( file.name );
        let fileName=file.name;
        let ext=(fileName.substring(fileName.lastIndexOf("."))).toLowerCase();
        console.log(ext);

        let tamMaxPermitido = parseFloat(''+doc.c4);
        console.log("Tam en MB"+tamArchSeleccionado+"<"+tamMaxPermitido);

        if(doc.c3==ext){

          if(tamArchSeleccionado<tamMaxPermitido){
          
            let date = new Date();
            let timeStamp =
              "" +
              date.getFullYear() +
              "-" +
              (date.getMonth() + 1) +
              "-" +
              date.getDate() +
              " " +
              date.getHours() +
              ":" +
              date.getMinutes() +
              ":" +
              date.getSeconds();

            console.log(timeStamp);            

            let formData = new FormData();
            formData.append("fileToUpload", file);            
            formData.append("archivoSizeMb", doc.c4);
            formData.append("extensionDeseada", doc.c3);
            formData.append("folio", this.userStore.idp);
            formData.append("aIngreso",this.userStore.anioIngreso);
            formData.append("nombre",doc.c5);
            console.log(formData);
            
            this.uploadDocFile(formData);
          }else{

            this.$emit('showModalEv',{msg:''+"El archivo no se pudo cargar. El limite de tamaño de archivo permitido para este tipo de documento es de "+tamMaxPermitido+" MByte(s), Por favor reduzca el tamaño del archivo e intente nuevamente"});

            this.cargando=0;
            this.$refs[doc.c1+'ref'].value="";
          }
        }else{

          this.$emit('showModalEv',{msg:''+"Error: "+"Error el tipo de Archivo seleccionado: "+ext+" no concuerda con el tipo especificado: "+doc.c3});

          this.cargando=0;
          this.$refs[doc.c1+'ref'].value="";
        }

    },
    async uploadDocFile(formData) {
            
      const uriServer = "https://webdevworks.xyz/liceoFSTR/uploadASP.php";

      axios
        .post(uriServer, formData, {
          headers: {
            "Content-Type": "multipart/form-data"
          }
        })
        .then(response => {
          console.log(response.data);
          console.log(response.data.response_data.status);
          //
          //this.listaDocumentos[index].cargando = response.data.response_data.status;
          
          this.cargando=0;
          let strExito=response.data.response_data.msg;
          console.log("response.data.response_data.msg:");
          console.log(response.data.response_data.msg);


          if (response.data.response_data.status == 1) {
            //this.fichaEscolar.status=-1;
            //alert(strExito);
            this.resultadoCarga = response.data.response_data.status;
            this.modalMsg=strExito;
            this.nombreArchivo=response.data.response_data.fileName;
            //this.updateDocData(strExito);
            this.sendUpdateEstadoDocumentosAspirante(strExito);
          }else{            
            
            this.$emit('showModalEv',{msg:''+"Error: "+strExito});
            this.resultadoCarga = response.data.response_data.status;
            this.$refs[this.docParam.c1+'ref'].value="";
          }

          console.log("SUCCESS");
        })
        .catch(error => {
          console.log(error);
        });
      
    },
    sendUpdateEstadoDocumentosAspirante(){
      console.log("sendUpdateEstadoDocumentosAspirante()");

      let archExtension=this.nombreArchivo.substring(this.nombreArchivo.lastIndexOf('.'));
      let data={
        idp:''+this.userStore.idp,        
        iddoc:''+this.idDoc,
        idCatDoc:''+this.docParam.c10,
        nombre:''+this.nombreArchivo,        
        estado:'En Verificacion',
        tipoArch:''+archExtension,
        observ:'Archivo Cargado en Espera de Verificación'
      };
      this.$root.sendPOSTRequest(
        "https://webdevworks.xyz/scLC/api/v1/actualizarEstadoDocumentoAspirante/",
        data,
        this.onSuccessSendUpdateEstadoDocumentosAspirante,
        this.onErrorSendUpdateEstadoDocumentosAspirante
        );
    },
    onSuccessSendUpdateEstadoDocumentosAspirante(data){
      console.log("onSuccessSendUpdateEstadoDocumentosAspirante");
      console.log("Data success:");
      console.log(data);            
      
      this.estado=data.estado;
      this.observacion=data.observ;
      this.idDoc=data.id;
      
      this.$emit('updateDocEv',{c0:''+this.idDoc,c1:''+this.docParam.c1,c6:''+this.estado,c7:''+this.observacion});
      this.$emit('showModalEv',{msg:''+this.modalMsg});
      this.userStore.documentosCargados=1;
      this.filter(this.estado);
    },
    onErrorSendUpdateEstadoDocumentosAspirante(data){
      console.log("onErrorGetListaDocumentosAspirante");
      console.log(data);
    },


  },
  computed:{    
    ...mapStores(useUserStore),
  },
  props: {
    
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
