<template>
  <div>
    
    <div class="blockRow subtitleBlueDark separator">
      Descarga de Comprobante de Inscripción
    </div>
    <div class="form__panel">
      
      
      <div>
        <button class="btn primary" v-on:click="getLink();">
          Descargar Comprobante
        </button>
      </div>
      

    </div>

  </div>
</template>

<script>
export default {
  name: 'DescargarComprobante',
  props: {
    msg: String
  },
  data:function(){
    return{
      estado:'Pendiente'
       

    };
  },
  methods:{

    getLink(){

      let link='https://webdevworks.xyz/propuestaliceo/prototipo/comprobante.php?nombre=nombre primerApellido segundoApellido&folio=2201B0001&periodo=Octubre 2022 - Diciembre 2022&noPeriodo=1&indicaciones=indicaciones&sede=Xalapa';

      window.open(link);

    }

  },
  computed:{    

    userData(){
      return this.$store.state.userData;
    },
    sys(){

      return this.$store.state.sys;

    }

  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
