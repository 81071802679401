<template>
  <div class="CargaDocumentos">
    

    <div class="form__panel fullWidth">
      <div class="col50 row">
        <button class="btn default fullWidth" @click="modo='pendientes';">Pendientes<span style="background:white;color:black;border-radius:30px;padding:3px;margin:3px;"><strong>{{nPendientes}}</strong></span></button>
      </div>
      <div class="col50 row">
        <button class="btn primary fullWidth" @click="modo='cargados';">Cargados<span style="background:orangered;color:white;border-radius:30px;padding:3px;margin:3px;"><strong>{{nCargados}}</strong></span></button>
      </div>
      
    </div>
    <div class="form__panel fullWidth" style="min-height:20px;">
      <span></span>
    </div>

    <div class="form__panel fullWidth">
      <div class="blockRow subtitleBlueDark separator">
        <label v-if="modo=='pendientes'">
          Documentos Pendientes
        </label>
        <label v-else>
          Documentos Cargados
        </label>
      </div>
      

      <carta-documento      
        v-for="doc in listaDocumentos" 
        v-bind:key="doc"
        v-bind:docParam="doc" 
        v-bind:class="getClass(doc.status)"
        v-bind:modo="modo"        
        v-on:updateDocEv="updateDoc"
        v-on:showModalEv="openModal"
      >
      </carta-documento>      

      <div class="fileCard default" style="background:none;">        
      </div>
      
      <div class="form__row" style="min-height:100px;">
        <span></span>
      </div>

    </div>
    <modal title="Carga de Documentos" v-if="showModal" v-on:closeModalEv="showModal=false;" style="display:block;left:10vw;top:10vh;height:30vh;">              
      <div style="text-align:center;">
        <h3 style="color:black;">
          {{modalMessage}}
        </h3>
      </div>
    </modal>
  </div>
</template>

<script>
import { mapStores } from 'pinia';
import { useUserStore } from '../../store/user';
import CartaDocumento from './CartaDocumento.vue';
import Modal from '../Modal/Modal.vue';

export default {
  name: 'CargaDocumentosAspirante',
  components:{
    CartaDocumento,
    Modal
  },
  data:function(){

    return{
      modo:'pendientes',
      listaDocumentos:[],
      nPendientes:0,
      nCargados:0,
      nCorrectos:0,
      nTotal:0,
      modalMessage:'',
      showModal:false,

      


    };

  },
  watch:{

    

  },
  mounted:function(){

    //this.getListaDocumentos();
    this.getListaDocumentosAspirante();

  },
  methods:{

    openModal(data){

      this.showModal=true;
      this.modalMessage=''+data.msg;
      
    },
    updateDoc(dataDoc){
      console.log("updateDoc:");
      console.log(dataDoc);


      let l=this.listaDocumentos.length;

      for(let i=0;i<l;i++){

        if(this.listaDocumentos[i].c1==dataDoc.c1){

          this.listaDocumentos[i].c0=dataDoc.c0;
          this.listaDocumentos[i].c6=dataDoc.c6;
          this.listaDocumentos[i].c7=dataDoc.c7;

        }

      }

      this.updateCount();

    },
    updateCount(){
      console.log("updateCount()");
      this.nPendientes=0;
      this.nCargados=0;
      this.nCorrectos=0;
      let l=this.listaDocumentos.length;      

      for(let i=0;i<l;i++){
        if(this.listaDocumentos[i].c6=='No Disponible'){
          this.nPendientes++;
        }
        if(this.listaDocumentos[i].c6=='Con observacion'){
          this.nPendientes++;
        }
        if(this.listaDocumentos[i].c6=='En Verificacion'){          
          this.nCargados++;
        }
        if(this.listaDocumentos[i].c6=='Correcto'){
          this.nCargados++;
          this.nCorrectos++;
        }        
      }



    },
    getListaDocumentosAspirante(){
      console.log("getListaDocumentosAspirante()");
      let data={
        idp:''+this.userStore.idp         
      };
      this.$root.sendPOSTRequest(
        "https://webdevworks.xyz/scLC/api/v1/listaDocumentosAspirantes/",
        data,
        this.onSuccessGetListaDocumentosAspirante,
        this.onErrorGetListaDocumentosAspirante
        );
    },
    onSuccessGetListaDocumentosAspirante(data){

      console.log("onSuccessGetListaDocumentosAspirante");
      console.log("Data success:");
      console.log(data);
      //console.log(typeof data.data.c0);         
      console.log(data.data);
      console.log(data.data.data);
      this.setListaDocumentosAspirante(data.data.data);
      
    },
    onErrorGetListaDocumentosAspirante(data){
      console.log("onErrorGetListaDocumentosAspirante");
      console.log(data);
    },
    setListaDocumentosAspirante(data){
      console.log("setListaDocumentosAspirante:");
      console.log(data);
      //console.log("Tipo:");
      //console.log(typeof data);
            
      this.nTotal=data.length;      

      //this.listaDocumentos = data;
      let arr = [];
      let l = data.length;
      for(let i=0;i<l;i++){

        if(data[i].c1!='Ficha de Registro'){
          arr[arr.length]=data[i];
        }

      }

      this.listaDocumentos=arr;

      this.updateCount();

    },
    

    getClass(status){

      let cssClass="fileCard grisCartaDocumentos";
      if(status=='En Verificacion'){
        cssClass="fileCard primary";
      }
      if(status=='Con observacion'){
        cssClass="fileCard comment";
      }
      if(status=='Correcto'){
        cssClass="fileCard correct";
      }
      return cssClass;

    },

    getListaDocumentos(){
      this.setListaDocumentos();
    },
    setListaDocumentos(){
      let arr=[
        {          
          name:"Fotografia",          
          fileName:"img/user.jpg",
          uploadFileName:"",
          extensionOptions:["jpg"],
          description:"Fotografia tipo credencial, playera o blusa negra fondo blanco",
          maxSize:"1",
          comments:"",
          status:"Correcto",
          loadDate:"03-07-2022 04:33:00",
          validationDate:""
        },
        {          
          name:"Acta de Nacimiento",
          fileName:"acta de nacimeinto.pdf",
          uploadFileName:"",
          extensionOptions:["pdf"],
          description:"Archivo Formato .PDF menor a 1.8MB(MegaBytes)",
          maxSize:"1.8",
          comments:"",
          status:"No disponible",
          loadDate:"03-07-2022 04:33:00",
          validationDate:""
        },
        {          
          name:"CURP",
          fileName:"curp.pdf",
          uploadFileName:"",
          extensionOptions:["pdf"],
          description:"Archivo Formato .PDF menor a 1.5MB(MegaBytes)",
          maxSize:"1.5",
          comments:"",
          status:"En Verificación",
          loadDate:"03-07-2022 04:33:00",
          validationDate:""
        },
        {          
          name:"Comprobante de Domicilio",
          fileName:"domicilio.pdf",
          uploadFileName:"",
          extensionOptions:["jgp,pdf"],
          description:"Archivo Formato .JPG o .PDF menor a 1.3MB(MegaBytes)",
          maxSize:"1.3",
          comments:"Debe de cargar un recibo que haya sido emitido en los ultimos 3 meses",
          status:"Con observación",
          loadDate:"03-07-2022 04:33:00",
          validationDate:""
        },
        {          
          name:"Certificado de Secundaria",
          fileName:"certificadoSec.pdf",
          uploadFileName:"",
          extensionOptions:["pdf"],
          description:"Archivo Formato .PDF menor a 1.8MB(MegaBytes)",
          maxSize:"1.8",
          comments:"",
          status:"No disponible",
          loadDate:"03-07-2022 04:33:00",
          validationDate:""
        },
        {          
          name:"Recibo",
          fileName:"recibo.pdf",
          uploadFileName:"",
          extensionOptions:["jpg","pdf"],
          description:"Archivo Formato .JPG o .PDF menor a 1.8MB(MegaBytes)",
          maxSize:"1.8",
          comments:"",
          status:"No disponible",
          loadDate:"03-07-2022 04:33:00",
          validationDate:""
        },
        {          
          name:"Gafete de Colaborador",
          fileName:"gafete.pdf",
          uploadFileName:"",
          extensionOptions:["jpg","pdf"],
          description:"Archivo Formato .JPG o .PDF menor a 1.8MB(MegaBytes)",
          maxSize:"1.8",
          comments:"",
          status:"No disponible",
          loadDate:"03-07-2022 04:33:00",
          validationDate:""
        },
      ];


      this.listaDocumentos=arr;

    }

  },
  props: {
    msg: String
  },
  computed:{  
      ...mapStores(useUserStore)
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
