<template>
  <div class="fullWidth" style="min-height:70vh;">
    
    <div class="col80 center tCenter">
      <div class="block fullWidth tCenter">
        <img class="center" src=".././../public/img/usuario.png" style="width:18%;margin-left:42%;">
      </div>
    </div>
    
    <div class="col80 center tCenter" style="" >
      <h3 
      @click="handleClicks()"       
      >
        Inicio de sesión de alumnos
      </h3>
      
      <!--
        <i class="bi bi-android2"></i>
      -->
    </div>
    
    <div class="col80 center tCenter" style="">

      <div v-if="!showPasswordInput">
        <div class="formRow col50">
        <!--
          <label>Por favor introduzca su CURP</label>
          -->
          <input class="input" type="text" placeholder="Introduce tu CURP o nombre de usuario" v-model="curp" @keyup.enter="sendcurp()" v-if="userStore.showLogin">          
          <input class="input" type="text" placeholder="Introduce tu CURP o nombre de usuario" style="background:gray" disabled v-else>          
        </div>
        <div class="formRow col50" v-if="userStore.showLogin">
          <button class="btnBlue btnWide fullWidth" @click="sendcurp()" v-if="curp.length!=0">
            Continuar
          </button>
          <button class="btnDefault btnWide fullWidth" @click="setMessageCURP()" v-else>
            Continuar
          </button>
        </div>
        <div class="formRow col50" v-if="!userStore.showLogin">
          
          <button class="btnDefault btnWide fullWidth" @click="setMessageAccesoNoDisponible()">
            Continuar
          </button>
        </div>
      </div>
      <div v-else>
        <div class="formRow col50">
          <label class="form__iconlabel" style="color:#025BAD;">
            <i class="bi bi-person-fill"></i>{{userStore.userName}}            
          </label>
          
        </div>
        <div class="formRow col50">
          <input class="input" type="password" placeholder="Contraseña" v-model="password" @keyup.enter="sendPassword()" v-if="!showPassword">
          <input class="input" type="text" placeholder="Contraseña" v-model="password" @keyup.enter="sendPassword()" v-else>
          <small style="color:blue;cursor:pointer;" v-on:click="showPassword=!showPassword;" v-if="!showPassword">
            
            <i class="bi bi-eyeglasses"></i>
            Mostrar Contraseña
          </small>
          <small style="color:blue;cursor:pointer;" v-on:click="showPassword=!showPassword;" v-else>
            <i class="bi bi-incognito" ></i>
            Ocultar Contraseña
          </small>


        </div>
        <div class="formRow col50">
          <button class="btnBlue btnWide fullWidth" @click="sendPassword();">
            Iniciar Sesión
          </button>
        </div>
        
      </div>


      <div class="formRow dividerBlack col50" style="min-height:10px;min-width:50%;">
        <span class="" style="min-height:10px;min-width:50%;"></span>
      </div>
      <div class="formRow col50" v-if="userStore.showRegistro">

          <button class="btnOrange btnWide fullWidth" @click="toRegistro()">
            Registrar cuenta
          </button>
        </div>
      <div class="formRow col50">
        <label style="color:var(--blueDark);">{{verMsg}}</label>
      </div>
    </div>

    <modal title="Inicio de Sesión" v-if="showModal" v-on:closeModalEv="showModal=false;" style="display:block;left:10vw;top:10vh;height:30vh;">              
      <div style="text-align:center;">
        <h3>
          {{modalMessage}}
        </h3>
        <div v.if="showCuentas">
          
          <div class="btnBlue btnWide" v-for="cuenta in listaRegistros" :key="cuenta" @click="loadData(cuenta);">
            <div>
              <label>
                Plantel:
                <span style="color:orange;">
                  {{cuenta.c19}} 
                </span>
              </label>
              <label>
                Modalidad:
                <strong style="color:white;">
                  {{cuenta.c17}} 
                </strong>
              </label>
            </div>
            <div>
              <strong>
                Folio:{{cuenta.c18}}&nbsp;
              </strong>
              <label>              
                <small>
                  {{cuenta.c16}}
                </small>
              </label>
            </div>
          </div>

        </div>


      </div>
      
    </modal>
    
    
  </div>
</template>

<script>
  import { mapStores } from 'pinia';
  import { useUserStore } from '../store/user';
  import Modal from './Modal/Modal.vue';
  //import axios from 'axios';

export default {
  name: 'Login',
  components:{Modal},
  props: {
    msg: String
  },
  data:function(){
    return{
      verMsg:'Versión Actual',
      curp:'',
      clicks:0,
      showPasswordInput:'',
      showPassword:false,
      password:'',
      showModal:false,
      showCuentas:false,
      modalMessage:'',
      id:'',
      listaRegistros:[]
      

    }
  },
  watch:{

    curp:function(){
      this.curp=this.curp.toUpperCase();
    }

  },
  mounted: function(){
    this.userStore.login=0;
    this.userStore.registroView=false;
    this.userStore.iddisp='-';
    this.getCurrentVersion();
  },
  methods:{

    handleClicks(){
      this.clicks++;
      if(this.clicks==3){
        this.userStore.showLogin=!this.userStore.showLogin;
        this.clicks=0;
      }

    },

    getCurrentVersion(){

      
      //let request_data=this.$root.getData(this.formData);  
      /*
      ap1:''+this.formData['ap1'].data,
      ap2:''+this.formData['ap2'].data,
      nombre:''+this.formData['nombre'].data,        
      carrera:'prueba',
      plantel:''+this.formData['plantel'].data,
      */
      let data={};
      //request_data['password']=this.password;
      //console.log("request_data:");
      //console.log(request_data);

      console.log("data:");
      console.log(data);

      //this.$root.sendRequest("http://192.168.1.74:8086/api/login",request_data,this.onSuccessLogin,this.onErrorLogin);
      this.$root.sendPOSTRequest("https://webdevworks.xyz/scLC/api/v1/verificarVersionPortalAlumnos/",
      data,
      this.onSuccessVersion,
      this.onErrorVersion);

    },
    onSuccessVersion(data){

      console.log(data);

      /*
      console.log(data.exists);
      console.log(typeof data.exists);
      console.log(data.insert);
      console.log(typeof data.insert);
      */      
      //alert("Obteniendo Versión");
      this.userStore.showLogin=(data.acceso=='1');
      this.userStore.showRegistro=(data.registro=='1');
      this.setVersionState(data.version);

    },
    onErrorVersion(error){
      console.log(error);
      //alert("Ocurrio un error: "+error);

    },

    setVersionState(currentVersion){
      console.log('currentVersion:');
      console.log(currentVersion);
      let version=currentVersion;

        //localStorage.clear();
        if(localStorage.estado){
            let estadoLoaded=JSON.parse(localStorage.estado);
            //this.version=estadoLoaded.version;
            //this.versionActual=version;
            //alert("Version actual: "+estadoLoaded.version);
            console.log("Estado:");
            console.log(localStorage.estado);
            console.log("estadoLoaded.version="+estadoLoaded.version);
            console.log("currentVersion="+currentVersion);

            if(estadoLoaded.version!=version){
              let estado={
                
                version:""+version,
                actualizado:true
                
              };
              
              localStorage.estado=JSON.stringify(estado);
              //this.userData.version=currentVersion;
              //alert("Estado Actualizado");
              this.verMsg="Versión Actualizada";
              console.log("Versión Actualizada");
              
              console.log(localStorage.estado);
              location.reload(true);
            }
            this.currentVersion=currentVersion;

        }else{
          let estado={            
            version:""+currentVersion,
            actualizado:true
          };
          //this.userData.version=currentVersion;
          localStorage.estado=JSON.stringify(estado);
          //alert("Estado Guardado");
          this.verMsg="Versión guardada";
          console.log(localStorage.estado);
          this.currentVersion=currentVersion;
        }

    },
    
    setData(data){
      if(data.c0==1){

        this.$root.changeRoute("/home");
        this.userStore.userID=data.c1;
        this.userStore.status=data.c2;
        this.userStore.nombre=data.c3;
        this.userStore.ap1=data.c4;
        this.userStore.ap2=data.c5;
        this.userStore.userName=data.c6;
        this.userStore.correoVerificado=data.c7;
        this.userStore.datosStatus=data.c8;
        this.userStore.documentosStatus=data.c9;
        this.userStore.fichaRegistro=data.c10;
        this.userStore.comprobanteInscripcionDescargado=data.c11;
        this.userStore.registroCompleto=data.c12;
        this.userStore.sistema=data.c13;
        this.userStore.carrera=data.c14;
        this.userStore.numeroControl=data.c15;
        this.userStore.plantel=data.c16;

      }
      
    },
    setMessageAccesoNoDisponible(){
      this.modalMessage="El acceso al portal no esta disponible por el momento, intenta más tarde";
      this.showModal=1;
    },

    setMessageCURP(){
      this.modalMessage="Para iniciar sesión, por favor introduzca su CURP.";
      this.showModal=1;
    },

    sendcurpToVerification(){
      console.log("sendcurpToVerification()");
      let data={
        usName:''+this.curp,
        email:''+this.curp,
        curp:''+this.curp
      };

      this.$root.sendPOSTRequest(
        "https://webdevworks.xyz/scLC/api/v1/verificarCURP/",
        data,
        this.onSuccessSendcurp,
        this.onErrorSendcurp
        );
    },
    onSuccessSendcurp(data){
      console.log("Data success curp:");
      console.log(data);
      console.log(typeof data);
      if(data.na>=5){
        this.showModal=true;
        this.modalMessage="Error: Ha agotado los intentos validos para acceder a su cuenta. Por favor contacte al personal administrativo para desbloquear el acceso a su cuenta.";
        return;
      }

      if(data.data.c4=='1'){
        this.id=data.data.c2;
        this.showPasswordInput=true;
        this.userStore.userName=data.data.c3;
      }else{

        this.showModal=true;
        this.modalMessage="Error, su CURP es incorrecta, no esta registrado o el usuario no tiene permitido el acceso.";
      }
      
    },
    onErrorSendcurp(data){
      console.log(data);
    },

    sendcurp(){

      this.sendcurpToVerification();

    },

    loadData(data){
      if(data.c0=='1'){
        this.$root.importData(data);
        this.userStore.login=1;        
      }
    },

    sendPassword(){
      console.log("sendPassword()");
      let data={
        pk:''+this.id,
        contra:''+this.password
        
      };

      this.$root.sendPOSTRequest(
        "https://webdevworks.xyz/scLC/api/v1/verificarContraseniaAspirante/",
        data,
        this.onSuccessSendPassword,
        this.onErrorSendPassword
        );
    },
    onSuccessSendPassword(data){

      console.log("onSuccessSendPassword");
      console.log("Data success:");
      console.log(data);         
      //console.log(typeof data.data.c0);         
      console.log(data.data);         
      console.log(data.data.data);   
      
      
      if(data.na>=5){
        this.showModal=true;
        this.modalMessage="Error: Ha agotado los intentos validos para acceder a su cuenta. Por favor contacte al personal administrativo para desbloquear el acceso a su cuenta.";
      }else{

        this.listaRegistros=data.data.data;
        if(data.data.data.length>0){
          this.showModal=true;
          this.showCuentas=true;
          this.modalMessage="Selecciona a que modalidad quieres acceder:";

        }else{
          if(data.data.data[0].c0=='1'){
            this.$root.importData(data.data.data);
          }
        }
        
      }
      
      
    },
    onErrorSendPassword(data){
      console.log("onErrorSendPassword");
      console.log(data);
    },



    toRegistro(){
      this.$root.changeRoute('/registro');      
    },

    cambiarNombre(newName){
      this.userStore.userName=newName;
    }

  },
  computed:{  
    //Para usar states con Pinia se debe definir un store y dentro un state
    //para usar pinia se debe de importar el store deseado y la funcion mapStores 
    //import { mapStores } from 'pinia'
    //import { useUserStore } from '../store/user';
    //
    //El objeto state es un objeto global que puede ser utilizado por muchos componentes
    //para compartir información entre ellos
    //para accesar al state dentro del store definido este sera accedido mediante el apuntador this.
    //tal como la siguiente sintaxis:
    //this.nombreDelStoreMasLaPalabraStoreAlFinalDeEste
    //Ejemplo se creo un store llamado user.js
    //dentro de este se definio una variable llamada useUserStore, esta contiene el Store llamado 'user'
    //Para accesar a este se llamara a la variable this.userStore para accesar a las propiedades guardadas 
    //en el state se usara como cualquier objeto
    //ejemplo: this.userStore.username   
    //this.userStore.userName=newName;
    //{{userStore.userName}} 
    //Para hacer uso de más stores, solo hace falta agregarlo a la lista dentro de la funcion
    //mapStores()
    //por ejemplo:...mapStores(useUserStore,useCartStore)
      ...mapStores(useUserStore)
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
