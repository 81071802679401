<template>
  <div class="registro" style="min-height:80vh;">

    <!--
    <div class="subtitleBlueDark fullWidth">
      <label v-on:dblclick="testRegistro()">
      Registro de Datos del Aspirante
      </label>
    </div>
    -->
    <div class="form__panel fullWidth" >

      <div class="col95 center">
        <div class="col22 colSm45 row rowm btnBlue tCenter" @click="showPersonal=!showPersonal;" style="margin:1px;">
          <small>
            Información <br>Personal
          </small>
        </div>
        <div class="col22 colSm45 row rowm btnBlue tCenter" @click="showContacto=!showContacto;" style="margin:1px;">
          <small>
            Información <br>de Contacto
          </small>
        </div>

        <div class="col22 colSm45 row rowm btnBlue tCenter" @click="showAntecedente=!showAntecedente;" style="margin:1px;">
          <small>
            Información <br>Antecedente Escolar
          </small>
        </div>

        <div class="col22 colSm45 row rowm btnBlue tCenter" @click="showRelacionChedraui=!showRelacionChedraui;" style="margin:1px;">
          <small>
            Información Relación<br> Grupo Chedraui
          </small>
        </div>

      </div>

      <div class="col95" v-if="showPersonal">
        <div class="blockRow subtitleBlueDark separator">
          Información Personal 
          <small style="color:black;">
          (1 de 4)
          </small>
        </div>

        <div class="formCol col48 colSm95">
          <div class="formRow colSm95">
              
            <label class="formLabel" style="color:#025BAD;">        
              <small>
                Nombre:
              </small>
            </label>
            <span style="min-width:5%;width:5%;"></span>          
            
            <InputText ref="nombreRef" :propName="'nombre'"  :whiteBackground="false" :mode="'A'" 
            :placeholder="'Nombre'" :capital="true" :maxLength="120" :minLength="2"></InputText>
                          
          </div>
        </div>      
        
        <div class="formCol col48 colSm95">
          <div class="formRow colSm95">
              
            <label class="formLabel" style="color:#025BAD;">        
              <small>
                Primer Apellido:
              </small>
            </label>

            <span style="min-width:5%;width:5%;"></span>          
            
            <input-text ref="ap1Ref" :propName="'ap1'"  :whiteBackground="false" :mode="'A'" 
            :placeholder="'Primer Apellido'" :capital="true" :maxLength="120" :minLength="2"></input-text>
                          
          </div>
        </div>


        <div class="formCol col48 colSm95">
          <div class="formRow colSm95">
              
            <label class="formLabel" style="color:#025BAD;">        
              <small>
                Segundo Apellido:
              </small>
            </label>
            <span style="min-width:5%;width:5%;"></span>          
            
            <input-text ref="ap2Ref" :propName="'ap2'"  :whiteBackground="false" :mode="'A'" 
            :placeholder="'Segundo Apellido'" :capital="true" :maxLength="120" :minLength="2"></input-text>
                          
          </div>
        </div>
        
        
        <div class="formCol col48 colSm95">
          <div class="formRow colSm95">
              
            <label class="formLabel" style="color:#025BAD;">        
              <small>
                CURP:
              </small>
            </label>
            <span style="min-width:5%;width:5%;"></span>          
            
            <input-text ref="curpRef" :propName="'curp'"  :whiteBackground="false" :mode="'CURP'" 
            :placeholder="'CURP'" :capital="true" :maxLength="18" :minLength="18"></input-text>
                          
          </div>
        </div>      


        <div class="formCol col48 colSm95">
          <div class="formRow colSm95" >          
            <label class="formLabel" style="color:#025BAD;">        
              <small>
                Sexo:{{formData.sexo.data}}
              </small>
            </label>
            <span style="min-width:5%;width:5%;"></span>          
            <input-select style="color:black;" ref="sexoRef" :optionName="'sexo'" :whiteBackground="true" 
            :optionsArray="listaSexo" :propName="'sexo'" 
            :valFieldName="'c2'" 
            :extraPropName="'c2'"
            >
            </input-select>
          </div>
        </div>


        <div class="formCol col48 colSm95">
          <div class="formRow colSm95" >            
            <label class="formLabel" style="color:#025BAD;">        
              <small>
                Fecha de Nacimiento:
              </small>
            </label>
            <span style="min-width:5%;width:5%;"></span>            
            <input-date style="color:black;" ref="fechaNacRef"
            :propName="'fechaNac'"
            ></input-date>                                
          </div>
        </div>

        <div class="formCol col48 colSm95">
          <div class="formRow colSm95" >          
            <label class="formLabel" style="color:#025BAD;">        
              <small>
                País de Nacimiento:
              </small>
            </label>
            <span style="min-width:5%;width:5%;"></span>          
            <input-select style="color:black;" ref="paisNacRef" :optionName="'páis de nacimiento'" :whiteBackground="true" 
            :optionsArray="listaPaises" :propName="'paisNac'"
            :displayFieldname="'c0'" :valFieldName="'c0'"
            ></input-select>                              
          </div>
        </div>

        <div class="formCol col48 colSm95">
          <div class="formRow colSm95" >          
            <label class="formLabel" style="color:#025BAD;">        
              <small>
                Estado de Nacimiento:
              </small>
            </label>
            <span style="min-width:5%;width:5%;"></span>          
            <input-select style="color:black;" ref="estadoNacRef" :optionName="'estado de nacimiento'" :whiteBackground="true" 
            :optionsArray="listaEstados" :propName="'estadoNac'" :extraPropName="'estadoNac'"
            ></input-select>                              
          </div>
        </div>

        <div class="formCol col48 colSm95">
          <div class="formRow colSm95" >          
            <label class="formLabel" style="color:#025BAD;">        
              <small>
                Municipio de Nacimiento:
              </small>
            </label>
            <span style="min-width:5%;width:5%;"></span>          
            <input-select style="color:black;" ref="municipioNacRef" :optionName="'municipio de nacimiento'" :whiteBackground="true" 
            :optionsArray="listaMunicipiosNac" :propName="'munNac'" 
            :displayFieldname="'c0'" :valFieldName="'c0'"
            ></input-select>                              
          </div>
        </div>   

        <div v-if="isInformacionPersonalCompleta()" style="margin-top:10px;">          
          <div class="fullWidth row rowm center colSm50 btnBlue tCenter" @click="showPersonal=false;showContacto=true;">
            Continuar registro <br>de Información de Contacto
          </div>
        </div>

      </div>

      <div class="col95" v-if="showContacto">
        <div class="blockRow subtitleBlueDark separator">
          Información de Contacto 
          <small style="color:black;">
          (2 de 4)
          </small>
        </div>

        <div class="formCol col48 colSm95">
          <div class="formRow colSm95">            
            <label class="formLabel" style="color:#025BAD;">        
              <small>
                Teléfono:
              </small>
            </label>
            <span style="min-width:5%;width:5%;"></span>                    
            <input-number ref="telefonoRef" :propName="'telefono'"  :whiteBackground="false"
            :placeholder="'Teléfono'" :capital="true" 
            :minLength="10"
            :maxLength="10"
            :mode="'L'"
            ></input-number>                        
          </div>
        </div>

        <div class="formCol col48 colSm95">
          <div class="formRow colSm95">            
            <label class="formLabel" style="color:#025BAD;">        
              <small>
                Teléfono de Emergencia:
              </small>
            </label>
            <span style="min-width:5%;width:5%;"></span>                    
            <input-number ref="telefonoEmergenciaRef" :propName="'telefonoEmergencia'"  :whiteBackground="false"
            :placeholder="'Teléfono de Emergencia'" :capital="true" 
            :minLength="10"
            :maxLength="10"
            :mode="'L'"
            ></input-number>                        
          </div>
        </div>

        <div class="formCol col48 colSm95">
          <div class="formRow colSm95">
              
            <label class="formLabel" style="color:#025BAD;">        
              <small>
                Domicilio:
              </small>
            </label>
            <span style="min-width:5%;width:5%;"></span>          
            
            <InputText ref="domicilioRef" :propName="'domicilio'"  :whiteBackground="false" :mode="'AN'" 
            :placeholder="'Domicilio'" :capital="true" :maxLength="120" :minLength="2"></InputText>
                          
          </div>
        </div>

        <div class="formCol col48 colSm95">
          <div class="formRow colSm95">            
            <label class="formLabel" style="color:#025BAD;">        
              <small>
                Código Postal:
              </small>
            </label>
            <span style="min-width:5%;width:5%;"></span>                    
            <input-number ref="codigoPostalRef" :propName="'codigoPostal'"  :whiteBackground="false"
            :placeholder="'Código Postal'" :capital="true" 
            :minLength="5"
            :maxLength="5"
            :mode="'L'"
            ></input-number>                        
          </div>
        </div>

        <div class="formCol col48 colSm95">
          <div class="formRow colSm95" >          
            <label class="formLabel" style="color:#025BAD;">        
              <small>
                Estado de Residencia:
              </small>
            </label>
            <span style="min-width:5%;width:5%;"></span>          
            <input-select style="color:black;" ref="estadoResRef" :optionName="'estado de residencia'" :whiteBackground="true" 
            :optionsArray="listaEstados" :propName="'estadoRes'" :extraPropName="'estadoRes'" 
            ></input-select>                              
          </div>
        </div>

        <div class="formCol col48 colSm95">
          <div class="formRow colSm95" >          
            <label class="formLabel" style="color:#025BAD;">        
              <small>
                Municipio de Residencia:
              </small>
            </label>
            <span style="min-width:5%;width:5%;"></span>          
            <input-select style="color:black;" ref="munResRef" 
            :optionName="'municipio de residencia'" :whiteBackground="true" 
            :optionsArray="listaMunicipiosRes" :propName="'munRes'" 
            :displayFieldname="'c0'" :valFieldName="'c0'"
            ></input-select>                              
          </div>
        </div>

        <div v-if="isInformacionContactoCompleta()" style="margin-top:10px;">
          <div class="col10 row rowm center colSm10 btnBlue tCenter" 
          @click="showPersonal=true;showContacto=false;" style="vertical-align:top;">
            <i class="bi bi-arrow-left-square"></i>
            <br>
            Atrás            
          </div>
          <div class="col90 row rowm center colSm50 btnBlue tCenter" @click="showContacto=false;showAntecedente=true;" style="vertical-align:top;">
            Continuar registro Información <br>Antecedentes Escolares
          </div>
        </div>

      </div>

      <div class="col95" v-if="showAntecedente">
        <div class="blockRow subtitleBlueDark separator">
          Información de Antecedente Escolar 
          <small style="color:black;">
          (3 de 4)
          </small>
        </div>

        <div class="formCol col48 colSm95">
          <div class="formRow colSm95" >          
            <label class="formLabel" style="color:#025BAD;">        
              <small>
                Estado Secundaria de Egreso:
              </small>
            </label>
            <span style="min-width:5%;width:5%;"></span>          
            <input-select style="color:black;" 
            ref="estadoSecRef" 
            :optionName="'Estado Secundaria de Egreso'" 
            :whiteBackground="true" 
            :optionsArray="listaEstados" :propName="'estadoSec'"
            :extraPropName="'estadoSec'"
            ></input-select>                              
          </div>
        </div>
        


        <div class="formCol col48 colSm95">
          <div class="formRow colSm95" >          
            <label class="formLabel" style="color:#025BAD;">        
              <small>
                Municipio Secundaria de Egreso:
              </small>
            </label>
            <span style="min-width:5%;width:5%;"></span>          
            <input-select style="color:black;" 
            ref="munSecRef" 
            :optionName="'Municipio de secundaria de egreso'" 
            :whiteBackground="true" 
            :optionsArray="listaMunicipiosSec" :propName="'munSec'" 
            :displayFieldname="'c0'" :valFieldName="'c0'" 
            :extraPropName="'munSec'"
            ></input-select>                              
          </div>
        </div>


        
        <div class="formCol col48 colSm95">
          <div class="formRow colSm95" >          
            <label class="formLabel" style="color:#025BAD;">        
              <small>
                Nombre de la Secundaria de Egreso:
              </small>
            </label>
            <span style="min-width:5%;width:5%;"></span>          
            <input-select style="color:black;" 
            v-if="!showNuevaSec" 
            ref="secundariaRef" 
            :optionName="'secundaria de egreso'" 
            :whiteBackground="true" 
            :optionsArray="listaSecundarias" :propName="'secundaria'" 
            :displayFieldname="'c0'" :valFieldName="'c0'"
            ></input-select>
            
            <InputText ref="secundariaRef" 
            v-else
            :propName="'secundaria'"  
            :whiteBackground="false" 
            :mode="'AN'" 
            :placeholder="'Nombre de la secundaria de egreso'" 
            :capital="true" 
            :maxLength="120" 
            :minLength="2">
            </InputText> 
            <small style="color:blue;display:block;margin:0 auto;text-align:center;" @click="showNuevaSec=!showNuevaSec;">Mi escuela secundaria no está en la lista</small>

          </div>
        </div>      


        <div class="formCol col48 colSm95">
          <div class="formRow colSm95">
            <label class="formLabel" style="color:#025BAD;">        
              <small>
                Folio de Certificado de Secundaria:
              </small>
            </label>
            <span style="min-width:5%;width:5%;"></span>                    
            <InputText ref="folioCertificadoSecRef" 
            :propName="'folioCertificadoSec'"  
            :whiteBackground="false" 
            :mode="'AN'" 
            :placeholder="'Folio de Certificado de Secundaria'" 
            :capital="true" 
            :maxLength="120" 
            :minLength="2"></InputText> 
          </div>
        </div>

        <div style="min-height:10px;">
          <span></span>
        </div>

        <div class="formCol col48 colSm95">
          <div class="formRow colSm95" >          
            <label class="formLabel" style="color:#025BAD;">        
              <small>
                Ha estado en otro bachillerato antes:{{formData.bachilleratoPrevio.data}}
              </small>
            </label>
            <span style="min-width:5%;width:5%;"></span>          
            <input-select style="color:black;" 
            ref="bachilleratoPrevioRef" 
            :optionName="'opción'" 
            :whiteBackground="true" 
            :optionsArray="listaOpcionesSiNo" 
            :propName="'bachilleratoPrevio'" 
            :valFieldName="'c2'" 
            :extraPropName="'c2'"
            ></input-select>                              
          </div>
        </div>  

        <div class="formCol col48 colSm95" v-if="formData.bachilleratoPrevio.data==1">
          <div class="formRow colSm95" >          
            <label class="formLabel" style="color:#025BAD;">        
              <small>
                Estado Bachillerato Anterior:
              </small>
            </label>
            <span style="min-width:5%;width:5%;"></span>          
            <input-select style="color:black;" 
            ref="estadoBacAntRef" 
            :optionName="'Estado Bachillerato Anterior'" 
            :whiteBackground="true" 
            :optionsArray="listaEstados" 
            :propName="'estadoBacAnt'"
            :extraPropName="'estadoBacAnt'"
            ></input-select>                              
            
          </div>
        </div>


        <div class="formCol col48 colSm95" v-if="formData.bachilleratoPrevio.data==1">
          <div class="formRow colSm95" >          
            <label class="formLabel" style="color:#025BAD;">        
              <small>
                Municipio Bachillerato Anterior:
              </small>
            </label>
            <span style="min-width:5%;width:5%;"></span>          
            <input-select-list style="color:black;" 
            ref="munBacAntRef" 
            :optionName="'Municipio bachillerato anterior'" 
            :whiteBackground="true" 
            :optionsArray="listaMunicipiosBacAnt" 
            :propName="'munBacAnt'"
            :displayFieldname="'c0'" :valFieldName="'c0'"
            :extraPropName="'munBacAnt'" 
            :mode="'A'" 
            :placeholder="'Nombre municipio bachillerato anterior'" 
            :capital="true" 
            :maxLength="120" 
            :minLength="2"
            :allowNew="true"
            ></input-select-list>                              
          </div>
        </div>


        <div class="formCol col48 colSm95" v-if="formData.bachilleratoPrevio.data==1">
          <div class="formRow colSm95" >          
            <label class="formLabel" style="color:#025BAD;">        
              <small>
                Nombre del Bachillerato Anterior:
              </small>
            </label>
            <span style="min-width:5%;width:5%;"></span>          
            <input-select-list style="color:black;" 
            ref="bachilleratoAnteriorRef" 
            :optionName="'Bachillerato anterior'" 
            :whiteBackground="true" 
            :optionsArray="listaBachilleratos" 
            :propName="'bachilleratoAnterior'"
            :displayFieldname="'c0'" :valFieldName="'c0'"
            :extraPropName="'bachilleratoAnterior'" 
            :mode="'AN'" 
            :placeholder="'Nombre del bachillerato anterior'" 
            :capital="true" 
            :maxLength="120" 
            :minLength="2"
            :allowNew="true"
            ></input-select-list>                              
          </div>
        </div>          


        <div class="formCol col48 colSm95" v-if="formData.bachilleratoPrevio.data==1">
          <div class="formRow colSm95" >          
            <label class="formLabel" style="color:#025BAD;">        
              <small>
                Situación Escolar:
              </small>
            </label>
            <span style="min-width:5%;width:5%;"></span>          
            <input-select style="color:black;" 
            ref="situacionEscolarRef" 
            :optionName="'situacion escolar'"           
            :optionsArray="listaSituacionEscolar" 
            :propName="'situacionEscolar'"
            ></input-select>                              
          </div>
        </div>


        <div v-if="isInformacionAntecedenteEscolarCompleta()" style="margin-top:10px;">

          <div class="col10 row rowm center colSm10 btnBlue tCenter" 
          @click="showContacto=true;showAntecedente=false;" style="vertical-align:top;">
            <i class="bi bi-arrow-left-square"></i>
            <br>
            Atrás            
          </div>

          <div class="col90 row rowm center colSm50 btnBlue tCenter" @click="showAntecedente=false;showRelacionChedraui=true;" style="vertical-align:top;">
            Continuar registro Información <br>Relación con Grupo Chedraui
          </div>
        </div>

      </div>
      

      <div class="col95" v-if="showRelacionChedraui">
        <div class="blockRow subtitleBlueDark separator">
          Información de Relación con Grupo Chedraui 
          <small style="color:black;">
          (4 de 4)
          </small>
        </div>


        <div class="formCol col48 colSm95">
          <div class="formRow colSm95" >          
            <label class="formLabel" style="color:#025BAD;">        
              <small>
                Relación con grupo Chedraui: {{relacionChedraui}}
              </small>
            </label>
            <span style="min-width:5%;width:5%;"></span>          
            <input-select style="color:black;" ref="relacionChedrauiRef" 
            :optionName="'relación con Grupo Chedraui'" 
            :whiteBackground="true" 
            :optionsArray="listaRelacionChedraui" :propName="'relacionChedraui'" 
            :extraPropName="'relacionChedraui'"
            ></input-select>                              
          </div>
        </div>


        <div class="formCol col48 colSm95" v-if="formData.relacionChedraui.data!='Externo sin relación con Grupo Chedraui'">
          <div class="formRow colSm95">
            <label class="formLabel" style="color:#025BAD;">        
              <small>
                CURP de Colaborador: {{formData.noColaborador.data}}
              </small>
            </label>
            <span style="min-width:5%;width:5%;"></span>  

            <input-text ref="noColaboradorRef" :propName="'noColaborador'"  :whiteBackground="false" :mode="'CURP'" 
            :placeholder="'CURP Colaborador'" :capital="true" :maxLength="18" :minLength="18"></input-text>                  
            <!--
            <InputText ref="noColaboradorRef" 
            :propName="'noColaborador'" 
            :whiteBackground="false" :mode="'AN'" 
            :placeholder="'Número de Colaborador'" 
            :capital="true" 
            :maxLength="20" 
            :minLength="20"></InputText> 
            -->
          </div>
        </div>


        <div class="formCol col48 colSm95" v-if="formData.relacionChedraui.data=='Familiar'">
          <div class="formRow colSm95">
            <label class="formLabel" style="color:#025BAD;">        
              <small>
                Nombre del Familiar:
              </small>
            </label>
            <span style="min-width:5%;width:5%;"></span>                    
            <InputText ref="nombreFamiliarRef" 
            :propName="'nombreFamiliar'" 
            :whiteBackground="false" :mode="'A'" 
            :placeholder="'Nombre del Familiar'" 
            :capital="true" 
            :maxLength="45" 
            :minLength="2"></InputText> 
          </div>
        </div>


        <div class="formCol col48 colSm95" v-if="formData.relacionChedraui.data=='Familiar'">
          <div class="formRow colSm95">
            <label class="formLabel" style="color:#025BAD;">        
              <small>
                Primer Apellido del Familiar:
              </small>
            </label>
            <span style="min-width:5%;width:5%;"></span>                    
            <InputText ref="ap1FamiliarRef" 
            :propName="'ap1Familiar'" 
            :whiteBackground="false" :mode="'A'" 
            :placeholder="'Primer Apellido del Familiar'" 
            :capital="true" 
            :maxLength="45" 
            :minLength="2"></InputText> 
          </div>
        </div>


        <div class="formCol col48 colSm95" v-if="formData.relacionChedraui.data=='Familiar'">
          <div class="formRow colSm95">
            <label class="formLabel" style="color:#025BAD;">        
              <small>
                Segundo Apellido del Familiar:
              </small>
            </label>
            <span style="min-width:5%;width:5%;"></span>                    
            <InputText ref="ap2FamiliarRef" 
            :propName="'ap2Familiar'" 
            :whiteBackground="false" :mode="'A'" 
            :placeholder="'Segundo Apellido del Familiar'" 
            :capital="true" 
            :maxLength="45" 
            :minLength="2"></InputText> 
          </div>
        </div>


        <div class="formCol col48 colSm95" v-if="formData.relacionChedraui.data!='Externo sin relación con Grupo Chedraui'">
          <div class="formRow colSm95" >          
            <label class="formLabel" style="color:#025BAD;">        
              <small>
                Estado de la Sucursal:
              </small>
            </label>
            <span style="min-width:5%;width:5%;"></span>          
            <input-select-list style="color:black;" 
            ref="estadoSucursalRef" 
            :optionName="'Estado de la Sucursal'" 
            :whiteBackground="true" 
            :optionsArray="listaEstados" 
            :propName="'estadoSucursal'"            
            :extraPropName="'estadoSucursal'"            
            :displayFieldname="'c1'" :valFieldName="'c1'"            
            :mode="'A'" 
            :placeholder="''" 
            :capital="true" 
            :maxLength="120" 
            :minLength="2"
            :allowNew="false"
            ></input-select-list>                              
          </div>
        </div>
        


        <div class="formCol col48 colSm95" v-if="formData.relacionChedraui.data!='Externo sin relación con Grupo Chedraui'">
          <div class="formRow colSm95" >          
            <label class="formLabel" style="color:#025BAD;">        
              <small>
                Municipio de la Sucursal:
              </small>
            </label>
            <span style="min-width:5%;width:5%;"></span>          
            <input-select-list style="color:black;" 
            ref="municipioSucursalRef" 
            :optionName="'Municipio de la sucursal'" 
            :whiteBackground="true" 
            :optionsArray="listaMunicipiosSucursal" 
            :propName="'municipioSucursal'"
            :extraPropName="'municipioSucursal'" 
            :displayFieldname="'c0'" :valFieldName="'c0'"
            :mode="'A'" 
            :placeholder="'Nombre municipio de la sucursal'" 
            :capital="true" 
            :maxLength="120" 
            :minLength="2"
            :allowNew="true"
            ></input-select-list>                              
          </div>
        </div>


        <div class="formCol col48 colSm95" v-if="formData.relacionChedraui.data!='Externo sin relación con Grupo Chedraui'">
          <div class="formRow colSm95" >          
            <label class="formLabel" style="color:#025BAD;">        
              <small>
                Sucursal:
              </small>
            </label>
            <span style="min-width:5%;width:5%;"></span>          
            <input-select-list style="color:black;" 
            ref="sucursalRef" 
            :optionName="'sucursal'" 
            :whiteBackground="true" 
            :optionsArray="listaSucursal" 
            :propName="'sucursal'"            
            :displayFieldname="'c0'" 
            :valFieldName="'c0'"
            :mode="'AN'" 
            :placeholder="'Sucursal'" 
            :capital="true" 
            :maxLength="120" 
            :minLength="2"
            :allowNew="true"
            ></input-select-list>                              
          </div>
        </div>

        

        <div class="formCol col48 colSm95" v-if="formData.relacionChedraui.data!='Externo sin relación con Grupo Chedraui'">
          <div class="formRow colSm95" >          
            <label class="formLabel" style="color:#025BAD;">        
              <small>
                Puesto:
              </small>
            </label>
            <span style="min-width:5%;width:5%;"></span>          
            <input-select-list style="color:black;" 
            ref="puestoRef" 
            :optionName="'puesto'" 
            :whiteBackground="true" 
            :optionsArray="listaPuestos" 
            :propName="'puesto'"            
            :extraPropName="'puesto'"            
            :displayFieldname="'c0'" :valFieldName="'c0'"
            :mode="'A'" 
            :placeholder="''" 
            :capital="true" 
            :maxLength="120" 
            :minLength="2"
            :allowNew="true"
            ></input-select-list>                              
          </div>
        </div>

        
        

        <div style="margin-top:20px;" v-if="isInformacionRelacionGrupoChedrauiCompleta()">          
            
          <button class="col90 row rowm center colSm50 btnBlue tCenter" v-on:click="sendRegistrar()"
          v-if="(
              isInformacionPersonalCompleta()&&
              isInformacionContactoCompleta()&&
              isInformacionAntecedenteEscolarCompleta()&&
              isInformacionRelacionGrupoChedrauiCompleta()
            )"
          >
            <i class="bi bi-pencil-square block"></i>
            Registrar
          </button>
          <button class="col90 row rowm center colSm50 btnDefault tCenter" v-on:click="mostrarMensajeRegistrar()" v-else>
            <i class="bi bi-pencil-square block"></i>
            Registrar
          </button>
          
        </div>

      </div>

<!--
      <div>
          {{formData}}
      </div>
-->
      
      <div class="col90" style="min-height:40px;">
        <span style="min-height:20px;"></span>
      </div>      

      


    </div>
    <modal title="Registro" v-if="showModal" v-on:closeModalEv="closeModal()" style="display:block;left:10vw;top:10vh;height:30vh;">              
      <div style="text-align:center;overflow-y:auto;">
        <h3 style="color:black;">
          {{modalMessage}}          
        </h3>        
      </div>
      
    </modal>


  </div>
</template>

<script>

import InputText from '../Inputs/InputText.vue';
import InputNumber from '../Inputs/InputNumber.vue';
import InputSelect from '../Inputs/InputSelect.vue';
import InputSelectList from '../Inputs/InputSelectList.vue';
import InputDate from '../Inputs/InputDate.vue';

import Modal from '../Modal/Modal.vue';
import { mapStores } from 'pinia';
import { useUserStore } from '../../store/user';


export default {
  name: 'RegistroDatosAspirante',

  components:{InputText,InputSelect,InputNumber,InputDate,InputSelectList,Modal},
  data:function(){
    return{
      modalMessage:'',
      showModal:false,
      showNuevoBac:false,
      showNuevaSec:false,
      showRegistrarBtn:false,
      showPersonal:false,
      showContacto:false,
      showAntecedente:false,
      showRelacionChedraui:false,
      listaRelacionChedraui:[
        {c1:'Colaborador',c2:'C'},
        {c1:'Familiar',c2:'F'},
        {c1:'Externo sin relación con Grupo Chedraui',c2:'O'},
      ],
      listaSituacionEscolar:[
        {c1:'Regular',c2:'1'},
        {c1:'Irregular',c2:'0'},
        {c1:'Repetidor',c2:'2'},
      ],
      listaOpcionesSiNo:[
        {c1:'Si',c2:'1'},
        {c1:'No',c2:'0'}
      ],
      listaSexo:[
        {c1:'HOMBRE',c2:'H'},
        {c1:'MUJER',c2:'M'}
      ],
      
      formData:{
        'email':{data:"",state:0},
        'telefono':{data:"",state:0},        
        'telefonoEmergencia':{data:"",state:0},                      

        'nombre':{data:"",state:0},
        'ap1':{data:"",state:0},
        'ap2':{data:"",state:0},        
        
        'curp':{data:"",state:0},        
        'sexo':{data:"",state:0},     

        'paisNac':{data:"",state:0},        
        'estadoNac':{data:"",state:0},
        'munNac':{data:"",state:0},   

        'estadoRes':{data:"",state:0},
        'munRes':{data:"",state:0},     
           
        'estadoSec':{data:"",state:0},
        'munSec':{data:"",state:0},      
        'secundaria':{data:"",state:0},
        'folioCertificadoSec':{data:"",state:0},  

        'bachilleratoPrevio':{data:"",state:0},        
        'estadoBacAnt':{data:"",state:0},        
        'munBacAnt':{data:"",state:0},
        'bachilleratoAnterior':{data:"",state:0},        
        'situacionEscolar':{data:"",state:0},
        
        'fechaNac':{data:"",state:0},

        'domicilio':{data:"",state:0},
        'codigoPostal':{data:"",state:0},

        'relacionChedraui':{data:"",state:0},
        'noColaborador':{data:"",state:0},
        'nombreFamiliar':{data:"",state:0},
        'ap1Familiar':{data:"",state:0},
        'ap2Familiar':{data:"",state:0},
        'estadoSucursal':{data:"",state:0},
        'municipioSucursal':{data:"",state:0},
        'sucursal':{data:"",state:0},
        'puesto':{data:"",state:0},
              

      },

      email:'',
      nombre:'',
      ap1:'',
      ap2:'',
      curp:'',
      telefono:'',   
      telefonoEmergencia:'',               
      sexo:'Seleccione el sexo',
      paisNac:'Seleccione el país',
      estadoNac:'Seleccione el estado',
      munNac:'Seleccione el municipio',
      estadoRes:'Seleccione el estado',
      munRes:'Seleccione el municipio',

      estadoSec:'Seleccione el estado',
      munSec:'Seleccione el municipio',
      secundaria:'Seleccione la Escuela',

      estadoEnBachilleratoAnterior:'Seleccione la opción',

      estadoBacAnt:'Seleccione el estado',
      munBacAnt:'Seleccione el municipio',
      bachilleratoAnterior:'Seleccione la Escuela',
      situacionEscolar:'Seleccione la opción',
      
      domicilio:'',
      codigoPostal:'',

      showGoToIniciarSesion:false,
      procesando:0,

      relacionChedraui:'Seleccione la opción',
      nombreFamiliar:'',
      sucursal:'Seleccione la sucursal',
      puesto:'Seleccione la opción',
      folioCertificadoSec:'',

      estadoSucursal:'',
      municipioSucursal:'',

      listaSucursal:[],
      listaPuestos:[
        {c1:'GERENTE'},
        {c1:'ASISTENTE'},
        {c1:'PROMOTOR'},
        {c1:'VENDEDOR DE PISO'},
        {c1:'CAJERO'},
      ],
      listaEstados:[],
      listaMunicipiosNac:[],
      listaMunicipiosRes:[],
      listaMunicipiosSec:[],
      listaMunicipiosBacAnt:[],
      listaMunicipiosSucursal:[],
      listaPaises:[        
      ],

      listaSecundarias:[        
      ],
      listaBachilleratos:[],    

    };

  },
  watch:{

    relacionChedraui:function(){
      //alert("Relacion chedraui cambiada");
      if(this.relacionChedraui=='Colaborador'){

        this.updateInputData('relacionChedrauiRef',''+this.formData.noColaborador.data);


        this.formData.nombreFamiliar.data=this.formData.nombre.data;
        this.formData.ap1Familiar.data=this.formData.ap1.data;
        this.formData.ap2Familiar.data=this.formData.ap2.data;
        this.formData.noColaborador.data=this.formData.curp.data;
        console.log('Nombre Familiar: '+this.formData.nombreFamiliar.data);
        console.log('Ap1 Familiar: '+this.formData.ap1Familiar.data);
        console.log('Ap2 Familiar: '+this.formData.ap2Familiar.data);
        console.log('noColaborador Familiar: '+this.formData.noColaborador.data);
      }



    },
    
    estadoNac:function(){
      this.getListaMunicipios(this.estadoNac,'nac');
    },
    estadoRes:function(){
      this.getListaMunicipios(this.estadoRes,'res');
    },
    estadoSec:function(){
      this.getListaMunicipios(this.estadoSec,'sec');
    },
    munSec:function() {
      this.getListaCatalogoSecundarias(this.formData.estadoSec.data,this.munSec)
    },
    estadoBacAnt:function(){
      this.getListaMunicipios(this.estadoBacAnt,'bacAnt');
    },
    munBacAnt:function(){
      this.getListaCatalogoBachilleratos();
    },

    estadoSucursal:function(){
      this.getListaMunicipios(this.estadoSucursal,'suc');
    },
    municipioSucursal:function(){
      this.getListaCatalogoSucursal();
      this.getListaCatalogoPuestos();
    },

  },
  created:function(){
    console.log("RegistroDatosAspirante created");
    this.getListaCatalogoEstado();
    this.getListaCatalogoPais();   
    

  },
  mounted:function(){
    console.log("RegistroDatosAspirante mounted");
    //if(this.userStore.datosRegistrados==1){
      //this.showPersonal=true;      
    //}
  },
  methods:{

    closeModal(){

      this.showModal=false;

    },    

    updateInputData(strRef,val){
      //console.log("Refs:");
      //console.log(this.$refs);
      this.$refs[''+strRef].updateData(val);
      //console.log("InputData:");
      //console.log(this.$refs[strRef]);

    },

    loadData(data){
      //console.log("loadData()");
      this.formData.nombre.data=data.c0;
      this.formData.ap1.data=data.c1;
      this.formData.ap2.data=data.c2;
      this.formData.sexo.data=data.c3;
      this.formData.curp.data=data.c4;
      this.formData.fechaNac.state=1;
      this.formData.fechaNac.data=data.c5;
      this.formData.paisNac.data=data.c10;
      this.formData.estadoNac.data=data.c11.toUpperCase();
      this.formData.munNac.data=data.c12.toUpperCase();

      this.formData.telefono.data=data.c6;
      this.formData.telefonoEmergencia.data=data.c7;
      this.formData.domicilio.data=data.c18;
      this.formData.codigoPostal.data=data.c9;

      this.formData.estadoRes.data=data.c13.toUpperCase();
      this.formData.munRes.data=data.c14.toUpperCase();

      this.formData.estadoSec.data=data.c15.toUpperCase();
      this.formData.munSec.data=data.c16.toUpperCase();
      this.formData.secundaria.data=data.c17;
      this.formData.folioCertificadoSec.data=data.c18;
      this.formData.bachilleratoPrevio.data=data.c19;
      this.formData.estadoBacAnt.data=data.c20.toUpperCase();
      this.formData.munBacAnt.data=data.c21.toUpperCase();
      this.formData.bachilleratoAnterior.data=data.c22.toUpperCase();
      this.formData.situacionEscolar.data=data.c23;

      if(data.c24=='COL'){
        this.formData.relacionChedraui.data='Colaborador';
      }
      if(data.c24=='FAM'){
        this.formData.relacionChedraui.data='Familiar';
      }
      if(data.c24=='EXT'){
        this.formData.relacionChedraui.data='Externo sin relación con Grupo Chedraui';
      }
      this.formData.nombreFamiliar.data=data.c25;
      this.formData.ap1Familiar.data=data.c26;
      this.formData.ap2Familiar.data=data.c27;

      this.formData.noColaborador.data=data.c28;
      this.formData.puesto.data=data.c29.toUpperCase();
      this.formData.sucursal.data=data.c30.toUpperCase();
      this.formData.estadoSucursal.data=data.c31.toUpperCase();
      this.formData.municipioSucursal.data=data.c32.toUpperCase();

      if(this.formData.nombre.data!=''){
        this.formData.nombre.state              =1;
      }
      if(this.formData.ap1.data!=''){
        this.formData.ap1.state                 =1;
      }
      if(this.formData.ap2.data!=''){
        this.formData.ap2.state                 =1;
      }
      if(this.formData.nombre.data!=''){
        this.formData.sexo.state                =1;
      }
      if(this.formData.curp.data!=''){
        this.formData.curp.state                =1;
      }
      if(this.formData.paisNac.data!=''){
        this.formData.paisNac.state             =1;
      }
      if(this.formData.estadoNac.data!=''){
        this.formData.estadoNac.state           =1;
      }
      if(this.formData.munNac.data!=''){
        this.formData.munNac.state              =1;
      }
      if(this.formData.munNac.data!=''){
        this.formData.telefono.state            =1;
      }

      if(this.formData.telefonoEmergencia.data!=''){
        this.formData.telefonoEmergencia.state  =1;
      }
      if(this.formData.domicilio.data!=''){        
        this.formData.domicilio.state           =1;
      }
      if(this.formData.codigoPostal.data!=''){        
        this.formData.codigoPostal.state        =1;
      }
      if(this.formData.estadoRes.data!=''){        
        this.formData.estadoRes.state           =1;
      }
      if(this.formData.munRes.data!=''){        
        this.formData.munRes.state              =1;
      }
      if(this.formData.estadoSec.data!=''){      
        this.formData.estadoSec.state           =1;
      }
      if(this.formData.munSec.data!=''){        
        this.formData.munSec.state              =1;
      }
      if(this.formData.secundaria.data!=''){        
        this.formData.secundaria.state          =1;
      }
      if(this.formData.folioCertificadoSec.data!=''){        
        this.formData.folioCertificadoSec.state =1;
      }
      if(this.formData.bachilleratoPrevio.data!=''){        
        this.formData.bachilleratoPrevio.state  =1;
      }
      if(this.formData.estadoBacAnt.data!=''){        
        this.formData.estadoBacAnt.state        =1;
      }
      if(this.formData.munBacAnt.data!=''){        
        this.formData.munBacAnt.state           =1;
      }
      if(this.formData.bachilleratoAnterior.data!=''){        
        this.formData.bachilleratoAnterior.state=1;
      }
      if(this.formData.situacionEscolar.data!=''){        
        this.formData.situacionEscolar.state    =1;
      }
      if(this.formData.relacionChedraui.data!=''){        
        this.formData.relacionChedraui.state    =1;
      }
      if(this.formData.nombreFamiliar.data!=''){        
        this.formData.nombreFamiliar.state      =1;
      }
      if(this.formData.ap1Familiar.data!=''){        
        this.formData.ap1Familiar.state         =1;
      }
      if(this.formData.ap2Familiar.data!=''){        
        this.formData.ap2Familiar.state         =1;
      }
      if(this.formData.noColaborador.data!=''){        
        this.formData.noColaborador.state       =1;
      }
      if(this.formData.puesto.data!=''){        
        this.formData.puesto.state              =1;
      }
      if(this.formData.sucursal.data!=''){        
        this.formData.sucursal.state            =1;
      }
      if(this.formData.estadoSucursal.data!=''){        
        this.formData.estadoSucursal.state      =1;
      }
      if(this.formData.municipioSucursal.data!=''){        
        this.formData.municipioSucursal.state   =1;
      }
            
      this.showPersonal=true;

    },
    getDatosAspirante(){
      console.log("getDatosAspirante()");
      let data={       
        idp:''+this.userStore.idp 
      };
      this.$root.sendPOSTRequest(
        "https://webdevworks.xyz/scLC/api/v1/datosAspirante/",
        data,
        this.onSuccessGetDatosAspirante,
        this.onErrorGetDatosAspirante
        );
    },
    onSuccessGetDatosAspirante(data){
      console.log("Data:");
      console.log(data.data.data);     
      this.loadData(data.data.data);       
      
    },
    onErrorGetDatosAspirante(data){
      console.log(data);
    },

    sendRegistrar(){
      console.log("sendRegistrar()");
      let data={
        idp                         :''+this.userStore.idp,
        correo                      :''+this.userStore.correo,
        nombre                      :''+this.formData.nombre.data,
        ap1                         :''+this.formData.ap1.data,
        ap2                         :''+this.formData.ap2.data,
        curp                        :''+this.formData.curp.data,
        sexo                        :''+this.formData.sexo.data,
        fechaNac                    :''+this.formData.fechaNac.data,
        paisNac                     :''+this.formData.paisNac.data,
        estadoNac                   :''+this.formData.estadoNac.data,
        munNac                      :''+this.formData.munNac.data,
        telefono                    :''+this.formData.telefono.data,
        telefonoEmergencias         :''+this.formData.telefonoEmergencia.data,
        domicilio                   :''+this.formData.domicilio.data,
        codigoPostal                :''+this.formData.codigoPostal.data,
        estadoRes                   :''+this.formData.estadoRes.data,
        munRes                      :''+this.formData.munRes.data,
        estadoSec                   :''+this.formData.estadoSec.data,
        munSec                      :''+this.formData.munSec.data,
        secundaria                  :''+this.formData.secundaria.data,
        folioCertificadoSec         :''+this.formData.folioCertificadoSec.data,
        bachilleratoPrevio          :''+this.formData.bachilleratoPrevio.data,
        estadoBach                  :''+this.formData.estadoBacAnt.data,
        munBach                     :''+this.formData.munBacAnt.data,
        bachillerato                :''+this.formData.bachilleratoAnterior.data,
        situacionEscolar            :''+this.formData.situacionEscolar.data,
        relChedraui                 :''+this.formData.relacionChedraui.data,
        noColaborador               :''+this.formData.noColaborador.data,
        nombreFamiliar              :''+this.formData.nombreFamiliar.data,
        ap1Familiar                 :''+this.formData.ap1Familiar.data,
        ap2Familiar                 :''+this.formData.ap2Familiar.data,
        estadoSucursal              :''+this.formData.estadoSucursal.data,
        munSucursal                 :''+this.formData.municipioSucursal.data,
        sucursal                    :''+this.formData.sucursal.data,
        puesto                      :''+this.formData.puesto.data,
      };
      this.$root.sendPOSTRequest(
        "https://webdevworks.xyz/scLC/api/v1/registrarDatosAlumno/",
        data,
        this.onSuccessSendRegistrar,
        this.onErrorSendRegistrar
        );
    },
    onSuccessSendRegistrar(data){
      console.log("Data send registrar:");
      console.log(data);
      if(data.update1=='ok'&&data.update2=='ok'){
        this.showModal=true;
        this.modalMessage='Registro de información actualizado exitosamente';
        this.userStore.datosRegistrados=parseInt(data.datosRegistrados);
      }

      
    },
    onErrorSendRegistrar(data){
      console.log(data);
      this.showModal=true;
      this.modalMessage='Ocurrior un error al actualizar su registro de información';
    },


    mostrarMensajeRegistrar(){
      alert('Debes llenar correctamente todos los campos antes de poder actualizar sus datos');
    },

    isInformacionPersonalCompleta(){

      return (
      this.formData.nombre.state   ==1&&
      this.formData.ap1.state      ==1&&
      this.formData.ap2.state      ==1&&
      this.formData.sexo.state     ==1&&
      this.formData.paisNac.state  ==1&&
      this.formData.estadoNac.state==1&&
      this.formData.munNac.state   ==1&&
      this.formData.fechaNac.state ==1&&
      this.formData.curp.state==1
      );

    },

    isInformacionContactoCompleta(){

      return (
      this.formData.telefono.state          ==1&&
      this.formData.telefonoEmergencia.state==1&&
      this.formData.domicilio.state         ==1&&
      this.formData.estadoRes.state         ==1&&
      this.formData.codigoPostal.state      ==1&&
      this.formData.munRes.state            ==1
      );

    },

    isInformacionAntecedenteEscolarCompleta(){
      if(this.formData.bachilleratoPrevio.state==1&&this.formData.bachilleratoPrevio.data==1){
        return (
          this.formData.estadoSec.state           ==1&&
          this.formData.munSec.state              ==1&&
          this.formData.secundaria.state          ==1&&
          this.formData.folioCertificadoSec.state ==1&&
          this.formData.bachilleratoPrevio.state  ==1&&
          this.formData.estadoBacAnt.state        ==1&&
          this.formData.munBacAnt.state           ==1&&
          this.formData.bachilleratoAnterior.state==1&&
          this.formData.situacionEscolar.state    ==1
        );

      }else{
        return (
          this.formData.estadoSec.state           ==1&&
          this.formData.munSec.state              ==1&&
          this.formData.secundaria.state          ==1&&
          this.formData.folioCertificadoSec.state ==1&&
          this.formData.bachilleratoPrevio.state  ==1
        );        
      }
    },

    isInformacionRelacionGrupoChedrauiCompleta(){

      if(this.formData.relacionChedraui.data=='Familiar'){
        console.log("familiar");
        return( 
        this.formData.relacionChedraui.state  ==1&&
        this.formData.noColaborador.state     ==1&&
        this.formData.nombreFamiliar.state    ==1&&
        this.formData.ap1Familiar.state       ==1&&
        this.formData.ap2Familiar.state       ==1&&
        this.formData.estadoSucursal.state    ==1&&
        this.formData.municipioSucursal.state ==1&&
        this.formData.sucursal.state          ==1&&
        this.formData.puesto.state            ==1
        );
      }
      if(this.formData.relacionChedraui.data=='Colaborador'){
        console.log("colaborador");
        return( 
        this.formData.relacionChedraui.state  ==1&&
        this.formData.noColaborador.state     ==1&&        
        this.formData.estadoSucursal.state    ==1&&
        this.formData.municipioSucursal.state ==1&&
        this.formData.sucursal.state          ==1&&
        this.formData.puesto.state            ==1
        );
      }
      console.log("externo");
      return( 
        this.formData.relacionChedraui.state  ==1            
      );
      


    },
    

    getListaCatalogoPais(){
      console.log("getListaCatalogoPais()");
      let data={        
      };
      this.$root.sendPOSTRequest(
        "https://webdevworks.xyz/scLC/api/v1/definicionPaises/",
        data,
        this.onSuccessGetListaCatalogoPais,
        this.onErrorGetListaCatalogoPais
        );
    },
    onSuccessGetListaCatalogoPais(data){
      console.log("Data:");
      console.log(data.data.data);            
      this.setListaPaises(data.data.data);
    },
    onErrorGetListaCatalogoPais(data){
      console.log(data);
    },
    setListaPaises(data){
      this.listaPaises=data;
      this.getDatosAspirante();
    },


    getListaCatalogoEstado(){
      console.log("getListaCatalogoEstado()");
      let data={        
      };
      this.$root.sendPOSTRequest(
        "https://webdevworks.xyz/scLC/api/v1/definicionEstados/",
        data,
        this.onSuccessGetListaCatalogoEstado,
        this.onErrorGetListaCatalogoEstado
        );
    },
    onSuccessGetListaCatalogoEstado(data){
      console.log("Data:");
      console.log(data.data.data);            
      this.setListaEstados(data.data.data);
    },
    onErrorGetListaCatalogoEstado(data){
      console.log(data);
    },
    setListaEstados(data){
      this.listaEstados=data;
    },

    getListaMunicipios(estado,op){
      console.log("getListaMunicipios()");
      let data={
        estado:''+estado
      };
      this.$root.sendPOSTRequestParam(
        "https://webdevworks.xyz/scLC/api/v1/definicionMunicipios/",
        data,
        this.onSuccessGetListaMunicipios,
        this.onErrorGetListaMunicipios,
        op
        );
    },
    onSuccessGetListaMunicipios(data,op){
      console.log("Data:");
      console.log(data.data.data);            
      this.setListaMunicipios(data.data.data,op);
    },
    onErrorGetListaMunicipios(data){
      console.log(data);
    },
    setListaMunicipios(data,op){
      if(op=='nac'){
        this.listaMunicipiosNac=data;
      }
      if(op=='res'){
        this.listaMunicipiosRes=data;
      }
      if(op=='sec'){
        this.listaMunicipiosSec=data;
      }
      if(op=='bacAnt'){
        this.listaMunicipiosBacAnt=data;
      }
      if(op=='suc'){
        this.listaMunicipiosSucursal=data;
      }      
    },

    getListaCatalogoSecundarias(estado,municipio){
      console.log("getListaCatalogoSecundarias()");
      let data={   
        mun:''+municipio,
        est:''+estado
      };
      this.$root.sendPOSTRequest(
        "https://webdevworks.xyz/scLC/api/v1/definicionSecundarias/",
        data,
        this.onSuccessGetListaCatalogoSecundarias,
        this.onErrorGetListaCatalogoSecundarias
        );
    },
    onSuccessGetListaCatalogoSecundarias(data){
      console.log("Data:");
      console.log(data.data.data);            
      this.setListaCatalogoSecundarias(data.data.data);
    },
    onErrorGetListaCatalogoSecundarias(data){
      console.log(data);
    },
    setListaCatalogoSecundarias(data){
      this.listaSecundarias=data;
    },
    getListaCatalogoBachilleratos(){
      console.log("getListaCatalogoSecundarias()");
      let data={   
        mun:''+this.formData.munBacAnt.data,
        est:''+this.formData.estadoBacAnt.data
      };
      this.$root.sendPOSTRequest(
        "https://webdevworks.xyz/scLC/api/v1/definicionBachilleratos/",
        data,
        this.onSuccessGetListaCatalogoBachilleratos,
        this.onErrorGetListaCatalogoBachilleratos
        );
    },
    onSuccessGetListaCatalogoBachilleratos(data){
      console.log("Data:");
      console.log(data.data.data);            
      this.setListaCatalogoBachilleratos(data.data.data);
    },
    onErrorGetListaCatalogoBachilleratos(data){
      console.log(data);
    },
    setListaCatalogoBachilleratos(data){
      this.listaBachilleratos=data;
    },


    getListaCatalogoPuestos(){
      console.log("getListaCatalogoPuestos()");
      let data={           
      };
      this.$root.sendPOSTRequest(
        "https://webdevworks.xyz/scLC/api/v1/definicionPuestos/",
        data,
        this.onSuccessGetListaCatalogoPuestos,
        this.onErrorGetListaCatalogoPuestos
        );
    },
    onSuccessGetListaCatalogoPuestos(data){
      console.log("Data:");
      console.log(data.data.data);            
      this.setListaCatalogoPuestos(data.data.data);
    },
    onErrorGetListaCatalogoPuestos(data){
      console.log(data);
    },
    setListaCatalogoPuestos(data){
      this.listaPuestos=data;
    },


    getListaCatalogoSucursal(){
      console.log("getListaCatalogoSucursal()");
      let data={   
        mun:''+this.formData.municipioSucursal.data,
        est:''+this.formData.estadoSucursal.data
      };
      this.$root.sendPOSTRequest(
        "https://webdevworks.xyz/scLC/api/v1/definicionSucursal/",
        data,
        this.onSuccessGetListaCatalogoSucursal,
        this.onErrorGetListaCatalogoSucursal
        );
    },
    onSuccessGetListaCatalogoSucursal(data){
      console.log("Data:");
      console.log(data.data.data);            
      this.setListaCatalogoSucursal(data.data.data);
    },
    onErrorGetListaCatalogoSucursal(data){
      console.log(data);
    },
    setListaCatalogoSucursal(data){
      this.listaSucursal=data;
    },

    

  },
  computed:{    
    ...mapStores(useUserStore),
  },
  props: {
    
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
