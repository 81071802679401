<template>
  <div>
  
    <input :class="getInputClass()" type="text" v-model="data" :placeholder="placeholder">
    <i class="bi bi-x-circle-fill fontError" style="max-height:10px;font-size:1.3rem;float:right;position:relative;top:-27px;right:15px;margin-bottom:0px;" v-if="state==-1"></i>
    <i class="bi bi-check-circle-fill fontCorrect" style="max-height:1px;font-size:1.3rem;float:right;position:relative;top:-27px;right:15px;margin-bottom:0px;" v-if="state==1"></i>    
    
  </div>
</template>
<script>

export default{
 name:'InputText',
 data:function(){
  return{
    data:'',
    state:0
  };
 },
 props:{
  propName:String,
  placeholder:String,
  maxLength:Number,
  minLength:Number,
  capital:Boolean,
  mode:String,
  ref:String 

 },

 mounted:function(){
  
  this.data=this.$parent.formData[''+this.propName].data;
  this.state=this.$root.verifData(this.data, this.mode, this.minLength, this.maxLength);
  //console.log("inputText Mounted data: "+this.data);

 },
 watch:{
  data:function(){

    if(this.capital){
      this.data=this.data.toUpperCase();
    }
    if(this.data.length>this.maxLength){
      this.data=this.data.substring(0,this.maxLength);
    }

    this.state=this.$root.verifData(this.data, this.mode, this.minLength, this.maxLength);
    if(this.propName!=''){
        //this.$parent[''+this.propName]=this.data;
        
        this.$parent.formData[''+this.propName]={data:''+this.data,state:this.state};
        //console.log("actualizando valor de "+this.propName);
    }

  }
 },
 methods:{

  updateData(val){
    console.log("updateData()");
    this.data=val;

  },

  getData(){
    return this.data;
  },
  getBState(){
    return (this.state==1);
  },
  
  getInputClass(){    

    let classStr={};

    classStr={
        '-1':"inputError",
        '0':'input',
        '1':'inputCorrect'
    };

    return classStr[''+this.state];
  },

  


 }
}
</script>
<style scoped>

</style>
