<template>
  <div>
  
    <input :class="getInputClass()" type="number" v-model="data" :placeholder="placeholder">
    <i class="bi bi-x-circle-fill fontError" style="max-height:10px;font-size:1.3rem;float:right;position:relative;top:-27px;right:15px;margin-bottom:0px;" v-if="state==-1"></i>
    <i class="bi bi-check-circle-fill fontCorrect" style="max-height:1px;font-size:1.3rem;float:right;position:relative;top:-27px;right:15px;margin-bottom:0px;" v-if="state==1"></i>    
    
  </div>
</template>
<script>

export default{
 name:'InputNumber',
 data:function(){
  return{
    data:'',
    state:0
  };
 },
 props:{
  mode:String,
  min:String,
  max:String,
  propName:String,
  placeholder:String,
  maxLength:Number,
  minLength:Number,
  capital:Boolean  
 },
 mounted:function(){

  if(this.$parent.formData[''+this.propName].data!=''){
      this.data=this.$parent.formData[''+this.propName].data;
      this.state=1;
  }

 },
 watch:{
  data:function(){
    
    if(this.data.length>this.maxLength){
      this.data=this.data.substring(0,this.maxLength);
    }
    let nData=parseFloat(this.data);
    
    //console.log("Num en intervalo");
    //console.log(nData>=parseFloat(''+this.min)&&nData<=parseFloat(''+this.max));
    //console.log("length:");
    //console.log((''+this.data).length);
    if(this.mode=='L'){
      if((''+this.data).length>=this.minLength&&(''+this.data).length<=this.maxLength){
        this.state=1;      
      }else{
        this.state=-1;      
      }
    }
    if(this.mode=='C'){
      if(nData>=parseFloat(''+this.min)&&nData<=parseFloat(''+this.max)){        
        this.state=1;
      }else{
        this.state=-1;
      }
    }

    if(this.propName!=''){
        //this.$parent[''+this.propName]=this.data;
        
        this.$parent.formData[''+this.propName]={data:''+this.data,state:this.state};
        //console.log("actualizando valor de "+this.propName);
    }

    
    

  }
 },
 methods:{

  getData(){
    return this.data;
  },
  getBState(){
    return (this.state==1);
  },
  
  getInputClass(){    

    let classStr={};

    classStr={
        '-1':"inputError",
        '0':'input',
        '1':'inputCorrect'
    };

    return classStr[''+this.state];
  },

  


 }
}
</script>
<style scoped>

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

</style>
