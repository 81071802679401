<template class="transparent">
  <div class="blockRow" v-if="displayOption == 0 || displayOption == 1">
    <div class="blockRow">
      <div class="aspirante__pasoNum">
        <div class="right square" style="vertical-align:top;">
          <i class="bi bi-square" style="vertical-align:top;" v-if="userStore.correoVerificado == 0"></i>
          <i
            class="bi bi-exclamation-square-fill"
            style="color: var(--comment);vertical-align:top;"
            v-if="userStore.correoVerificado == 2"
          ></i>
          <i class="bi bi-check-square-fill" style="vertical-align:top;color: var(--correct);" v-if="userStore.correoVerificado == 1"></i>
        </div>
        <div style="vertical-align:top;">
          <strong> 1) Verificar Correo </strong>          
        </div>        

      </div>
      
    </div>
    <div class="block" style="text-align:left;line-height:15px;">      
      <label v-if="userStore.correoVerificado == 0">
        Verifica tu dirección de correo electrónico.
      </label>
      <label style="color: var(--comment)" v-if="userStore.correoVerificado == 2">
        Error en verificación de correo electrónico.
      </label>
      <label style="color: var(--correct);" v-if="userStore.correoVerificado == 1">
        Correo verificado exitosamente.
      </label>      
    </div>
    
    <div class="aspiranteSubrow subRowBottom whiteDark tCenter" v-on:click="openClose(1)">
      <span class="center" style="border: none; background: none">
        <i class="bi bi-chevron-down" v-if="displayOption == 0"></i>
        <i class="bi bi-chevron-up" v-if="displayOption == 1"></i>
      </span>
    </div>
  </div>
  <div class="aspiranteContainer" v-if="displayOption == 1">
    <verificar-correo></verificar-correo>
  </div>

  <div class="blockRow transparent" v-if="displayOption == 0 || displayOption == 2">
    <div class="blockRow">
      <div class="blockRow">
        <div class="aspirante__pasoNum" style="vertical-align:top;">
          <div class="right square" style="vertical-align:top;">
            <i class="bi bi-square" style="vertical-align:top;" v-if="userStore.datosRegistrados == 0 && userStore.datosValidados==0"></i>
            <i
              class="bi bi-exclamation-square-fill"
              style="color: var(--comment);vertical-align:top;"
              v-if="userStore.datosRegistrados == 1 && userStore.datosValidados==2"
            ></i>
            <i class="bi bi-check-square-fill" style="vertical-align:top;color:var(--blue);" v-if="userStore.datosRegistrados == 1 && userStore.datosValidados==0"></i>
            <i class="bi bi-check-square-fill" style="vertical-align:top;color:var(--correct);" v-if="userStore.datosRegistrados == 1 && userStore.datosValidados==1"></i>
          </div>
          <div style="vertical-align:top;">
            <strong> 2) Registrar Datos </strong>
          </div>
        </div>
        
      </div>
      <div class="block" style="text-align:left;line-height:15px;">      
      
        <label v-if="userStore.correoVerificado == 0" >
            Verifica tu dirección de correo electrónico. Antes de Poder Continuar.
        </label>
        <label v-if="userStore.correoVerificado==1&&userStore.datosRegistrados==0&& userStore.datosValidados==0">
            Registra tus datos.
        </label>
        <label v-if="userStore.datosRegistrados==1&& userStore.datosValidados==0" style="color:var(--blue);">
            Datos en espera de verificación.
        </label>
        <label v-if="userStore.datosRegistrados==1&& userStore.datosValidados==1" style="color:var(--correct);">
            Datos validados.
        </label>
        
      </div>
      <div
        class="aspiranteSubrow subRowBottom whiteDark tCenter"
        v-on:click="openClose(2)"
      >
        <span class="center" style="border: none; background: none">
          <i class="bi bi-chevron-down" v-if="displayOption == 0"></i>
          <i class="bi bi-chevron-up" v-if="displayOption == 2"></i>
        </span>
      </div>
    </div>
    <div class="aspiranteContainer" v-if="displayOption == 2">
      <registro-datos-aspirante></registro-datos-aspirante>
    </div>
  </div>

  

  <div class="blockRow transparent" v-if="displayOption == 0 || displayOption == 3">
    <div class="blockRow">
      <div class="blockRow">
        <div class="aspirante__pasoNum">
          <div class="right square" style="vertical-align:top;">
            <i class="bi bi-square" style="vertical-align:top;" 
            v-if="userStore.fichaRegistroRegistrada==0&&userStore.fichaRegistroValidada==0"></i>
            <i class="bi bi-exclamation-square-fill" style="color: var(--comment);vertical-align:top;"
              v-if="userStore.fichaRegistroRegistrada==1&&userStore.fichaRegistroValidada==2"></i>
            <i class="bi bi-check-square-fill" style="vertical-align:top;color:var(--blue);" 
            v-if="userStore.fichaRegistroRegistrada==1&&userStore.fichaRegistroValidada==0"></i>
            <i class="bi bi-check-square-fill" style="vertical-align:top;color:var(--correct);" 
            v-if="userStore.fichaRegistroRegistrada==1&&userStore.fichaRegistroValidada==1"></i>
          </div>
          <div style="vertical-align: top;">
            <strong> 3) Llenar Ficha de Registro </strong>
          </div>
        </div>        
      </div>
      <div class="block" style="text-align:left;line-height:15px;">      
        <label v-if="userStore.fichaRegistroRegistrada==0&&userStore.fichaRegistroValidada==0">
          Completa tu ficha de registro.
        </label>

        <label style="color: var(--blue);" v-if="userStore.fichaRegistroRegistrada==1&&userStore.fichaRegistroValidada==0">
          Ficha de registro en verificación.
        </label>

        <label style="color: var(--comment);" v-if="userStore.fichaRegistroRegistrada==1&&userStore.fichaRegistroValidada==2">
          Hay errores que corregir en tu Ficha de registro.
        </label>
        
        <label style="color: var(--correct);" v-if="userStore.fichaRegistroRegistrada==1&&userStore.fichaRegistroValidada==1">
          Ficha de registro verificada exitosamente.
        </label>
      </div>
      <div
        class="aspiranteSubrow subRowBottom whiteDark tCenter"
        v-on:click="openClose(3)"
      >
        <span style="border: none; background: none">
          <i class="bi bi-chevron-down" v-if="displayOption == 0"></i>
          <i class="bi bi-chevron-up" v-if="displayOption == 3"></i>
        </span>
      </div>
    </div>
    <div class="aspiranteContainer" v-if="displayOption == 3">
      <ficha-registro-aspirante></ficha-registro-aspirante>
    </div>
  </div>

  <div class="blockRow transparent" v-if="displayOption == 0 || displayOption == 4">
    <div class="blockRow">
      <div class="blockRow">
        <div class="aspirante__pasoNum">
          <div class="right square" style="vertical-align:top;">
            <i class="bi bi-square" style="vertical-align:top;" 
            v-if="userStore.fichaRegistroCargada==0&&userStore.fichaRegistroValidada==0"></i>            
            <i class="bi bi-check-square-fill" style="vertical-align:top;color:var(--blue);" 
            v-if="userStore.fichaRegistroCargada==1&&userStore.fichaRegistroValidada==0"></i>
            <i class="bi bi-exclamation-square-fill" style="vertical-align:top;color:var(--comment);" 
            v-if="userStore.fichaRegistroCargada==1&&userStore.fichaRegistroValidada==2"></i>
            <i class="bi bi-check-square-fill" style="vertical-align:top;color:var(--correct);" 
            v-if="userStore.fichaRegistroCargada==1&&userStore.fichaRegistroValidada==1"></i>            
          </div>
          <div style="vertical-align:top;">
            <strong> 4) Firmar Ficha de Registro </strong>
          </div>
        </div>
        
      </div>
      <div class="block" style="text-align:left;line-height:15px;">      
        <label v-if="userStore.fichaRegistroRegistrada==1&&userStore.fichaRegistroCargada==0&&userStore.fichaRegistroValidada==0">
          Firma, descarga y carga tu ficha de Registro.
        </label>
        
        <label style="color: var(--blue);" v-if="userStore.fichaRegistroRegistrada==1&&userStore.fichaRegistroCargada==1&&userStore.fichaRegistroValidada==0">
          Ficha de registro cargada y en verificación.
        </label>

        <label style="color: var(--comment);" v-if="userStore.fichaRegistroRegistrada==1&&userStore.fichaRegistroCargada==1&&userStore.fichaRegistroValidada==2">
          Hay errores que corregir en tu Ficha de registro.
        </label>
        
        <label style="color: var(--correct);" v-if="userStore.fichaRegistroRegistrada==1&&userStore.fichaRegistroCargada==1&&userStore.fichaRegistroValidada==1">
          Ficha de registro verificada exitosamente.
        </label>
      </div>
      <div
        class="aspiranteSubrow subRowBottom whiteDark tCenter"
        v-on:click="openClose(4)"
      >
        <span style="border: none; background: none">
          <i class="bi bi-chevron-down" v-if="displayOption == 0"></i>
          <i class="bi bi-chevron-up" v-if="displayOption == 4"></i>
        </span>
      </div>
    </div>
    <div class="aspiranteContainer" v-if="displayOption == 4">
      <FirmarDescargarFichaRegistroAspirante :key="reloadFirma" @closeOpenEv="openCloseFirma"></FirmarDescargarFichaRegistroAspirante>
    </div>
  </div>

  <div class="blockRow transparent" v-if="displayOption == 0 || displayOption == 5">
    <div class="blockRow">
      <div class="blockRow">
        <div class="aspirante__pasoNum">
          <div class="right square" style="vertical-align:top;">
            <i class="bi bi-square" style="vertical-align:top;" v-if="userStore.documentosCargados==0&&userStore.documentosValidados==0"></i>
            <i
              class="bi bi-exclamation-square-fill"
              style="color: var(--comment);vertical-align:top;"
              v-if="userStore.documentosCargados==1&&userStore.documentosValidados==2"
            >
            </i>
            <i class="bi bi-check-square-fill" style="vertical-align:top;color:var(--blue);" v-if="userStore.documentosCargados==1&&userStore.documentosValidados==0"></i>
            <i class="bi bi-check-square-fill" style="vertical-align:top;color:var(--correct);" v-if="userStore.documentosCargados==1&&userStore.documentosValidados==1"></i>
          </div>
          <div style="vertical-align: top;">
            <strong> 5) Cargar Documentos </strong>
          </div>
        </div>        
      </div>
      <div class="block" style="text-align:left;line-height:15px;">      
        <label v-if="userStore.documentosCargados==0&&userStore.documentosValidados==0">
          Carga tus documentos.
        </label>
        <label style="color: var(--blue)" v-if="userStore.documentosCargados==1&&userStore.documentosValidados==0">
          Documentos en espera de verificación.
        </label>
        <label style="color: var(--comment)" v-if="userStore.documentosCargados==1&&userStore.documentosValidados==2">
          Uno o más documentos necesitan corrección.
        </label>
        <label style="color: var(--correct)" v-if="userStore.documentosCargados==1&&userStore.documentosValidados==1">
          Documentos verificados exitosamente.
        </label>
      </div>
      <div
        class="aspiranteSubrow subRowBottom whiteDark tCenter"
        v-on:click="openClose(5)"
      >
        <span class="center" style="border: none; background: none">
          <i class="bi bi-chevron-down" v-if="displayOption == 0"></i>
          <i class="bi bi-chevron-up" v-if="displayOption == 5"></i>
        </span>
      </div>
    </div>
    <div class="aspiranteContainer" v-if="displayOption == 5">
      <carga-documentos-aspirante></carga-documentos-aspirante>
    </div>
  </div>

  <div class="blockRow transparent" v-if="displayOption == 0 || displayOption == 6">
    <div class="blockRow">
      <div class="blockRow">
        <div class="aspirante__pasoNum">
          <div class="right square" style="vertical-align:top;">
            <i class="bi bi-square" style="vertical-align:top;" 
            v-if="userStore.descargaComprobanteInscripcion==0"></i>                        
            <i class="bi bi-check-square-fill" style="vertical-align:top;color:var(--correct);" 
            v-if="userStore.descargaComprobanteInscripcion==1"></i>            
          </div>
          <div style="vertical-align: top;">
            <strong> 6) Descargar comprobante Inscripción </strong>
          </div>
        </div>        
      </div>
      <div class="block" style="text-align:left;line-height:15px;">      
        <label v-if="userStore.correoVerificado==0&&userStore.datosValidados==0&&userStore.documentosValidados==0&&userStore.fichaRegistroValidada==0">
          Completa los pasos anteriores para descargar tu comprobante de inscripción.
        </label>
        
        <label style="color: var(--blue)" v-if="userStore.correoVerificado==1&&userStore.datosValidados==1&&userStore.documentosValidados==1&&userStore.fichaRegistroValidada==1&&userStore.descargaComprobanteInscripcion==0">
          Ya puedes descargar tu comprobante de inscripción.
        </label>

        <label style="color: var(--correct)" v-if="userStore.descargaComprobanteInscripcion==1">
          Comprobante de Inscripción Descargado.
        </label>
                
      </div>
      <div
        class="aspiranteSubrow subRowBottom whiteDark tCenter"
        v-on:click="openClose(6)"
      >
        <span style="border: none; background: none">
          <i class="bi bi-chevron-down" v-if="displayOption == 0"></i>
          <i class="bi bi-chevron-up" v-if="displayOption == 6"></i>
        </span>
      </div>
    </div>
    <div class="aspiranteContainer" v-if="displayOption == 6">
      <descargar-comprobante></descargar-comprobante>
    </div>
  </div>
</template>
<script>
import { mapStores } from "pinia";
import { useUserStore } from "../../store/user";
import VerificarCorreo from "./VerificarCorreo.vue";
import RegistroDatosAspirante from "./RegistroDatosAspirante.vue";
import CargaDocumentosAspirante from "./CargaDocumentosAspirante.vue";
import FichaRegistroAspirante from "./FichaRegistroAspirante.vue";
import DescargarComprobante from "./DescargarComprobante.vue";
import FirmarDescargarFichaRegistroAspirante from "./FirmarDescargarFichaRegistroAspirante.vue";

export default {
  name: "MenuAspirante",
  components: {
    VerificarCorreo,
    RegistroDatosAspirante,
    CargaDocumentosAspirante,
    FichaRegistroAspirante,
    DescargarComprobante,
    FirmarDescargarFichaRegistroAspirante,
  },
  props: {},
  emits: ["expandMenuEv"],
  data: function () {
    return {
      reloadFirma:0,
      displayOption: 0,
      openForm: false,
      showVerificarCorreo: false,
      showRegistrarDatos: false,
      showCargarDocumentos: false,
      showFichaRegistro: false,
      showDescargarComprobante: false,
      showEstatusCuota: false,
    };
  },
  watch: {
    displayOption: function () {
      console.log("MenuAspirante ExpandMenu");
      if (this.displayOption != 0) {
        this.$emit("expandMenuEv", true);
      } else {
        this.$emit("expandMenuEv", false);
      }
    },
  },
  methods: {

    openCloseFirma(){

      
      //this.displayOption = 0;
      console.log("openCloseFirma()");
      this.reloadFirma++;
      //this.displayOption = 4;

    },

    openClose(opt) {
      if(this.displayOption == 0){
        this.displayOption = opt;
      }
      else {
        this.displayOption = 0;
      }
    },
  },
  mounted: function () {},
  computed: {
    ...mapStores(useUserStore),
  },
};
</script>
<style scoped>
.transparent {
  background-color: none;
}
.aspirante__pasoNum {
  float: left;
  font-family: fedrasanslight;    
  margin: 5px;
  color: var(--blue);
  width: 100%;
  vertical-align:top;
}

.aspiranteContainer {
  width: 100%;
  display: block;
  min-height: 67vh;
  overflow-y: auto;
  max-height: 67vh;
}
</style>
