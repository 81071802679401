<template>
  <div class="registro" style="min-height:80vh;">

    <!--
    <div class="subtitleBlueDark fullWidth">
      <label v-on:dblclick="testRegistro()">
      Registro de Datos del Aspirante
      </label>
    </div>
    -->
    <div class="form__panel fullWidth" >

      <div class="col95 center">
        <div class="col30 colSm50 row rowm btnBlue tCenter" @click="showPadreTutor=!showPadreTutor;" style="margin:1px;">
          <small>
            Información del Padre o<br> Tutor
          </small>
        </div>
        <div class="col30 colSm50 row rowm btnBlue tCenter" @click="showSeguridadSocial=!showSeguridadSocial;" style="margin:1px;">
          <small>
            Información de Seguridad Social <br>y Salud
          </small>
        </div>

        <div class="col30 colSm50 row rowm btnBlue tCenter" @click="showMotivacionContacto=!showMotivacionContacto;" style="margin:1px;">
          <small>
            Información de motivacion<br> y contacto
          </small>
        </div>        

      </div>
      <div class="col80" v-if="userStore.fichaRegistroValidada==2" style="text-align:center;background:var(--comment);color:black;margin:0 auto;">
        <label>
        
          {{userStore.observacion}}
        </label>
      </div>

      <div class="col95" v-if="showPadreTutor">
        <div class="blockRow subtitleBlueDark separator">
          Información del Padre o Tutor
          <small style="color:black;">
          (1 de 3)
          </small>
        </div>

        <div class="formCol col48 colSm95">
          <div class="formRow colSm95" >          
            <label class="formLabel" style="color:#025BAD;">        
              <small>
                Parentesco:
              </small>
            </label>
            <span style="min-width:5%;width:5%;"></span>          
            <input-select style="color:black;" ref="parentezcoRef" :optionName="'relación de parentesco'" :whiteBackground="true" 
            :optionsArray="listaParentezco" :propName="'parentezco'"
            :displayFieldname="'c0'" :valFieldName="'c0'"
            ></input-select>                              
          </div>
        </div>   


        <div class="formCol col48 colSm95">
          <div class="formRow colSm95">
              
            <label class="formLabel" style="color:#025BAD;">        
              <small>
                Nombre:
              </small>
            </label>
            <span style="min-width:5%;width:5%;"></span>          
            
            <InputText ref="nombreRef" :propName="'nombre'"  :whiteBackground="false" :mode="'A'" 
            :placeholder="'Nombre'" :capital="true" :maxLength="120" :minLength="2"></InputText>
                          
          </div>
        </div>      
        
        <div class="formCol col48 colSm95">
          <div class="formRow colSm95">
              
            <label class="formLabel" style="color:#025BAD;">
              <small>
                Primer Apellido:
              </small>
            </label>

            <span style="min-width:5%;width:5%;"></span>          
            
            <input-text ref="ap1Ref" :propName="'ap1'"  :whiteBackground="false" :mode="'A'" 
            :placeholder="'Primer Apellido'" :capital="true" :maxLength="120" :minLength="2"></input-text>
                          
          </div>
        </div>


        <div class="formCol col48 colSm95">
          <div class="formRow colSm95">
              
            <label class="formLabel" style="color:#025BAD;">        
              <small>
                Segundo Apellido:
              </small>
            </label>
            <span style="min-width:5%;width:5%;"></span>          
            
            <input-text ref="ap2Ref" :propName="'ap2'"  :whiteBackground="false" :mode="'A'" 
            :placeholder="'Segundo Apellido'" :capital="true" :maxLength="120" :minLength="2"></input-text>
                          
          </div>
        </div>
        
        
        <div class="formCol col48 colSm95">
          <div class="formRow colSm95">
              
            <label class="formLabel" style="color:#025BAD;">        
              <small>
                CURP:
              </small>
            </label>
            <span style="min-width:5%;width:5%;"></span>          
            
            <input-text ref="curpRef" :propName="'curp'"  :whiteBackground="false" :mode="'CURP'" 
            :placeholder="'CURP'" :capital="true" :maxLength="18" :minLength="18"></input-text>
                          
          </div>
        </div>   

        


        <div class="formCol col48 colSm95">
          <div class="formRow colSm95" >          
            <label class="formLabel" style="color:#025BAD;">        
              <small>
                Sexo:{{formData.sexo.data}}
              </small>
            </label>
            <span style="min-width:5%;width:5%;"></span>          
            <input-select style="color:black;" ref="sexoRef" :optionName="'sexo'" :whiteBackground="true" 
            :optionsArray="listaSexo" :propName="'sexo'" 
            :valFieldName="'c2'" 
            :extraPropName="'c2'"
            ></input-select>                              
          </div>
        </div>        


        <div class="formCol col48 colSm95">
          <div class="formRow colSm95" >            
            <label class="formLabel" style="color:#025BAD;">        
              <small>
                Fecha de Nacimiento:
              </small>
            </label>
            <span style="min-width:5%;width:5%;"></span>            
            <input-date style="color:black;" ref="fechaNacRef"
            :propName="'fechaNac'"
            ></input-date>                                
          </div>
        </div>

        
        <div class="formCol col48 colSm95">
          <div class="formRow colSm95" >          
            <label class="formLabel" style="color:#025BAD;">        
              <small>
                Nivel de Estudios:
              </small>
            </label>
            <span style="min-width:5%;width:5%;"></span>          
            <input-select style="color:black;" ref="nivelEstudiosRef" :optionName="'último nivel de estudios obtenido'" :whiteBackground="true" 
            :optionsArray="listaNivelEstudios" :propName="'nivelEstudios'"
            :displayFieldname="'c0'" :valFieldName="'c0'"
            ></input-select>                              
          </div>
        </div>   

        <div class="formCol col48 colSm95">
          <div class="formRow colSm95" >          
            <label class="formLabel" style="color:#025BAD;">        
              <small>
                Ocupación:
              </small>
            </label>
            <span style="min-width:5%;width:5%;"></span>                      

            <input-select-list style="color:black;" 
            ref="ocupacionRef"             
            :optionName="'Ocupación'" 
            :whiteBackground="true" 
            :optionsArray="listaOcupacion" 
            :propName="'ocupacion'"
            :displayFieldname="'c1'" 
            :valFieldName="'c1'"             
            :mode="'AN'" 
            :placeholder="'Ocupación'"             
            :capital="true" 
            :maxLength="65"
            :minLength="2"
            :allowNew="true"
            ></input-select-list>  


          </div>
        </div>   


        <div class="formCol col48 colSm95">
          <div class="formRow colSm95" >          
            <label class="formLabel" style="color:#025BAD;">        
              <small>
                País de Residencia:
              </small>
            </label>
            <span style="min-width:5%;width:5%;"></span>          
            <input-select 
            style="color:black;" 
            ref="paisResRef" 
            :optionName="'país de residencia'" 
            :whiteBackground="true" 
            :optionsArray="listaPaises" 
            :propName="'paisRes'"
            :displayFieldname="'c0'" 
            :valFieldName="'c0'"
            ></input-select>                              
          </div>
        </div>


        <div class="formCol col48 colSm95">
          <div class="formRow colSm95" >          
            <label class="formLabel" style="color:#025BAD;">        
              <small>
                Estado de Residencia:
              </small>
            </label>
            <span style="min-width:5%;width:5%;"></span>          
            <input-select style="color:black;" ref="estadoResRef" :optionName="'estado de residencia'" :whiteBackground="true" 
            :optionsArray="listaEstados" :propName="'estadoRes'" :extraPropName="'estadoRes'" 
            ></input-select>                              
          </div>
        </div>

        <div class="formCol col48 colSm95">
          <div class="formRow colSm95" >          
            <label class="formLabel" style="color:#025BAD;">        
              <small>
                Municipio de Residencia:
              </small>
            </label>
            <span style="min-width:5%;width:5%;"></span>          
            <input-select style="color:black;" ref="munResRef" 
            :optionName="'municipio de residencia'" :whiteBackground="true" 
            :optionsArray="listaMunicipiosRes" :propName="'munRes'" 
            :displayFieldname="'c0'" :valFieldName="'c0'"
            ></input-select>                              
          </div>
        </div>

        <div class="formCol col48 colSm95">
          <div class="formRow colSm95">
              
            <label class="formLabel" style="color:#025BAD;">        
              <small>
                Domicilio:
              </small>
            </label>
            <span style="min-width:5%;width:5%;"></span>          
            
            <InputText ref="domicilioRef" :propName="'domicilio'"  :whiteBackground="false" :mode="'AN'" 
            :placeholder="'Domicilio'" :capital="true" :maxLength="120" :minLength="2"></InputText>
                          
          </div>
        </div>


        <div class="formCol col48 colSm95">
          <div class="formRow colSm95">            
            <label class="formLabel" style="color:#025BAD;">        
              <small>
                Código Postal:
              </small>
            </label>
            <span style="min-width:5%;width:5%;"></span>                    
            <input-number ref="codigoPostalRef" :propName="'codigoPostal'"  :whiteBackground="false"
            :placeholder="'Código Postal'" :capital="true" 
            :minLength="5"
            :maxLength="5"
            :mode="'L'"
            ></input-number>                        
          </div>
        </div>


        <div class="formCol col48 colSm95">
          <div class="formRow colSm95">
              
            <label class="formLabel" style="color:#025BAD;">        
              <small>
                Teléfono:
              </small>
            </label>
            <span style="min-width:5%;width:5%;"></span>          
            
            <InputText :ref="'telefonoRef'" :propName="'telefono'" :mode="'N'" 
            :placeholder="'Telefono'" :capital="true" :maxLength="10" :minLength="10"></InputText>
                          
          </div>
        </div>

        
        

        <div v-if="isInformacionPadreTutorCompleta()" style="margin-top:10px;">          
          <div class="fullWidth row rowm center colSm50 btnBlue tCenter" @click="showPadreTutor=false;showSeguridadSocial=true;">
            Continuar Registro <br>de Información de Seguridad Social y Salud
          </div>
        </div>

      </div>

      <div class="col95" v-if="showSeguridadSocial">
        <div class="blockRow subtitleBlueDark separator">
          Información de Seguridad Social y Salud
          <small style="color:black;">
          (2 de 3)
          </small>
        </div>
        <div class="formCol col48 colSm95">
          <div class="formRow colSm95" >          
            <label class="formLabel" style="color:#025BAD;">        
              <small>
                Tipo de Seguro Social con el que cuenta:
              </small>
            </label>
            <span style="min-width:5%;width:5%;"></span>          
            <input-select-list style="color:black;" 
            :ref="'seguroSocialRef'" 
            :optionName="'tipo de seguro social'" 
            :whiteBackground="true" 
            :optionsArray="listaSeguroSocial" 
            :propName="'seguroSocial'"
            :displayFieldname="'c1'" 
            :valFieldName="'c1'"
            :extraPropName="'seguroSocial'" 
            :mode="'A'" 
            :placeholder="'Tipo de Seguro Social'" 
            :capital="true" 
            :maxLength="120" 
            :minLength="2"
            :allowNew="true"
            ></input-select-list>                              
          </div>
        </div>

        <div class="formCol col48 colSm95">
          <div class="formRow colSm95">            
            <label class="formLabel" style="color:#025BAD;">        
              <small>
                Folio de Seguro Social:
              </small>
            </label>
            <span style="min-width:5%;width:5%;"></span>                    
            <input-text ref="folioSeguroSocialRef" :propName="'folioSeguroSocial'"  :whiteBackground="false"
            :placeholder="'Folio de Seguro Social'" :capital="true" 
            :minLength="2"
            :maxLength="30"
            :mode="'NA'"
            ></input-text>                        
          </div>
        </div>
        
        <div class="formCol col48 colSm95">
          <div class="formRow colSm95" >          
            <label class="formLabel" style="color:#025BAD;">        
              <small>
                Padece de enfermedades crónicas:
              </small>
            </label>
            <span style="min-width:5%;width:5%;"></span>          
            <input-select-list style="color:black;" 
            :ref="'enfermedadesCronicasRef'" 
            :optionName="'tipo de enfermedad crónica'" 
            :whiteBackground="true" 
            :optionsArray="listaEnfermedadesCronicas" 
            :propName="'enfermedadesCronicas'"
            :displayFieldname="'c1'" :valFieldName="'c1'"
            :mode="'A'" 
            :placeholder="'Enfermedad Crónica que Padece'" 
            :capital="true" 
            :maxLength="85" 
            :minLength="2"
            :allowNew="true"
            ></input-select-list>                              
          </div>
        </div>


        <div class="formCol col48 colSm95">
          <div class="formRow colSm95" >          
            <label class="formLabel" style="color:#025BAD;">        
              <small>
                Padece de Alergias:
              </small>
            </label>
            <span style="min-width:5%;width:5%;"></span>          
            <input-select-list style="color:black;" 
            ref="alergiasRef" 
            :optionName="'tipo de alergia'" 
            :whiteBackground="true" 
            :optionsArray="listaAlergias" 
            :propName="'alergias'"
            :displayFieldname="'c1'" :valFieldName="'c1'"
            :mode="'A'" 
            :placeholder="'Padece de Alergias'" 
            :capital="true" 
            :maxLength="85" 
            :minLength="2"
            :allowNew="true"
            ></input-select-list>                              
          </div>
        </div>

        <div class="formCol col48 colSm95">
          <div class="formRow colSm95" >          
            <label class="formLabel" style="color:#025BAD;">        
              <small>
                ¿Toma algún medicamento?:
              </small>
            </label>
            <span style="min-width:5%;width:5%;"></span>          
            <input-select-list style="color:black;" 
            ref="medicamentoRef" 
            :optionName="'tipo de medicamento'" 
            :whiteBackground="true" 
            :optionsArray="listaMedicamentos" 
            :propName="'medicamentos'"
            :displayFieldname="'c1'" :valFieldName="'c1'"
            :mode="'A'" 
            :placeholder="'¿Toma algún medicamento?'" 
            :capital="true" 
            :maxLength="85" 
            :minLength="2"
            :allowNew="true"
            ></input-select-list>                              
          </div>
        </div>


        <div class="formCol col48 colSm95">
          <div class="formRow colSm95">
            <label class="formLabel" style="color:#025BAD;">        
              <small>
                Tipo de Sangre:
              </small>
            </label>
            <span style="min-width:5%;width:5%;"></span>          
            <input-select style="color:black;" 
            ref="tipoSangreRef" 
            :optionName="'tipo de sangre'" 
            :whiteBackground="true" 
            :optionsArray="listaTipoSangre" :propName="'tipoSangre'"            
            ></input-select>                              
          </div>
        </div>        


        <div v-if="isInformacionSeguridadSocialCompleta()" style="margin-top:10px;">
          <div class="col10 row rowm center colSm10 btnBlue tCenter" 
          @click="showPadreTutor=true;showSeguridadSocial=false;" style="vertical-align:top;">
            <i class="bi bi-arrow-left-square"></i>
            <br>
            Atrás            
          </div>
          <div class="col90 row rowm center colSm50 btnBlue tCenter" @click="showSeguridadSocial=false;showMotivacionContacto=true;" style="vertical-align:top;">
            Continuar registro Información <br>Motivación y Contacto
          </div>
        </div>

      </div>

      <div class="col95" v-if="showMotivacionContacto">
        <div class="blockRow subtitleBlueDark separator">
          Información de motivacion y contacto
          <small style="color:black;">
          (3 de 3)
          </small>
        </div>
      

        <div class="formCol col48 colSm95">
          <div class="formRow colSm95" >          
            <label class="formLabel" style="color:#025BAD;">        
              <small>
                Principal Motivo para ingresar a esta Escuela:
              </small>
            </label>
            <span style="min-width:5%;width:5%;"></span>          
            <input-select-list style="color:black;" 
            ref="motivoRef" 
            :optionName="'Motivo para ingresar a esta escuela'" 
            :whiteBackground="true" 
            :optionsArray="listaMotivos" 
            :propName="'motivo'"
            :displayFieldname="'c0'" :valFieldName="'c0'"
            :mode="'A'" 
            :placeholder="'Motivo para ingresar a esta escuela'" 
            :capital="true" 
            :maxLength="120" 
            :minLength="2"
            :allowNew="true"
            ></input-select-list>                              
          </div>
        </div>


        <div class="formCol col48 colSm95">
          <div class="formRow colSm95" >          
            <label class="formLabel" style="color:#025BAD;">        
              <small>
                ¿Cómo se enteró de esta escuela?:
              </small>
            </label>
            <span style="min-width:5%;width:5%;"></span>          
            <input-select-list style="color:black;" 
            ref="medioComunicacionRef" 
            :optionName="'Medio por el que conociste esta escuela'" 
            :whiteBackground="true" 
            :optionsArray="listaMedios" 
            :propName="'medioComunicacion'"
            :displayFieldname="'c0'" :valFieldName="'c0'"            
            :mode="'A'" 
            :placeholder="'Medio por el que conociste esta escuela'" 
            :capital="true" 
            :maxLength="120" 
            :minLength="2"
            :allowNew="true"
            ></input-select-list>                              
          </div>
        </div>

        <div v-if="isInformacionMotivacionContactoCompleta()" style="margin-top:10px;">

          <div class="col10 row rowm center colSm10 btnBlue tCenter" 
          @click="showSeguridadSocial=true;showMotivacionContacto=false;" style="vertical-align:top;">
            <i class="bi bi-arrow-left-square"></i>
            <br>
            Atrás            
          </div>

          <button class="col90 row rowm center colSm50 btnBlue tCenter" v-on:click="sendRegistrar()"
          v-if="(
              isInformacionPadreTutorCompleta()&&
              isInformacionSeguridadSocialCompleta()&&
              isInformacionMotivacionContactoCompleta()              
            )"
          >
            
            <i class="bi bi-pencil-square block"></i>
            Registrar
          </button>
          <button class="col90 row rowm center colSm50 btnDefault tCenter" v-on:click="mostrarMensajeRegistrar()" v-else>
            <i class="bi bi-pencil-square block"></i>
            Registrar
          </button>
        </div>

      </div>      

<!--
      <div>
          {{formData}}
      </div>
-->
      
      <div class="col90" style="min-height:40px;">
        <span style="min-height:20px;"></span>
      </div>      

      


    </div>

    <modal title="Registro" v-if="showModal" v-on:closeModalEv="closeModal()" style="display:block;left:10vw;top:10vh;height:30vh;">              
      <div style="text-align:center;overflow-y:auto;">
        <h3 style="color:black;">
          {{modalMessage}}          
        </h3>        
      </div>
      
    </modal>


  </div>
</template>

<script>

import InputText from '../Inputs/InputText.vue';
import InputNumber from '../Inputs/InputNumber.vue';
import InputSelect from '../Inputs/InputSelect.vue';
import InputSelectList from '../Inputs/InputSelectList.vue';
import InputDate from '../Inputs/InputDate.vue';

import Modal from '../Modal/Modal.vue';
import { mapStores } from 'pinia';
import { useUserStore } from '../../store/user';


export default {
  name: 'FichaRegistroAspirante',

  components:{InputText,InputSelect,InputNumber,InputDate,InputSelectList,Modal},
  data: function(){
    return{
      modalMessage:'',
      showModal:false,
      seguroSocial:'',            
      showPadreTutor:false,
      showSeguridadSocial:false,
      showMotivacionContacto:false,
      
      listaOpcionesSiNo:[
        {c1:'Si',c2:'1'},
        {c1:'No',c2:'0'}
      ],
      listaSexo:[
        {c1:'HOMBRE',c2:'H'},
        {c1:'MUJER',c2:'M'}
      ],
      listaTipoSangre:[
        {c1:'A+' },
        {c1:'A-' },
        {c1:'B+' },
        {c1:'B-' },
        {c1:'AB+'},
        {c1:'AB-'},
        {c1:'O+' },
        {c1:'O-' }
      ],
      listaMotivos:[],
      listaMedios:[],
      listaOcupacion:[],
      formData:{
        'parentezco':{data:"",state:0},
        'nombre':{data:"",state:0},
        'ap1':{data:"",state:0},
        'ap2':{data:"",state:0},                
        'curp':{data:"",state:0},        
        'sexo':{data:"",state:0},     
        'fechaNac':{data:"",state:0},
        'paisRes':{data:"",state:0},
        'estadoRes':{data:"",state:0},
        'munRes':{data:"",state:0},     
        'nivelEstudios':{data:"",state:0},          
        'domicilio':{data:"",state:0},
        'codigoPostal':{data:"",state:0},
        'ocupacion':{data:"",state:0},                            
        'telefono':{data:"",state:0},

        'seguroSocial':{data:"",state:0},
        'folioSeguroSocial':{data:"",state:0},
        'alergias':{data:"",state:0},
        'enfermedadesCronicas':{data:"",state:0},
        'medicamentos':{data:"",state:0},
        'tipoSangre':{data:"",state:0},

        'motivo':{data:"",state:0},
        'medioComunicacion':{data:"",state:0},                      
      },

      email:'',
      nombre:'',
      ap1:'',
      ap2:'',
      curp:'',
      telefono:'',   
      telefonoEmergencia:'',               
      sexo:'Seleccione el sexo',
      paisRes:'Seleccione el país',
      estadoNac:'Seleccione el estado',
      munNac:'Seleccione el municipio',
      estadoRes:'Seleccione el estado',
      munRes:'Seleccione el municipio',

      estadoSec:'Seleccione el estado',
      munSec:'Seleccione el municipio',
      secundaria:'Seleccione la Escuela',

      estadoEnBachilleratoAnterior:'Seleccione la opción',

      estadoBacAnt:'Seleccione el estado',
      munBacAnt:'Seleccione el municipio',
      bachilleratoAnterior:'Seleccione la Escuela',
      situacionEscolar:'Seleccione la opción',
      
      domicilio:'',
      codigoPostal:'',

      showGoToIniciarSesion:false,
      procesando:0,

      relacionChedraui:'Seleccione la opción',
      nombreFamiliar:'',
      sucursal:'Seleccione la sucursal',
      puesto:'Seleccione la opción',
      folioCertificadoSec:'',
      

      listaMedicamentos:[],
      listaAlergias:[],
      listaEnfermedadesCronicas:[],
      
      listaParentezco:[
        {c0:'PADRE'},
        {c0:'MADRE'},
        {c0:'TUTOR'}
      ],
      listaNivelEstudios:[
        {c0:'SIN GRADO DE ESTUDIOS'},
        {c0:'PREESCOLAR'},
        {c0:'PRIMARIA'},
        {c0:'SECUNDARIA'},
        {c0:'PREPARATORIA'},
        {c0:'LICENCIATURA'},
        {c0:'MAESTRÍA'},
        {c0:'DOCTORADO'},
      ],
      listaPaises:[],
      listaEstados:[],      
      listaMunicipiosRes:[],        
      listaSeguroSocial:[]
    };

  },

  watch:{
    
    estadoNac:function(){
      this.getListaMunicipios(this.estadoNac,'nac');
    },
    estadoRes:function(){
      this.getListaMunicipios(this.estadoRes,'res');
    },

  },
  created:function(){

    this.getListaCatalogoEstado();
    this.getListaCatalogoPais(); 
    this.getListaCatalogoSeguroSocial();
    this.getListaCatalogoOcupacion();
    this.getListaCatalogoAlergias();
    this.getListaCatalogoEnfermedades();
    this.getListaCatalogoMedicamentos();
    this.getListaCatalogoMedioComunicacion();
    this.getListaCatalogoMotivos();

    this.getDatosFichaRegistro();


  },
  mounted:function(){

    /*
    if(this.userStore.datosRegistrados==1){
      this.showPadreTutor=true;
    }
    */
    

    

  },
  methods:{


    getDatosFichaRegistro(){
      console.log("getDatosFichaRegistro()");
      let data={
        idp:''+this.userStore.idp
      };
      this.$root.sendPOSTRequest(
        "https://webdevworks.xyz/scLC/api/v1/datosFichaRegistro/",
        data,
        this.onSuccessGetDatosFichaRegistro,
        this.onErrorGetDatosFichaRegistro
        );
    },
    onSuccessGetDatosFichaRegistro(data){
      console.log("Data:");
      console.log(data.data.data);          
      this.loadData(data.data.data);            
    },
    onErrorGetDatosFichaRegistro(data){
      console.log(data);
    },
    loadData(data){

      this.formData.nombre.data              =data.c0;
      this.formData.ap1.data                 =data.c1;
      this.formData.ap2.data                 =data.c2;
      this.formData.parentezco.data          =data.c3;
      this.formData.fechaNac.state           =1;
      this.formData.fechaNac.data            =data.c4;
      this.formData.curp.data                =data.c5;
      this.formData.sexo.data                =data.c6;
      this.formData.ocupacion.data           =data.c7;
      this.formData.paisRes.data             =data.c8;
      this.formData.estadoRes.data           =data.c9.toUpperCase();
      this.formData.munRes.data              =data.c10.toUpperCase();
      this.formData.domicilio.data           =data.c11;
      this.formData.codigoPostal.data        =data.c12;
      this.formData.telefono.data            =data.c13;
      this.formData.nivelEstudios.data       =data.c14;
      this.formData.seguroSocial.data        =data.c15;
      this.formData.folioSeguroSocial.data   =data.c16;
      this.formData.enfermedadesCronicas.data=data.c17.toUpperCase();
      this.formData.alergias.data            =data.c18;
      this.formData.medicamentos.data        =data.c19;
      this.formData.tipoSangre.data          =data.c20;
      this.formData.motivo.data              =data.c21;
      this.formData.medioComunicacion.data   =data.c22;

      this.showPadreTutor=true;

    },

    closeModal(){
      this.showModal=false;
      this.$emit()
    },
    sendRegistrarDemo(){

      this.showModal=true;
      this.modalMessage='Registro de información actualizado exitosamente';

    },
    sendRegistrar(){
      console.log("sendRegistrar()");
      let data={
        idp              :''+this.userStore.idp,
        parentezco       :''+this.formData.parentezco.data,
        nombre           :''+this.formData.nombre.data,
        ap1              :''+this.formData.ap1.data,
        ap2              :''+this.formData.ap2.data,
        curp             :''+this.formData.curp.data,
        sexo             :''+this.formData.sexo.data,
        fechaNac         :''+this.formData.fechaNac.data,
        ocupacion        :''+this.formData.ocupacion.data,
        nivelEstudios    :''+this.formData.nivelEstudios.data,
        pais             :''+this.formData.paisRes.data,        
        estado           :''+this.formData.estadoRes.data,        
        municipio        :''+this.formData.munRes.data,        
        domicilio        :''+this.formData.domicilio.data,        
        codigoPostal     :''+this.formData.codigoPostal.data,        
        tel              :''+this.formData.telefono.data,        
        tipoSangre       :''+this.formData.tipoSangre.data,        
        enfermedades     :''+this.formData.enfermedadesCronicas.data,        
        alergias         :''+this.formData.alergias.data,        
        medicamentos     :''+this.formData.medicamentos.data,        
        seguroSocial     :''+this.formData.seguroSocial.data,        
        motivo           :''+this.formData.motivo.data,
        medioComunicacion:''+this.formData.medioComunicacion.data,
        folioSeguroSocial:''+this.formData.folioSeguroSocial.data
      };
      this.$root.sendPOSTRequest(
        "https://webdevworks.xyz/scLC/api/v1/registrarDatosFichaRegistro/",
        data,
        this.onSuccessSendRegistrar,
        this.onErrorSendRegistrar
        );
    },
    onSuccessSendRegistrar(data){
      console.log("Data:");
      console.log(data);  
      if(data.update1=='ok'&&data.update2=='ok'){
        
        this.showModal=true;
        this.modalMessage='Registro de información actualizado exitosamente.';          
        this.userStore.fichaRegistroRegistrada=parseInt(data.fichaRegistroRegistrada);
                
      }
      
      
    },
    onErrorSendRegistrar(data){
      console.log(data);
    },


    mostrarMensajeRegistrar(){
      alert('Debes llenar correctamente todos los campos antes de poder actualizar sus datos');
    },

    isInformacionPadreTutorCompleta(){

      return (
      this.formData.parentezco.state   ==1&&
      this.formData.nombre.state       ==1&&
      this.formData.ap1.state          ==1&&
      this.formData.ap2.state          ==1&&
      this.formData.curp.state         ==1&&
      this.formData.sexo.state         ==1&&      
      this.formData.fechaNac.state     ==1&&
      this.formData.nivelEstudios.state==1&&
      this.formData.estadoRes.state    ==1&&
      this.formData.munRes.state       ==1&&
      this.formData.domicilio.state    ==1&&
      this.formData.codigoPostal.state ==1      
      );

    },

    isInformacionSeguridadSocialCompleta(){

      return (
      this.formData.seguroSocial.state        ==1&&
      this.formData.folioSeguroSocial.state   ==1&&
      this.formData.alergias.state            ==1&&
      this.formData.enfermedadesCronicas.state==1&&
      this.formData.medicamentos.state        ==1&&
      this.formData.tipoSangre.state          ==1
      );

    },

    isInformacionMotivacionContactoCompleta(){
      
        return (
          this.formData.motivo.state           ==1&&
          this.formData.medioComunicacion.state==1
        );
      
    },    
    
    getListaCatalogoPais(){
      console.log("getListaCatalogoPais()");
      let data={        
      };
      this.$root.sendPOSTRequest(
        "https://webdevworks.xyz/scLC/api/v1/definicionPaises/",
        data,
        this.onSuccessGetListaCatalogoPais,
        this.onErrorGetListaCatalogoPais
        );
    },
    onSuccessGetListaCatalogoPais(data){
      console.log("Data:");
      console.log(data.data.data);            
      this.setListaPaises(data.data.data);
    },
    onErrorGetListaCatalogoPais(data){
      console.log(data);
    },
    setListaPaises(data){
      this.listaPaises=data;
    },


    getListaCatalogoEstado(){
      console.log("getListaCatalogoEstado()");
      let data={        
      };
      this.$root.sendPOSTRequest(
        "https://webdevworks.xyz/scLC/api/v1/definicionEstados/",
        data,
        this.onSuccessGetListaCatalogoEstado,
        this.onErrorGetListaCatalogoEstado
        );
    },
    onSuccessGetListaCatalogoEstado(data){
      console.log("Data:");
      console.log(data.data.data);            
      this.setListaEstados(data.data.data);
    },
    onErrorGetListaCatalogoEstado(data){
      console.log(data);
    },
    setListaEstados(data){
      this.listaEstados=data;
    },

    getListaMunicipios(estado,op){
      console.log("getListaMunicipios()");
      let data={
        estado:''+estado
      };
      this.$root.sendPOSTRequestParam(
        "https://webdevworks.xyz/scLC/api/v1/definicionMunicipios/",
        data,
        this.onSuccessGetListaMunicipios,
        this.onErrorGetListaMunicipios,
        op
        );
    },
    onSuccessGetListaMunicipios(data,op){
      console.log("Data:");
      console.log(data.data.data);            
      this.setListaMunicipios(data.data.data,op);
    },
    onErrorGetListaMunicipios(data){
      console.log(data);
    },
    setListaMunicipios(data,op){
      if(op=='nac'){
        this.listaMunicipiosNac=data;
      }
      if(op=='res'){
        this.listaMunicipiosRes=data;
      }
      
    },

    getListaCatalogoSeguroSocial(){
      console.log("getListaCatalogoSeguroSocial()");
      let data={           
      };
      this.$root.sendPOSTRequest(
        "https://webdevworks.xyz/scLC/api/v1/definicionSeguroSocial/",
        data,
        this.onSuccessGetListaCatalogoSeguroSocial,
        this.onErrorGetListaCatalogoSeguroSocial
        );
    },
    onSuccessGetListaCatalogoSeguroSocial(data){
      console.log("Data:");
      console.log(data.data.data);            
      this.setListaCatalogoSeguroSocial(data.data.data);
    },
    onErrorGetListaCatalogoSeguroSocial(data){
      console.log(data);
    },
    setListaCatalogoSeguroSocial(data){
      this.listaSeguroSocial=data;
    },


    getListaCatalogoOcupacion(){
      console.log("getListaCatalogoOcupacion()");
      let data={           
      };
      this.$root.sendPOSTRequest(
        "https://webdevworks.xyz/scLC/api/v1/definicionOcupacion/",
        data,
        this.onSuccessGetListaCatalogoOcupacion,
        this.onErrorGetListaCatalogoOcupacion
        );
    },
    onSuccessGetListaCatalogoOcupacion(data){
      console.log("Data:");
      console.log(data.data.data);            
      this.setListaCatalogoOcupacion(data.data.data);
    },
    onErrorGetListaCatalogoOcupacion(data){
      console.log(data);
    },
    setListaCatalogoOcupacion(data){
      this.listaOcupacion=data;
    },

    getListaCatalogoMedicamentos(){
      console.log("getListaCatalogoMedicamentos()");
      let data={           
      };
      this.$root.sendPOSTRequest(
        "https://webdevworks.xyz/scLC/api/v1/definicionMedicamentos/",
        data,
        this.onSuccessGetListaCatalogoMedicamentos,
        this.onErrorGetListaCatalogoMedicamentos
        );
    },
    onSuccessGetListaCatalogoMedicamentos(data){
      console.log("Data:");
      console.log(data.data.data);            
      this.setListaCatalogoMedicamentos(data.data.data);
    },
    onErrorGetListaCatalogoMedicamentos(data){
      console.log(data);
    },
    setListaCatalogoMedicamentos(data){
      this.listaMedicamentos=data;
    },

    getListaCatalogoAlergias(){
      console.log("getListaCatalogoAlergias()");
      let data={           
      };
      this.$root.sendPOSTRequest(
        "https://webdevworks.xyz/scLC/api/v1/definicionAlergias/",
        data,
        this.onSuccessGetListaCatalogoAlergias,
        this.onErrorGetListaCatalogoAlergias
        );
    },
    onSuccessGetListaCatalogoAlergias(data){
      console.log("Data:");
      console.log(data.data.data);            
      this.setListaCatalogoAlergias(data.data.data);
    },
    onErrorGetListaCatalogoAlergias(data){
      console.log(data);
    },
    setListaCatalogoAlergias(data){
      this.listaAlergias=data;
    },

    getListaCatalogoEnfermedades(){
      console.log("getListaCatalogoEnfermedades()");
      let data={           
      };
      this.$root.sendPOSTRequest(
        "https://webdevworks.xyz/scLC/api/v1/definicionEnfermedades/",
        data,
        this.onSuccessGetListaCatalogoEnfermedades,
        this.onErrorGetListaCatalogoEnfermedades
        );
    },
    onSuccessGetListaCatalogoEnfermedades(data){
      console.log("Data:");
      console.log(data.data.data);            
      this.setListaCatalogoEnfermedades(data.data.data);
    },
    onErrorGetListaCatalogoEnfermedades(data){
      console.log(data);
    },
    setListaCatalogoEnfermedades(data){
      this.listaEnfermedadesCronicas=data;
    },

    getListaCatalogoMotivos(){
      console.log("getListaCatalogoMotivos()");
      let data={           
      };
      this.$root.sendPOSTRequest(
        "https://webdevworks.xyz/scLC/api/v1/definicionMotivos/",
        data,
        this.onSuccessGetListaCatalogoMotivos,
        this.onErrorGetListaCatalogoMotivos
        );
    },
    onSuccessGetListaCatalogoMotivos(data){
      console.log("Data:");
      console.log(data.data.data);            
      this.setListaCatalogoMotivos(data.data.data);
    },
    onErrorGetListaCatalogoMotivos(data){
      console.log(data);
    },
    setListaCatalogoMotivos(data){
      this.listaMotivos=data;
    },

    getListaCatalogoMedioComunicacion(){
      console.log("getListaCatalogoMedioComunicacion()");
      let data={           
      };
      this.$root.sendPOSTRequest(
        "https://webdevworks.xyz/scLC/api/v1/definicionMedioComunicacion/",
        data,
        this.onSuccessGetListaCatalogoMedioComunicacion,
        this.onErrorGetListaCatalogoMedioComunicacion
        );
    },
    onSuccessGetListaCatalogoMedioComunicacion(data){
      console.log("Data:");
      console.log(data.data.data);            
      this.setListaCatalogoMedioComunicacion(data.data.data);
    },
    onErrorGetListaCatalogoMedioComunicacion(data){
      console.log(data);
    },
    setListaCatalogoMedioComunicacion(data){
      this.listaMedios=data;
    },


  },
  computed:{    
    ...mapStores(useUserStore),
  },
  props: {
    
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
