<template>
  <div>
    
    <!--
    <div class="subtitleBlueDark">
      <label>
        Verificar Correo
      </label>
    </div>
    -->
    <div class="col90 center">
      
      <div style="margin-bottom: 50px;text-align:center;">
        <div class="subtitleBlueDark">
          <label>
            Estado de dirección de correo electrónico:
          </label>
        </div>
        <div style="margin-top:20px;">
          {{estado}}          
          <i class="bi bi-check-circle-fill" v-if="estado=='Verificado'" style="color:var(--correct);"></i>          
          <i class="bi bi-clock-fill" v-else></i>
        </div>
        
      </div>
      <div class="fullWidth">
        <button class="btnBlue fullWidth" v-if="estado=='Pendiente'&&clickeado==0" v-on:click="clickeado=1;sendCorreoVerificacion()">
          Enviar mensaje de verificación
        </button>
      </div>
      <modal title="Verificar Correo" v-if="showModal" v-on:closeModalEv="closeModal()" style="display:block;left:10vw;top:10vh;height:30vh;">              
      <div style="text-align:center;">
        <h3 style="color:black;">
          {{modalMessage}}
        </h3>
      </div>
    </modal>

    </div>

  </div>
</template>

<script>

import { mapStores } from 'pinia';
import { useUserStore } from '../../store/user';
import Modal from '../Modal/Modal.vue';


export default {
  name: 'VerificarCorreo',
  
  data:function(){
    return{
      estado:'Pendiente',
      showModal:false,
      modalMessage:'',
      clickeado:0
    };
  },
  components:{Modal},
  mounted:function(){

    if(this.userStore.correoVerificado==1){
      this.estado='Verificado';
    }

  },
  methods:{


    sendLogOut(){
        console.log("sendLogOut()");
        let data={
          id:''+this.userStore.idp         
        };

        this.$root.sendPOSTRequest(
          "https://webdevworks.xyz/scLC/api/v1/registrarLogOut/",
          data,
          this.onSuccessSendLogOut,
          this.onErrorSendLogOut
          );
      },
      onSuccessSendLogOut(data){

        console.log("onSuccessSendLogOut");
        console.log("Data success:");
        console.log(data);         
        //console.log(typeof data.data.c0);         
        //console.log(data.data);         
        //console.log(data.data.data);   
        this.$root.changeRoute('/');
        
      },
      onErrorSendLogOut(data){
        console.log("onErrorSendLogOut");
        console.log(data);
      },

    closeModal(){

      this.showModal=false;
      
      this.sendLogOut();

    },

    sendCorreoVerificacion(){
      console.log("sendCorreoVerificacion()");
      let data={    
        correo:''+this.userStore.correo
      };
      this.$root.sendPOSTRequest(
        "https://webdevworks.xyz/scLC/api/v1/enviarCorreoVerificacion/",
        data,
        this.onSuccessSendCorreoVerificacion,
        this.onErrorSendCorreoVerificacion
        );
    },
    onSuccessSendCorreoVerificacion(data){
      console.log("Data:");
      console.log(data);      
      if(data.validationKeyGen=="ok"&&data.sendStatus=='sent'){

        this.showModal=true;
        this.modalMessage="Mensaje Enviado Exitosamente a: "+this.userStore.correo+". \nBusca el mensaje de verificación en la bandeja de tu correo electrónico. En caso de no encontrarlo ahi, por favor busca en la carpeta de 'SPAM' o de 'Correo no Deseado'"

      }else{
        this.showModal=true;
        this.modalMessage="Ocurrio un error al enviar el mensaje a la dirección de correo electrónico:"+this.userStore.correo+" Por favor intenta nuevamente";
        this.clickeado=0;
      }
      //console.log(data.data.data);                  
    },
    onErrorSendCorreoVerificacion(data){
      console.log(data);
    },

    enviarMensaje(){
      window.open("https://webdevworks.xyz/liceo/mailer/verificarCorreo.php?correo="+this.userData.correo);
      window.close();
    }

  },
  computed:{
    ...mapStores(useUserStore),    
    sys(){

      return this.$store.state.sys;

    }

  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
