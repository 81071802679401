<template>
  <div class="navBar blueDark">        
    <div class="col95" style="background:none;">
      
      <div class="col50 row tLeft" style="background:none;">
        <div class="block tCenter">
          <h3 class="navTitle" style="margin-bottom:3px;margin-top:10px;">
            Portal de Servicios 
          </h3>
        </div>
        <div class="block tCenter" style="padding-bottom:10px;">
          <h3 class="navTitle" style="margin-top:3px;">
            &nbsp;LAO    
          </h3>
        </div>

      </div>

      <div class="col30 row left" @click="toHome()">
        <img class="iconBrand" src="./../../../public/img/logobnuevo2.png">
      </div>

      <div class="col15 row right tRight" style="min-height:50px;margin-top:10px;" v-if="userStore.login==1">
        <button class="btnBlueDark">
          <i class="bi bi-list" style="font-size:200%;"></i>
        </button>
      </div>
      
      
      
    </div>
  </div>
</template>

<script>
  
  import { mapStores } from 'pinia'
  import { useUserStore } from '../../store/user';
  


  export default {
  
    name: 'Navbar',
    props: {
      msg: String
    },
    methods:{

      toHome(){
        this.$root.changeRoute("/");
      }

    },
    computed:{
    
    ...mapStores(useUserStore)

    },
    
  }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
