<template>
  <div>
    Pagina no encontrada
  </div>
</template>

<script>
export default {
  name: 'NotFound',
  props: {
    msg: String
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
