<template>
  <div>
            
    <div class="profileInfo">

      <div class="imagContainer" style="overflow:hidden;">
        
          <img class="profileImage" 
          src="img/user.jpg" 
          >
        
        <!--
        <button class="fullWidth btn orange" 
        style="position:relative;top:-15px;left:0px;z-index:10;max-height:5vh;border-radius:0px;" 
        @click="showCargarBtn=0;" v-if="showCargarBtn==1">
        <i class="bi bi-upload"></i>
        </button>
        -->
      </div>
      
      <div class="profileData">      

        <div class="col95" @dblclick="showData=!showData;">
          {{userStore.plantel}}
        </div>  

        <div class="col95">
          {{userStore.carrera}}
        </div>

        <div class="col95" style="color:cyan;">
          {{userStore.horario}}
        </div>
        
        <div class="col95" style="color:var(--defaultDark);">
          {{userStore.numeroControl}}
        </div>
        
        <div class="col95">
          {{userStore.nombre+' '+userStore.ap1+' '+userStore.ap2}}
        </div>
      
        <div class="col95" style="color:black;word-wrap: break-word;">
          {{userStore.correo}}         
        </div>

        <div class="col95" style="color:white;" v-if="showData">
          {{userStore.idp}}
        </div>
        


        <!--
        <div class="col95">
          {{userStore.status}}
        </div>
        <div class="col95">
          {{userStore.sistema}}
        </div>
        -->

      </div>
            
    </div>
    <div class="menuArea">

      <div class="menuPrincipalExp">
        <div class="fullWidth"  style="margin-bottom:10px;" v-if="!expandedMenu">
          <div class="fontBlack titleBlack row">
            Menú Principal
          </div>          
          <div class="fontBlack row right">                        
            <button class="btn blueDark" @click="sendLogOut()">
              Cerrar Sesión              
            </button>
            Titulo:
              <input type="text" v-model="notificacionTitle">
            Cuerpo:
              <input type="text" v-model="notificacionMsg">
            URL:
              <input type="text" v-model="notificacionURL">
              <button class="btnOrange" @click="sendNotificacionesGenerales()">
                Enviar Notificacion General
              </button>

            <button class="btnOrange" @click="registerServiceWorker()">
              Suscribirse a Notificaciones
            </button>
            <div>
              dataStr:
              <textarea v-model="dataStr"></textarea>
            </div>
            

            <button class="btn blueDark">
              Avisos/Mensajes
              <span style="background:orangered;color:black;margin:3px;border-radius:50px 50px;padding:3px;">0</span>
            </button>            
          </div>        
        </div>
        <div class="fullWidth">
          <div>
            <menu-aspirante v-on:expandMenuEv="expandMenu"></menu-aspirante>
          </div>
        </div>
      </div>
    <!--
      <div class="avisos" v-if="!expandedMenu">
        <div class="fullWidth">
          <div class="fontBlack titleBlack">            
            Avisos/Mensajes
          </div>
        </div>
        <div class="fullWidth">
          <div class="orange">
            mensajes
          </div>
        </div>
      </div>
    -->
    </div>


  </div>
</template>

<script>
  import { mapStores } from 'pinia'
  import { useUserStore } from '../store/user';
  import MenuAspirante from './Aspirante/MenuAspirante.vue';
  


  export default {
  
    name: 'Home',
    components:{MenuAspirante},

    props: {
      msg: String
    },
    data:function(){
      return{
        notificacionTitle:'',
        notificacionMsg:'',
        notificacionURL:'https://webdevworks.xyz/liceo/preinscripcion/',

        showCargarBtn:1,
        expandedMenu:false,
        showData:false,
        closeStatus:'',
        dataStr:'',

      };
    },
    created:function(){
      window.addEventListener('beforeunload', (event) => {
        //window.open("https://www.w3schools.com",'_blank');
        //event.preventDefault();
        //this.closeStatus='Cerrando';
        //window.open("https://www.w3schools.com");
        //event.returnValue = '';
        this.sendLogOut();
        
        //alert("cerrando");    
        console.log(event);
        console.log("cerrando");
        
      });
    },
    methods:{


      sendNotificacionesGenerales(){
        console.log("sendNotificacionesGenerales()");
        let data={
          title:''+this.notificacionTitle,
          mensaje:''+this.notificacionMsg,
          url:''+this.notificacionURL          
        };

        this.$root.sendPOSTRequest(
          "https://webdevworks.xyz/wpLC/enviarMensajeGeneral/",
          data,
          this.onSuccessNotificacionesGenerales,
          this.onErrorNotificacionesGenerales
          );
      },
      onSuccessNotificacionesGenerales(data){

        console.log("onSuccessNotificacionesGenerales");
        console.log("Data success:");
        console.log(data);         
        //console.log(typeof data.data.c0);         
        console.log(data.data);         
        console.log(data.data.data);   
        
      },
      onErrorNotificacionesGenerales(data){
        console.log("onErrorNotificacionesGenerales");
        console.log(data);
      },






      initIDDISP(idDisp){
        
        if(localStorage.iddisp){
            let estadoLoaded=localStorage.iddisp;
            
            console.log("Estado iddisp:");
            console.log(localStorage.iddisp);
            console.log("estadoLoaded.iddisp="+estadoLoaded.iddisp);
            console.log("new iddisp="+idDisp);

            if(estadoLoaded.iddisp!=idDisp&&idDisp!='-'){
              let iddisp=idDisp;
              
              localStorage.iddisp=iddisp;
              
              this.userStore.iddisp=localStorage.iddisp;
              console.log("idDisp:");
              console.log(localStorage.iddisp);
              
            }
            

        }else{
          let iddisp='-';
          
          localStorage.iddisp=iddisp;
          this.userStore.iddisp=localStorage.iddisp;
          console.log("iddisp Creado");          
        }

    },



      registerServiceWorker(){
        
        if('serviceWorker' in navigator){
            this.send().catch(err=>console.error(err));
            console.log("SW Registered");
        }
          
      },
  
      //Register SW, Register Push, Send Push
  
      async send(){
          let rootStr="/";
          rootStr="/liceo/betapreinscripcion/";
          //rootStr="/liceo/preinscripcion/";

          console.log('Registering Service Worker');
          const registration = 
          await navigator.serviceWorker.register(rootStr+'sw.js',
          {
              scope:rootStr,
          }
          );
          console.log('Service Worker Registered');
          //const subscriptionOne = await registration.pushManager.getSubscription();
          //console.log("subscriptionOne:");
          //console.log(subscriptionOne);
  
  
          //Register Push
          console.log('Registering Push...');
          const subscription = 
          await registration.pushManager.subscribe({
              userVisibleOnly:true,
              expirationTime:157680000000,
              applicationServerKey:this.urlBase64ToUint8Array(this.userStore.publicKey)
          });
  
          //subscription.expirationTime=157680000000;
          console.log("Subscription:");
          console.log(subscription);
          console.log("Endpoint:");
          console.log(subscription.endpoint);

          
          //console.log("Push registered...");
  
          //subscription:subscription,
          /*
          console.log(typeof subscription);
          console.log("Endpoint:");
          console.log(subscription.endpoint);
          console.log("ExpirationTime:");
          console.log(subscription.expirationTime);
          console.log("keys:");
          console.log(subscription.keys);

          console.log("p256dh:");
          console.log(subscription.keys.p256dh);
          console.log("Auth:");
          console.log(subscription.keys.auth);
          
          
          let data={
              idp:''+this.userStore.idp,
              iddisp:''+this.userStore.iddisp,
              endpoint:''+subscription.endpoint,
              expirationTime:''+subscription.expirationTime,
              p256dh:''+subscription.keys.p256dh,
              auth:''+subscription.keys.auth
          };
          */

          let data={
              idp:''+this.userStore.idp,
              iddisp:''+this.userStore.iddisp,
              subscription:subscription
          };
          
  
          Object.assign(data,subscription);
          console.log("SubscriptionData:");
          console.log(data);
          //console.log("Keys:");
          //console.log(data.subscription.keys);
          let dataStr=JSON.stringify(data);
          console.log(dataStr);
          this.dataStr=dataStr;
  
          //Send Notification
          /*
          console.log("Sending notification");
          await fetch('https://webdevworks.xyz/wpLC/suscribir',
          {
              method:'POST',
              body: JSON.stringify(subscriptionSend)
              
          });
          */

          this.$root.sendPOSTRequest(
          "https://webdevworks.xyz/wpLC/suscribir/",
          data,
          this.onSuccessSendSubscription,
          this.onErrorSendSubscription
          );

          console.log('Push Sent...');
          
      },

      onSuccessSendSubscription(data){
        alert("Suscrito: "+JSON.stringify(data));
        console.log("onSuccessSendSubscription:");
        console.log(data);
      },

      onErrorSendSubscription(data){
        alert("Error: "+JSON.stringify(data));
        console.log("ERROR:");
        console.log(data);
      },
  
      urlBase64ToUint8Array(base64String){
  
          const padding = '='.repeat((4 - base64String.length % 4) % 4);
          const base64 = 
          (base64String+padding)
          .replace(/-/g,'+')
          .replace(/_/g,'/');
  
          const rawData = window.atob(base64);    
          const outputArray = new Uint8Array(rawData.length);
  
          for(let i=0;i<rawData.length;i++){
  
              outputArray[i]=rawData.charCodeAt(i);
  
          }
  
          return outputArray;
  
  
      },


      sendLogOut(){
        console.log("sendLogOut()");
        let data={
          id:''+this.userStore.idp         
        };

        this.$root.sendPOSTRequest(
          "https://webdevworks.xyz/scLC/api/v1/registrarLogOut/",
          data,
          this.onSuccessSendLogOut,
          this.onErrorSendLogOut
          );
      },
      onSuccessSendLogOut(data){

        console.log("onSuccessSendLogOut");
        console.log("Data success:");
        console.log(data);         
        //console.log(typeof data.data.c0);         
        console.log(data.data);         
        console.log(data.data.data);   
        
      },
      onErrorSendLogOut(data){
        console.log("onErrorSendLogOut");
        console.log(data);
      },

      leaving(){
        //window.open("https://www.w3schools.com",'_blank');
      },
      
      expandClass(){
        
        if(!this.expandedMenu){
          console.log("expandClass min");
          return 'menuPrincipalMin';

        }else{
          console.log("expandClass expanded");
          return 'menuPrincipalExp';

        }

      },

      expandMenu(val){
        console.log("Home ExpandMenu: "+val);
        this.expandedMenu=val;
        //this.$forceUpdate();
      }


    },
    mounted:function(){

      if(this.userStore.login==0){
        this.$root.changeRoute("/");
      }
      this.initIDDISP('-');
      

    },    
    computed:{  
      ...mapStores(useUserStore)
    }
  }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

  .menuArea{
    
    width:75%;
    display:inline-block; 
    padding:10px;
    min-height:40vh; 
    max-height:38vh; 
    border-radius: 5px;
  }

  .avisos{
    width:20%;
    display:inline-block;
    vertical-align: top;
  }  

  .menuPrincipalMin{
    width:60%;
    display:inline-block;
    padding:10px;
    height:50vh; 
    border-radius: 5px;
  }

  .menuPrincipalExp{
    width:100%;
    display:inline-block;
    padding:10px;
    height:50vh; 
    border-radius: 5px;
  }

  .profileData{      
    text-align: center;        
  }

  .profileInfo{
    box-sizing: border-box;
    width:20%;  
    vertical-align: top;
    display: inline-block;
    border-radius: 5px;
    background-color: var(--blueDark);
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
    min-height: 80vh;
    padding-top:10px;
    padding-bottom:10px;
    color:white;

  }

  .imagContainer{
    background:var(--orange);
    width:75%;
    height:15vw;
    
    cursor:pointer;
    margin: 0 auto;
    border-radius: 5px;
    overflow:hidden;
    
    padding: 0px;
  }

  .profileImage{
    width:15vw;
    height:15vw;
    object-fit: cover;
    object-position: center;
  }

  

  @media (max-width: 850px) {

  .menuPrincipalMin{
    width:93%;
    display:inline-block;
    padding:10px;
    height:50vh; 
    border-radius: 5px;
  }

  .menuPrincipalExp{
    width:93%;
    display:inline-block;
    padding:10px;
    height:50vh; 
    border-radius: 5px;
  }

  .menuArea{
    width:100%;
    display:inline-block; 
    padding:10px; 
    height:70vh; 
    border-radius: 5px;
  }

    .avisos{
      width:100%;
      display:block;
      float:left;
    }

    .menuPrincipal{
      width:98%;
      margin: 0 auto;
      display:block;
      padding:10px; 
      height: 60vh; 
      border-radius: 5px;
    }

    .profileData{
      
      display:inline-block;
      width:65%;
      vertical-align: top;
      text-align: left;
      padding-left: 10px;
      padding-right: 10px;
      
    }

    .imagContainer{
      background:var(--orange);
      padding: 0px;
      width:25%;
      height:25vw;
      display:inline-block;
      margin: 0 auto;
      border-radius: 5px;
      overflow:hidden;
      
    }
    .profileImage{
      width:100%;
      height:100%;
      object-fit: cover;
      object-position: center;
      margin-bottom: 0px;
    }

    .profileInfo{

      box-sizing: border-box;
      width:100%;  
      vertical-align: top;
      display: block;
      border-radius: 5px;
      border-bottom-left-radius: 0px;
      border-bottom-right-radius: 0px;
      background-color: var(--blueDark);
      min-height: 10vh;
      padding:10px;
      
      color:white;

    }


  }



</style>
